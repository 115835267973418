import React, { useEffect, useRef, useState } from "react";
import { select, selectAll } from "d3-selection";
import styled from "styled-components";

const barHeight = 42;
const barWidth = 380;
const barSpace = 12;
const barPaddingR = 20;
const legendY = barHeight / 2 + 4;

const d3 = {
  select,
  selectAll,
};

const WaterFallChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : "100%")};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : "100%")};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

const LegendL = styled.text`
  fill: ${(props) => props.theme.colors.gray10};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const LegendR = styled.text`
  fill: ${(props) => props.theme.colors.gray10};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const GFlex = styled.g`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  transform: ${(props) =>
    props.transform ? props.transform : "translate(0,0)"};
`;

const calculateLeftAxisArea = ({ leftLegend, isMobile, isMicro }) => {
  switch (true) {
    case !!leftLegend:
      return isMobile || isMicro ? 8 : 92;
    default:
      return isMobile || isMicro ? 8 : 68;
  }
};

const calculateBottomAxisArea = ({ bottomLegend, isMobile, isMicro }) => {
  switch (true) {
    case !!bottomLegend:
      return isMobile || isMicro ? 8 : 62;
    default:
      return isMobile || isMicro ? 8 : 36;
  }
};

const calculateRightAxisArea = ({ isMobile, isMicro }) =>
  isMicro || isMobile ? 0 : 8;
const calculateTopAxisArea = ({ isMobile, isMicro }) =>
  isMicro || isMobile ? 0 : 8;

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export const WaterFallChart = ({
  chartId = "test",
  width = barWidth,
  height = 500,
  maxHeight = 720,
  data = [],
  leftLegend,
  bottomLegend,
  rightLegend,
  isMicro,
}) => {
  const [chartWidth, setChartWidth] = useState(width);
  const [chartHeight, setChartHeight] = useState(height);
  const isMobile = useMobileScreen();
  const chartMaxHeight = isMobile ? 260 : maxHeight;
  const axisArea = {
    top: calculateTopAxisArea({ isMobile, isMicro }),
    right: calculateRightAxisArea({ rightLegend, isMobile, isMicro }),
    bottom: calculateBottomAxisArea({ bottomLegend, isMobile, isMicro }),
    left: calculateLeftAxisArea({ leftLegend, isMobile, isMicro }),
  };
  const svgNode = useRef(null);
  const svgNodeWrapper = useRef(null);
  const keyValPair = data.reduce((acc, curr) => {
    const { id, value } = curr;
    acc[id] = Number(value);
    return acc;
  }, {});
  //console.log("keyValPair", keyValPair);

  const keyColorPair = data.reduce((acc, curr, i) => {
    let { id, color, colorNeg } = curr;
    if (
      (curr.id === "gross_margin" || curr.id === "income_pre_tax") &&
      keyValPair[curr.id] < 0
    ) {
      color = colorNeg;
    }
    // console.log(curr);
    acc[id] = { color: color };
    return acc;
  }, {});

  const setDiamensions = (mutation) => {
    const [currentMutationObject] = mutation;
    const {
      contentRect: { width: contentRectWidth, height: contentRectHeight },
    } = currentMutationObject;
    if (contentRectHeight !== chartHeight || contentRectWidth !== chartWidth) {
      setChartWidth(contentRectWidth);
      setChartHeight(contentRectHeight);
    }
  };

  useEffect(() => {
    renderChart();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartWidth, chartHeight]);

  useEffect(() => {
    const observer = new ResizeObserver(setDiamensions);
    observer.observe(svgNodeWrapper.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(svgNodeWrapper.current);
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgNodeWrapper.current]);

  const renderChart = () => {
    const svgObj = d3
      .select(svgNode.current)
      .select(`#${chartId}-chart-wrapper`);

    //console.log("income_pre_tax", keyValPair.income_pre_tax);
    // todo this test will need to check net_income instead of income_pre_tax when taxes are added back in.
    const isIncomeNeg = keyValPair.income_pre_tax < 0;

    const totalVal = isIncomeNeg
      ? Math.abs(
          keyValPair.cost_of_sales +
            keyValPair.gas_fees +
            keyValPair.royalty_fees
        )
      : keyValPair.gross_sales;

    //console.log("totalVal", totalVal);

    const delimiter = barWidth / totalVal;

    // X position, offset from totalVal
    const getXValue = (d) => {
      switch (d) {
        case "gross_sales":
          return totalVal - keyValPair.gross_sales;
        case "cost_of_sales":
          return totalVal - keyValPair.gross_sales !== 0
            ? totalVal - Math.abs(keyValPair.cost_of_sales)
            : keyValPair.gross_sales + keyValPair.cost_of_sales;
        case "gross_margin":
          if (isIncomeNeg) {
            return keyValPair.gross_margin > 0
              ? totalVal - keyValPair.gross_sales
              : totalVal - Math.abs(keyValPair.cost_of_sales);
          } else {
            return 0;
          }
        case "gas_fees":
          return totalVal - keyValPair.gross_sales !== 0
            ? Math.abs(keyValPair.royalty_fees)
            : keyValPair.gross_sales +
                keyValPair.cost_of_sales +
                keyValPair.gas_fees;
        case "royalty_fees":
          return totalVal - keyValPair.gross_sales !== 0
            ? 0
            : keyValPair.gross_sales +
                keyValPair.cost_of_sales +
                keyValPair.gas_fees +
                keyValPair.royalty_fees;
        case "income_pre_tax":
          return 0;
        case "income_tax":
          return (
            keyValPair.gross_sales +
            keyValPair.cost_of_sales +
            keyValPair.gas_fees +
            keyValPair.royalty_fees +
            keyValPair.income_tax
          );
        default:
          return 0;
      }
    };

    svgObj
      .selectAll(".rect-group")
      .data(Object.keys(keyValPair))
      .attr(
        "transform",
        (d, i) => `translate(0,${(i - 1) * barHeight + barSpace * (i + 4)})`
      )
      .select(".rect")
      .attr("x", (d) => delimiter * getXValue(d))
      .attr("y", () => 0)
      .attr("height", barHeight)
      .attr("width", (d) => {
        return delimiter * Math.abs(keyValPair[d]);
      })
      .attr("fill", (d) => keyColorPair[d].color);

    svgObj
      .select(`#${chartId}-crosshair`)
      .attr(
        "transform",
        `translate(${delimiter * Math.abs(keyValPair.income_pre_tax) + 10}, -5)`
      );
  };
  //
  return (
    <WaterFallChartWrapper ref={svgNodeWrapper} maxHeight={chartMaxHeight}>
      <StyledSvg ref={svgNode} viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
        <g
          id={`${chartId}-chart-wrapper`}
          transform={`translate(100,${axisArea.top})`}
          className="chart-wrapper"
        >
          {data.map((item) => (
            <g key={item.id} className="rect-group" id={`bar-${item.id}`}>
              <GFlex
                justifyContent="flex-end"
                transform={`translate(0, ${legendY})`}
              >
                <LegendL textAnchor="end" x="0">
                  {item.name}
                </LegendL>
              </GFlex>
              <rect
                color="#1D1929"
                fill="#1D1929"
                transform={`translate(10,0)`}
                rx={8}
                ry={8}
                height={barHeight}
                width={barWidth}
              />
              <rect
                id={`rect-${item.id}`}
                className="rect"
                color={item.color}
                transform={`translate(10,0)`}
                rx={8}
                ry={8}
              />
              <GFlex
                transform={`translate(${barWidth + barPaddingR}, ${legendY})`}
              >
                <LegendR>
                  {item.value}
                  <tspan className="font-semibold text-[11px]">Ξ</tspan>
                </LegendR>
              </GFlex>
            </g>
          ))}
          {keyValPair.income_pre_tax < 0 && (
            <g id={`${chartId}-crosshair`}>
              <LegendL textAnchor="end" x="5" y="6">
                0Ξ
              </LegendL>
              <line
                className="crosshair"
                opacity="1"
                stroke={"#615E69"}
                strokeWidth={"2"}
                y1={barSpace}
                strokeDasharray="7 6"
                y2={320}
              />
            </g>
          )}
        </g>
      </StyledSvg>
    </WaterFallChartWrapper>
  );
};
