export const Globe = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
      <path d="M0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5Z" />
      <path d="M4.5 5.5V5C4.22386 5 4 5.22386 4 5.5H4.5ZM4.5 6.5L4.85355 6.85355L5 6.70711V6.5H4.5ZM2.79289 6.79289L2.43934 7.14645L2.43934 7.14645L2.79289 6.79289ZM6.5 13H7V12.7929L6.85355 12.6464L6.5 13ZM5.5 12H5V12.2071L5.14645 12.3536L5.5 12ZM5.5 10.5H6V10.2929L5.85355 10.1464L5.5 10.5ZM4.5 9.5H4V9.70711L4.14645 9.85355L4.5 9.5ZM4.5 8.5V8C4.22386 8 4 8.22386 4 8.5H4.5ZM9 0.5V2.5H10V0.5H9ZM8.5 3H7.5V4H8.5V3ZM5.5 5H4.5V6H5.5V5ZM4 5.5V6.5H5V5.5H4ZM4.14645 6.14645L3.85355 6.43934L4.56066 7.14645L4.85355 6.85355L4.14645 6.14645ZM3.14645 6.43934L1.35355 4.64645L0.646447 5.35355L2.43934 7.14645L3.14645 6.43934ZM6 4.5C6 4.77614 5.77614 5 5.5 5V6C6.32843 6 7 5.32843 7 4.5H6ZM7.5 3C6.67157 3 6 3.67157 6 4.5H7C7 4.22386 7.22386 4 7.5 4V3ZM3.85355 6.43934C3.65829 6.6346 3.34171 6.6346 3.14645 6.43934L2.43934 7.14645C3.02513 7.73223 3.97487 7.73223 4.56066 7.14645L3.85355 6.43934ZM9 2.5C9 2.77614 8.77614 3 8.5 3V4C9.32843 4 10 3.32843 10 2.5H9ZM7 14.5V13H6V14.5H7ZM6.85355 12.6464L5.85355 11.6464L5.14645 12.3536L6.14645 13.3536L6.85355 12.6464ZM6 12V10.5H5V12H6ZM5.85355 10.1464L4.85355 9.14645L4.14645 9.85355L5.14645 10.8536L5.85355 10.1464ZM5 9.5V8.5H4V9.5H5ZM4.5 9H8.5V8H4.5V9ZM9 9.5V10.1667H10V9.5H9ZM10.8333 12H13.5V11H10.8333V12ZM10 11.1667C10 11.6269 10.3731 12 10.8333 12V11C10.9254 11 11 11.0746 11 11.1667H10ZM9.83333 11C9.92538 11 10 11.0746 10 11.1667H11C11 10.5223 10.4777 10 9.83333 10V11ZM9 10.1667C9 10.6269 9.3731 11 9.83333 11V10C9.92538 10 10 10.0746 10 10.1667H9ZM8.5 9C8.77614 9 9 9.22386 9 9.5H10C10 8.67157 9.32843 8 8.5 8V9ZM7.5 14C3.91015 14 1 11.0899 1 7.5H0C0 11.6421 3.35786 15 7.5 15V14ZM14 7.5C14 11.0899 11.0899 14 7.5 14V15C11.6421 15 15 11.6421 15 7.5H14ZM7.5 1C11.0899 1 14 3.91015 14 7.5H15C15 3.35786 11.6421 0 7.5 0V1ZM7.5 0C3.35786 0 0 3.35786 0 7.5H1C1 3.91015 3.91015 1 7.5 1V0Z" fill={props.color2}/>
  </svg>
);

export default Globe;