import { useState } from 'react';
import Banner from '../components/Banner/Banner';
import { TraitsBreakdown } from '../components/TraitsBreakdown/TraitsBreakdown';
import TraitsCharts from '../components/TraitsCharts/TraitsCharts';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';

function Traits() {
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [selectedOps, setSelectedOps] = useState('or');
  
  const handleTraitsSelection = (traits) => {
    setSelectedTraits(traits);
  };
  
  const handleOpsSelection = (ops) => {
    setSelectedOps(ops);
  };
 
  return (
    <Sidebar
      onTratsSelect={handleTraitsSelection}
      onOpsSelect={handleOpsSelection}
    >
      <Banner selectedTraits={selectedTraits} selectedOps={selectedOps} />
      <TraitsCharts selectedTraits={selectedTraits} selectedOps={selectedOps} />
      <TraitsBreakdown
        selectedTraits={selectedTraits}
        selectedOps={selectedOps}
      />
      <Footer />
    </Sidebar>
  );
}

export default Traits;
