import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const ChevronLgLeft = withTheme(({ size = 16, viewBx = size, color, theme }) => {
  const viewBoxStr = "0 0 " + viewBx + " " + viewBx;

  return (
    <StyledSVG
      className='icon'
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewBoxStr}
      stroke-linecap="square"
      stroke={theme.colors[color]}
      strokeWidth='1.5'
    >
      <path d="M11 14L4 7.5L11 1"/>
    </StyledSVG>
  );
});

ChevronLgLeft.displayName = 'ChevronLgLeft';
