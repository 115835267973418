import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const Rollar = withTheme(({ ...props }) => {
  return (
    <StyledSVG
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 2C1 1.17157 1.67157 0.5 2.5 0.5H12.5C13.3284 0.5 14 1.17157 14 2V2.55001C15.1411 2.78164 16 3.79052 16 5V6.4914C16 7.6076 15.2601 8.58856 14.1868 8.89521L9.36264 10.2735C9.14799 10.3349 9 10.5311 9 10.7543V11.5H10.5C10.7761 11.5 11 11.7239 11 12V15C11 15.2761 10.7761 15.5 10.5 15.5H6.5C6.22386 15.5 6 15.2761 6 15V12C6 11.7239 6.22386 11.5 6.5 11.5H8V10.7543C8 10.0846 8.44397 9.496 9.08792 9.31202L13.9121 7.93368C14.556 7.7497 15 7.16112 15 6.4914V5C15 4.34689 14.5826 3.79127 14 3.58535V4C14 4.82843 13.3284 5.5 12.5 5.5H2.5C1.67157 5.5 1 4.82843 1 4V2Z'
        fill='#34303E'
        stroke='#A5A3A9'
        strokeLinejoin='round'
      />
    </StyledSVG>
  );
});

Rollar.displayName = 'Rollar';
