import React, { useEffect, useRef, useState } from "react";
import { select, selectAll } from "d3-selection";
import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { scaleLinear, scaleBand, scaleSequential } from "d3-scale";
import { pie, arc } from "d3-shape";
import { max, sum } from "d3-array";
import { format } from "d3-format";
import "d3-transition";
import styled, { css, withTheme } from "styled-components";
import { Flex } from "../Box";
import { lightTheme } from "../../utils/theme";
import { roundToNearest } from "../../utils/numbers";

const d3 = {
  select,
  selectAll,
  axisBottom,
  axisRight,
  format,
  scaleLinear,
  axisLeft,
  scaleBand,
  max,
  pie,
  arc,
  scaleSequential,
  sum,
};

const PieChartWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const LegendWrapper = styled(Flex)`
  margin-left: 0px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : "100%")};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : "100%")};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

const Legend = styled.span`
  color: ${(props) => props.theme.colors.gray10};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const Pointer = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 6px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

const TooltipWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.gray3};
  color: inherit;
  font-size: 12px;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 12px 12px 6px 12px;
  visibility: ${(props) => (props.isMobile ? "visible" : "hidden")};
  position: ${(props) => (props.isMobile ? "initial" : "absolute")};
  z-index: 999;
`;

const PointWrapper = styled(Flex)`
  .point-title-wrapper {
    margin-bottom: 0px;
  }
  ${(props) =>
    props.hasPreviousData &&
    css`
      &:first-child {
        padding-bottom: 8px;
      }
    `}
  ${(props) =>
    props.isPrevious &&
    css`
      &:first-child {
        padding-bottom: 8px;
      }
      &:last-child {
        border-top: 1px solid ${lightTheme.colors.gray9};
        padding-top: 8px;
      }
    `}
`;

const TooltipTitle = styled.strong`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

const TooltipText = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

const makeDivId = (str = "") => {
  const primaryString = str
    .replace(/[^\w\s]/gi, "")
    .split(" ")
    .join("-")
    .toLowerCase();

  return primaryString;
};

const clamp = (min, d, max) => {
  return Math.max(min, Math.min(max, d));
};

export const PieChart = withTheme(
  ({
    chartId = "test",
    width = 360,
    height = 400,
    maxHeight = 720,
    radius = 200,
    data = [],
    padding = 18,
    theme,
  }) => {
    const isMobile = useMobileScreen();
    const chartMaxHeight = isMobile ? 260 : maxHeight;
    const svgNode = useRef(null);
    const svgNodeWrapper = useRef(null);
    const total = data.values.reduce((acc, cur) => acc + cur.value, 0);

    // console.log(total);
    useEffect(() => {
      renderChart();
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const calculateTooltipDiamension = (event) => {
      const tooltipHoverMargin = 16;
      const { offsetX: x, offsetY: y } = event;
      const { width: tooltipWidth, height: tooltipHeight } = document
        .getElementById(`${chartId}-pie-tooltip`)
        .getBoundingClientRect();

      const left = clamp(
        tooltipHoverMargin,
        x - tooltipWidth / 2 + tooltipHoverMargin,
        width - tooltipWidth - tooltipHoverMargin + 20
      );

      const top =
        height > y + tooltipHoverMargin + tooltipHeight
          ? y + tooltipHoverMargin
          : y - tooltipHeight - tooltipHoverMargin;

      return { left, top };
    };

    function handleMouseOverPie(event, point) {
      const { left, top } = calculateTooltipDiamension(event);

      d3.select(`#${chartId}-pie-tooltip`)
        .style("visibility", "visible")
        .style("top", `${top}px`)
        .style("left", `${left}px`);

      d3.select(`#${chartId}-point-wrapper .tooltip-title-lebel`).text(
        point.type
      );

      d3.select(`#point-ls`).style("display", "flex");
      d3.select(`#point-ls span.point-label`)
        .text(`${roundToNearest((point.value / total) * 100, 2)}%`)
        .style("display", "block");
    }

    const handleMouseOut = () => {
      d3.select(`#${chartId}-pie-tooltip`).style("visibility", "hidden");
    };

    const renderChart = () => {
      const svgObj = d3
        .select(svgNode.current)
        .select(`#${chartId}-chart-wrapper`)
        .data([data]);

      function calcTranslate(data, move = 4) {
        const moveAngle =
          data.startAngle + (data.endAngle - data.startAngle) / 2;
        return `translate(${-move * Math.cos(moveAngle + Math.PI / 2)}, ${
          -move * Math.sin(moveAngle + Math.PI / 2)
        })`;
      }

      const path = d3
        .arc()
        .outerRadius(radius - padding)
        .innerRadius((radius - padding) / 2);

      const pie = d3
        .pie()
        .startAngle(Math.PI)
        .endAngle(3 * Math.PI)
        .value((d) => d.value);

      const duration = 250;

      const arc = svgObj
        .selectAll(".arc")
        .data((d) => pie(d.values))
        .enter()
        .append("g")
        .attr("class", "arc")
        .style("cursor", "pointer")
        .on("mouseover", (event, v) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(duration)
            .attr("transform", calcTranslate(v, 6));
          d3.select(event.currentTarget)
            .select("path")
            .transition()
            .duration(duration);
          handleMouseOverPie(event, v.data);
          // d3.select('.card-back text').text(v.data.type);
        })
        .on("mouseout", (event) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(duration)
            .attr("transform", "translate(0, 0)");
          d3.select(event.currentTarget)
            .select("path")
            .transition()
            .duration(duration);
          handleMouseOut(event);
        });

      arc
        .append("path")
        .attr("d", path)
        .attr("fill", (v) => v.data.color);

      const card = svgObj
        .append("g")
        .attr("text-anchor", "middle")
        .style("-webkit-perspective", 1000)
        .style("-webkit-transform", "rotateY(0deg)");

      const cardFront = card.append("g").attr("class", "card-front");
      cardFront
        .append("text")
        .text((d) => d.name)
        .attr("font-size", "22px")
        .attr("font-weight", "700")
        .attr("fill", "white");
      cardFront
        .append("text")
        .attr("dy", "1.2rem")
        .attr("font-size", "14px")
        .attr("font-weight", "600")
        .attr("fill", "#A5A3A9")
        .text(() => "Collections");

      const cardBack = card
        .append("g")
        .attr("class", "card-back")
        .style("display", "none")
        .style("transform", "rotateY(180deg)");
      cardBack.append("text").text("Back");
    };

    // used below so the last item in the legend (Others) isn't a link
    //https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
    // const ConditionalWrapper = ({ condition, wrapper, children }) =>
    // condition ? wrapper(children) : children;

    return (
      <PieChartWrapper
        flexDirection="row"
        alignItems="center"
        ref={svgNodeWrapper}
        maxHeight={chartMaxHeight}
      >
        <StyledSvg
          ref={svgNode}
          chartWidth={width + 20}
          chartHeight={height + 20}
          viewBox={`0 0 ${width + 20} ${height + 20}`}
        >
          <g
            id={`${chartId}-chart-wrapper`}
            transform={`translate(${radius},${radius + 10})`}
            className="chart-wrapper"
          />
        </StyledSvg>
        <LegendWrapper
          flexDirection="column"
          alignItems="baseline"
          justifyContent="center"
          height="100%"
          ml="5"
        >
          {data.values.map((item) => (
            // <ConditionalWrapper
            // condition={item.type !== "Others"}
            // wrapper={children => <a href={`/overview/${item.slug}`} className="w-full rounded-xl hover:bg-gray-1100 transition">{children}</a>}
            // >
            <Flex
              key={makeDivId(item.type)}
              flexDirection="row"
              alignItems="center"
              pb="2"
              pt="2"
              pl="2"
            >
              <Pointer
                className="pointer"
                isMobile={isMobile}
                color={item.color}
              />
              <Legend>
                {item.type} ({item.value})
              </Legend>
            </Flex>
            // </ConditionalWrapper>
          ))}
        </LegendWrapper>
        <TooltipWrapper
          isMobile={isMobile}
          id={`${chartId}-pie-tooltip`}
          flexDirection="column"
        >
          <PointWrapper
            id={`${chartId}-point-wrapper`}
            flexDirection="column"
            alignItems="center"
          >
            <TooltipTitle
              className="point-title-wrapper"
              id={`${chartId}-point-title-wrapper`}
            >
              <TooltipText className="tooltip-title-lebel" />
            </TooltipTitle>
            <Flex id="point-ls" className="point-ls" mb="1">
              <TooltipText className="point-label" />
            </Flex>
          </PointWrapper>
        </TooltipWrapper>
      </PieChartWrapper>
    );
  }
);
