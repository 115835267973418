import React, { useEffect, useRef, useState } from 'react';
import { select, selectAll } from 'd3-selection';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import { scaleLinear, scaleBand, scaleTime } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';
import { extent, max } from 'd3-array';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
} from 'd3-time';
import styled from 'styled-components';
import { Delaunay } from 'd3-delaunay';
import { Flex } from './../Box';
import { roundToNearest } from '../../utils/numbers';

const d3 = {
  select,
  selectAll,
  axisBottom,
  axisRight,
  format,
  scaleLinear,
  axisLeft,
  scaleBand,
  max,
  extent,
  scaleTime,
  line,
  curveMonotoneX,
  Delaunay,
  timeFormat,
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
};

const BarChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

const TooltipWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.gray3};
  color: inherit;
  font-size: inherit;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 12px;
  visibility: ${(props) => (props.isMobile ? 'visible' : 'hidden')};
  position: ${(props) => (props.isMobile ? 'initial' : 'absolute')};
  z-index: 999;
  opacity:0;
  transition-property: opacity;
  transition-duration: 250ms;
  
`;

const Pointer = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: ${(props) => props.color};
  display: ${(props) => (props.isMobile ? 'block' : 'none')};
`;

const PointWrapper = styled(Flex)`
  .point-title-wrapper {
    margin-bottom: 4px;
  }
`;

const TooltipTitle = styled.strong`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray7};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
`;

const TooltipText = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const Legend = styled.text`
  fill: ${(props) => props.theme.colors.gray7};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
`;

const Axis = styled.g`
  text {
    fill: ${(props) => props.theme.colors.gray7};
    font-family: ${(props) => props.theme.fonts.nunito};
    font-size: 10px;
  }
  line {
    stroke: ${(props) => props.theme.colors.gray2};
    stroke-width: 1px;
  }
`;

const addSuffixPrefix = (num, format) => {
  const unformattedNumber = num.toString().replace(/,/g, '');
  const equivalentPositiveNumber = num.toString().replace(/-/g, '');
  switch (format) {
    case 'Ξ':
      return parseFloat(unformattedNumber, 10) < 0
        ? `-${equivalentPositiveNumber}Ξ`
        : `${num}Ξ`;
    case '%':
      return `${num}%`;
    default:
      return num;
  }
};

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

function abbreviateNumber(number, format) {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return addSuffixPrefix(number, format);
  const suffix = SI_SYMBOL[tier] || '';
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return addSuffixPrefix(roundToNearest(scaled, 2), format) + suffix;
}

const makeDivId = (str) =>
  str
    .toString()
    .replace(/[^\w\s]/gi, '')
    .split(' ')
    .join('-')
    .toLowerCase();

const withZeroPadding = (value) => (value < 10 ? `0${value}` : value);

const formatDate = (day, hourly) =>
  isDate(day)
    ? hourly
      ? `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
          day.getDate(),
        )}/${withZeroPadding(day.getFullYear())} ${withZeroPadding(
          day.getHours(),
        )}:${withZeroPadding(day.getMinutes())}`
      : `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
          day.getDate(),
        )}/${withZeroPadding(day.getFullYear())}`
    : day;

const clamp = (min, d, max) => {
  return Math.max(min, Math.min(max, d));
};

function wrap(text, width) {
  text.each(function () {
    const text = d3.select(this);
    const words = text.text().trim().split(/\s+/).reverse();
    let word = '';
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1;
    const y = text.attr('y');
    const dy = parseFloat(text.attr('dy'));
    let tspan = text
      .text(null)
      .append('tspan')
      .attr('x', 3)
      .attr('y', y)
      .attr('dy', dy + 'em');
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    }
  });
}

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const formatMinute = d3.timeFormat('%H:%M'),
  formatHour = d3.timeFormat('%H:%M'),
  formatDay = d3.timeFormat('%a %d'),
  formatWeek = d3.timeFormat('%b %d'),
  formatMonth = d3.timeFormat('%b'),
  formatYear = d3.timeFormat('%Y');

function multiFormat(date) {
  return (
    d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

const isDate = (d) => d instanceof Date && !isNaN(d);

export const BarChart = ({
  chartId = 'test',
  width = 960,
  height = 360,
  maxHeight = 360,
  data = [],
  leftLegend,
  bottomLegend,
  indexBy,
  keys,
  isMicro,
  hasMultiLineAxisPoints = false,
  barWidth = 18,
  labels = {y1:'Listings'}, //todo, hack - add "labels" to SellWall data
  onBarClick = () => {},
}) => {
  const [chartWidth, setChartWidth] = useState(width);
  const [chartHeight, setChartHeight] = useState(height);
  const isMobile = useMobileScreen();
  const chartMaxHeight = isMobile ? 260 : maxHeight;
  const axisArea = {
    top: isMicro ? 8 : 8,
    right: isMicro ? 8 : 8,
    bottom: isMicro ? 8 : 36,
    left: isMicro ? 8 : 36,
  };
  const chartAreaWidth = chartWidth - axisArea.left - axisArea.right;
  const chartAreaHeight = chartHeight - axisArea.top - axisArea.bottom;
  const svgNode = useRef(null);
  const svgNodeWrapper = useRef(null);

  const setDiamensions = (mutation) => {
    const [currentMutationObject] = mutation;
    const {
      contentRect: { width: contentRectWidth, height: contentRectHeight },
    } = currentMutationObject;
    if (contentRectHeight !== chartHeight || contentRectWidth !== chartWidth) {
      setChartWidth(contentRectWidth);
      setChartHeight(contentRectHeight);
    }
  };

  useEffect(() => {
    renderChart();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartWidth, chartHeight]);

  useEffect(() => {
    const observer = new ResizeObserver(setDiamensions);
    observer.observe(svgNodeWrapper.current);
    return () => {
      if (svgNodeWrapper.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(svgNodeWrapper.current);
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgNodeWrapper.current]);

  const calculateTooltipDiamension = (event) => {
    const tooltipHoverMargin = 16;
    const { offsetX: x, offsetY: y } = event;
    const { width: tooltipWidth, height: tooltipHeight } = document
      .getElementById(`${chartId}-line-tooltip`)
      .getBoundingClientRect();

    const left = clamp(
      tooltipHoverMargin,
      x + tooltipHoverMargin,
      chartAreaWidth - tooltipWidth - tooltipHoverMargin + axisArea.left,
    );

    const top =
      chartAreaHeight > y + tooltipHoverMargin + tooltipHeight
        ? y + tooltipHoverMargin
        : y - tooltipHeight - tooltipHoverMargin;

    return { left, top };
  };
  
  function getTTPointDiv (keyData, key, suffix="") {
   const firstBit = `#${chartId}-point-ls-${makeDivId(
    isDate(keyData[indexBy])
      ? formatDate(keyData[indexBy], true)
      : keyData[indexBy],
    )}-`;

    const secondBit = makeDivId(key)
    const TTPointDiv = d3.select(firstBit + secondBit + suffix);
    return TTPointDiv;
  };

  // within this function, the text that will appear in tooltips is injected into the actual elements
  function handleMouseOverVoronoiWrapper(event) {
    //if (isMicro) return;
    const point = event.target.__data__;
    const { left, top } = calculateTooltipDiamension(event);

    d3.select(`#${chartId}-line-tooltip`)
      .style('visibility', 'visible')
      .style('opacity', 1)
      .style('top', `${top}px`)
      .style('left', `${left}px`);

    // First line of text, usually the date. Shorten date text for micors
    const firstLineElement = d3.select(`#${chartId}-point-wrapper .tooltip-title`);
    const firstLineText = isDate(point.keyData[indexBy])
    ? formatDate(point.keyData[indexBy], true)
    : point.keyData[indexBy];
    firstLineElement.text((isMicro)?firstLineText.substring(5,10):firstLineText);

      // if(isMicro){
    //   // hiding various elements to collapse space
    //   d3.select(`#${chartId}-point-title-wrapper`)
    //   .style('display', 'none')
    //   firstLineElement.style('display', 'none')
    // }else{
    //   firstLineElement.text(
    //     isDate(point.keyData[indexBy])
    //       ? formatDate(point.keyData[indexBy], true)
    //       : point.keyData[indexBy],
    //   );
    // };

    
    // Following line(s), "points"
    keys.forEach((key) => {
      const curData = point.keyData;
       
      getTTPointDiv(curData, key).style('display', 'flex');
      
      // little color dot (pointer). Hide for MicroCharts,
      getTTPointDiv(curData, key, " .pointer")
      .style('background-color', curData[`${key}Color`]).style('display', ((!isMicro)?'block':'none'));

      // Label of the value "e.g Volume", don't display if no label in data
        getTTPointDiv(curData, key, " span.point-label")
        .text(`${labels?.[key]}:`)
        .style('display', ((!isMicro)?'block':'none'));
  
      // Actual value
      // todo there is a hack in here that assumes all micros are in ETH format
      getTTPointDiv(curData, key, " span.point-value")
     .text(
          addSuffixPrefix(
            roundToNearest(curData[key], (Math.abs(curData[key])<1?3:1)),
            ((isMicro)?"Ξ":curData[`${key}Format`]),
          ),
        )
        .style('display', 'block');
        //console.log(roundToNearest(curData[key], 4));
      });
  }

  function handleMouseMove(event) {
    //if (isMicro) return;
    const { left, top } = calculateTooltipDiamension(event);
    d3.select(`#${chartId}-line-tooltip`)
      .style('top', `${top}px`)
      .style('left', `${left}px`);
  }

  const handleMouseOut = () => {
    //if (isMicro) return;
    d3.selectAll('.point-ls').style('display', 'none');
    d3.select(`#${chartId}-line-tooltip`)
    .style('visibility', 'hidden')
    .style('opacity', 0);
  };

  const renderChart = () => {
    const svgObj = d3
      .select(svgNode.current)
      .select(`#${chartId}-chart-wrapper`);

    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => d[indexBy]))
      .rangeRound([0, chartAreaWidth])
      .paddingInner(0.1)
      .range([0, chartAreaWidth]);

    const x1 = d3
      .scaleBand()
      .domain(keys)
      .rangeRound([0, x0.bandwidth()])
      .padding(0.05);

    const maxY =
      Number(d3.max(data, (d) => Number(d[keys[0]]))) +
      d3.max(data, (d) => Number(d[keys[0]])) * 0.2;

    const y = d3
      .scaleLinear()
      .domain([0, maxY])
      .nice()
      .rangeRound([isMicro ? chartHeight : chartAreaHeight, 0]);

    if (!isMicro) {
      svgObj
        .select('#bottom-axis')
        .attr('stroke-width', 0)
        .attr('transform', `translate(-10, ${chartAreaHeight})`)
        .call(
          d3
            .axisBottom(x0)
            .tickPadding(12)
            .ticks(8)
            .tickSize(0)
            .tickFormat((d) => {
              return isDate(d) ? multiFormat(d) : d;
            }),
        )
        .selectAll('text')
        .call(wrap, x0.bandwidth());

      svgObj
        .select('#left-axis')
        .attr('stroke-width', 0)
        .call(
          d3
            .axisLeft(y)
            .tickSize(-chartAreaWidth)
            .tickPadding(8)
            .ticks(8)
            .tickFormat((d) => {
              const [samplePoint] = data;
              return abbreviateNumber(d, samplePoint[`${keys[0]}Format`]);
            })
            .ticks(8),
        );
    }

    svgObj
      .selectAll('.rect-group')
      .data(data)
      .attr('transform', (d) => `translate(${x0(d[indexBy])},0)`)
      .selectAll('.rect')
      .data((d) => {
        return keys.map((key) => ({
          key,
          value: d[key],
          color: d[`${key}Color`],
          keyData: d,
        }));
      })
      .attr('x', (d) => {
        return x1(d.key);
      })
      .attr('y', (d) => (maxY === 0 ? chartHeight : y(Number(d.value))))
      .attr('width', barWidth)
      .attr('height', (d) => Math.abs(y(0) - y(Number(d.value))) || 1)
      .attr('fill', (d) => d.color)
      .on('mouseover', handleMouseOverVoronoiWrapper)
      .on('mousemove', handleMouseMove)
      .on('mouseout', handleMouseOut)
      .on('click', function (event) {
        const point = event.target.__data__;
        onBarClick(point);
      });
  };

  return (
    <BarChartWrapper ref={svgNodeWrapper} maxHeight={chartMaxHeight}>
      <StyledSvg
        ref={svgNode}
        viewBox={`${isMicro ? 10 : 0} ${
          isMicro ? 10 : 0
        } ${chartWidth} ${chartHeight}`}
      >
        <g
          id={`${chartId}-chart-wrapper`}
          transform={`translate(${axisArea.left}, ${axisArea.top})`}
          className="chart-wrapper"
        >
          {!isMobile && !isMicro && (
            <>
              <Axis
                id="bottom-axis"
                hasMultiLineAxisPoints={hasMultiLineAxisPoints}
              />
              <Axis id="left-axis" />
            </>
          )}
          {data.map((item) => (
            <g
              key={
                isDate(item[indexBy])
                  ? formatDate(item[indexBy], true)
                  : item[indexBy]
              }
              className="rect-group"
              id={`bar-${
                isDate(item[indexBy])
                  ? formatDate(item[indexBy], true)
                  : item[indexBy]
              }`}
            >
              {keys.map((keyItem) => (
                <rect
                  key={`rect-${
                    isDate(item[indexBy])
                      ? formatDate(item[indexBy], true)
                      : item[indexBy]
                  }-${keyItem}`}
                  id={`rect-${
                    isDate(item[indexBy])
                      ? formatDate(item[indexBy], true)
                      : item[indexBy]
                  }-${keyItem}`}
                  className="rect"
                  color={item[`${keyItem}Color`]}
                  rx={isMicro ? 3 : 6}
                  ry={isMicro ? 3 : 6}
                />
              ))}
            </g>
          ))}
          {bottomLegend && !isMobile && !isMicro && (
            <Legend
              textAnchor="middle"
              fontSize="14"
              x={chartAreaWidth / 2}
              y={chartAreaHeight + 52}
            >
              {bottomLegend}
            </Legend>
          )}
          {leftLegend && !isMobile && !isMicro && (
            <Legend
              textAnchor="end"
              fontSize="14"
              x={-chartAreaHeight / 2 + 52}
              y={-axisArea.left + 16}
              transform="rotate(-90)"
            >
              {leftLegend}
            </Legend>
          )}
        </g>
      </StyledSvg>
      
        <TooltipWrapper
          isMobile={isMobile}
          id={`${chartId}-line-tooltip`}
          flexDirection="column"
        >
          <PointWrapper id={`${chartId}-point-wrapper`} flexDirection="column">
            <TooltipTitle
              className="point-title-wrapper"
              id={`${chartId}-point-title-wrapper`}
            >
              {/* // This is the element that holds the first line of text, usually the date */}
              <TooltipTitle className="tooltip-title">---</TooltipTitle>
            </TooltipTitle>
            {keys.map((key) =>
              data.map((item) => (
                // These elements are for the second line of the tooltip, called the Pointer
                <Flex
                  key={`tooltip-item-${formatDate(
                    item[indexBy],
                    true,
                  )}-${makeDivId(key)}`}
                  id={`${chartId}-point-ls-${makeDivId(
                    isDate(item[indexBy])
                      ? formatDate(item[indexBy], true)
                      : item[indexBy],
                  )}-${makeDivId(key)}`}
                  className="point-ls"
                  mb="0"
                  style={{ display: 'none' }}
                >
                  <Flex marginRight="0" flexDirection="row" alignItems="center">
                    
                    <Pointer
                      className="pointer"
                      isMobile={isMobile}
                      color={item[`${key}Color`]}
                    />
                    
                    <TooltipText className="point-label">
                      {isDate(item[indexBy])
                        ? formatDate(item[indexBy], true)
                        : item[indexBy]}
                    </TooltipText>

                  </Flex>
                  {/* The numerical value is injected into this one */}
                  <TooltipText className={`point-value${(!isMicro)?' ml-1':''}`}>
                    --
                  </TooltipText>
                </Flex>
              )),
            )}
          </PointWrapper>
        </TooltipWrapper>
      
    </BarChartWrapper>
  );
};

// export const BarWithLineChart = ({
//   chartId = 'test',
//   width = 960,
//   height = 400,
//   maxHeight = 400,
//   data = [],
//   leftLegend,
//   bottomLegend,
//   rightLegend,
//   indexBy,
//   keys,
//   isMicro,
//   onBarClick = () => {},
//   lineData = [],
// }) => {
//   const [chartWidth, setChartWidth] = useState(width);
//   const [chartHeight, setChartHeight] = useState(height);
//   const isMobile = useMobileScreen();
//   const chartMaxHeight = isMobile ? 260 : maxHeight;
//   const axisArea = {
//     top: 8,
//     right: 8,
//     bottom: 36,
//     left: 36,
//   };
//   const chartAreaWidth = chartWidth - axisArea.left - axisArea.right;
//   const chartAreaHeight = chartHeight - axisArea.top - axisArea.bottom;
//   const svgNode = useRef(null);
//   const svgNodeWrapper = useRef(null);
//   const lData =
//     lineData.length > 0
//       ? lineData
//       : data.map((item) => ({
//           y: item[keys[0]],
//           x: new Date(item[indexBy]),
//         }));

//   const commulativeData = [{ id: 'line-chart', color: '#D96FF8', data: lData }];

//   const setDiamensions = (mutation) => {
//     const [currentMutationObject] = mutation;
//     const {
//       contentRect: { width: contentRectWidth, height: contentRectHeight },
//     } = currentMutationObject;
//     if (contentRectHeight !== chartHeight || contentRectWidth !== chartWidth) {
//       setChartWidth(contentRectWidth);
//       setChartHeight(contentRectHeight);
//     }
//   };

//   useEffect(() => {
//     renderChart();
//     return () => {};
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data, chartWidth, chartHeight]);

//   useEffect(() => {
//     const observer = new ResizeObserver(setDiamensions);
//     observer.observe(svgNodeWrapper.current);
//     return () => {
//       if (svgNodeWrapper.current) {
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//         observer.unobserve(svgNodeWrapper.current);
//         observer.disconnect();
//       }
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [svgNodeWrapper.current]);

//   const calculateTooltipDiamension = (event) => {
//     const tooltipHoverMargin = 16;
//     const { offsetX: x, offsetY: y } = event;
//     const { width: tooltipWidth, height: tooltipHeight } = document
//       .getElementById(`${chartId}-line-tooltip`)
//       .getBoundingClientRect();

//     const left = clamp(
//       tooltipHoverMargin,
//       x + tooltipHoverMargin,
//       chartAreaWidth - tooltipWidth - tooltipHoverMargin + axisArea.left,
//     );

//     const top =
//       chartAreaHeight > y + tooltipHoverMargin + tooltipHeight
//         ? y + tooltipHoverMargin
//         : y - tooltipHeight - tooltipHoverMargin;

//     return { left, top };
//   };

//   function handleMouseOverVoronoiWrapper(event) {
//     const point = event.target.__data__;
//     const { left, top } = calculateTooltipDiamension(event);

//     d3.select(`#${chartId}-line-tooltip`)
//       .style('visibility', 'visible')
//       .style('top', `${top}px`)
//       .style('left', `${left}px`);

//     d3.select(`#${chartId}-point-wrapper .tooltip-title`).text(
//       formatDate(point.x, true),
//     );

//     d3.select(`#${chartId}-point-wrapper .tooltip-title-lebel`).text(
//       abbreviateNumber(point.y, 'Ξ'),
//     );
//   }

//   function handleMouseMove(event) {
//     const { left, top } = calculateTooltipDiamension(event);
//     d3.select(`#${chartId}-line-tooltip`)
//       .style('top', `${top}px`)
//       .style('left', `${left}px`);
//   }

//   const handleMouseOut = () => {
//     d3.selectAll('.point-ls').style('display', 'none');
//     d3.select(`#${chartId}-line-tooltip`).style('visibility', 'hidden');
//   };

//   const renderChart = () => {
//     const svgObj = d3
//       .select(svgNode.current)
//       .select(`#${chartId}-chart-wrapper`);

//     const xLine = d3
//       .scaleTime()
//       .domain(d3.extent(lData, (d) => d.x))
//       .range([0, chartAreaWidth]);

//     const maxYLine =
//       Number(d3.max(lData, (d) => d.y)) + d3.max(lData, (d) => d.y) * 0.2;

//     const yLine = d3
//       .scaleLinear()
//       .domain([0, maxYLine])
//       .range([chartAreaHeight, 0]);

//     const x0 = d3
//       .scaleBand()
//       .domain(data.map((d) => d[indexBy]))
//       .rangeRound([0, chartAreaWidth])
//       .paddingInner(0.5)
//       .range([0, chartAreaWidth]);

//     const x1 = d3
//       .scaleBand()
//       .domain(keys)
//       .rangeRound([0, x0.bandwidth()])
//       .padding(0.05);

//     const maxY =
//       Number(d3.max(data, (d) => d3.max(keys, (key) => d[key]))) +
//       d3.max(data, (d) => d3.max(keys, (key) => d[key])) * 0.2;
//     const y = d3
//       .scaleLinear()
//       .domain([0, maxY])
//       .nice()
//       .rangeRound([chartAreaHeight, 0]);

//     if (!isMicro) {
//       svgObj
//         .select('#bottom-axis')
//         .attr('stroke-width', 0)
//         .attr('transform', `translate(0, ${chartAreaHeight})`)
//         .call(
//           d3
//             .axisBottom(x0)
//             .tickPadding(12)
//             .ticks(8)
//             .tickSize(0)
//             .tickFormat((d) => {
//               return isDate(d) ? multiFormat(d) : d;
//             }),
//         )
//         .selectAll('text')
//         .call(wrap, x0.bandwidth());

//       svgObj
//         .select('#left-axis')
//         .attr('stroke-width', 0)
//         .call(
//           d3
//             .axisLeft(yLine)
//             .tickSize(-chartAreaWidth)
//             .tickPadding(8)
//             .ticks(8)
//             .tickFormat((d) => addSuffixPrefix(d, 'Ξ')),
//         );
//     }

//     svgObj
//       .selectAll(`.${chartId}-overlapping-line-path`)
//       .data(commulativeData)
//       .attr('d', (d) => {
//         return d3
//           .line()
//           .curve(d3.curveMonotoneX)
//           .x((i) => xLine(new Date(i.x)))
//           .y((i) => yLine(+i.y))(d.data);
//       });

//     svgObj
//       .selectAll('.rect-group')
//       .data(data)
//       .attr('transform', (d) => `translate(${x0(d[indexBy])},0)`)
//       .selectAll('.rect')
//       .data((d) => {
//         return keys.map((key) => ({
//           key,
//           value: d[key],
//           color: d[`${key}Color`],
//           keyData: d,
//         }));
//       })
//       .attr('x', (d) => {
//         return x1(d.key);
//       })
//       .attr('y', (d) => {
//         return y(d.value);
//       })
//       .attr('width', x1.bandwidth())
//       .attr('height', (d) => {
//         return y(0) - y(d.value);
//       })
//       .attr('fill', (d) => d.color);

//     const voronoi = d3.Delaunay.from(
//       commulativeData[0].data,
//       (d) => xLine(d.x),
//       (d) => y(d.y),
//     ).voronoi([0, 0, chartAreaWidth, chartAreaHeight]);

//     svgObj
//       .select(`#${chartId}-voronoi-wrapper`)
//       .selectAll('path')
//       .data(commulativeData[0].data)
//       .attr('opacity', 0.5)
//       // .attr('stroke', 'pink')
//       .attr('fill', 'none')
//       .style('pointer-events', 'all')
//       .attr('d', (d, i) => voronoi.renderCell(i))
//       .on('mouseover', handleMouseOverVoronoiWrapper)
//       .on('mousemove', handleMouseMove)
//       .on('mouseout', handleMouseOut);
//   };

//   return (
//     <BarChartWrapper ref={svgNodeWrapper} maxHeight={chartMaxHeight}>
//       <StyledSvg ref={svgNode} viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
//         <g
//           id={`${chartId}-chart-wrapper`}
//           transform={`translate(${axisArea.left},${axisArea.top})`}
//           className="chart-wrapper"
//         >
//           {!isMobile && !isMicro && (
//             <>
//               <Axis id="bottom-axis" />
//               <Axis id="left-axis" />
//             </>
//           )}
//           {data.map((item) => (
//             <g
//               key={`${makeDivId(item[indexBy])}-${makeDivId(item[keys[0]])}`}
//               className="rect-group"
//               id={`bar-${item[indexBy]}`}
//             >
//               {keys.map((keyItem) => (
//                 <rect
//                   key={`rect-${item[indexBy]}-${keyItem}`}
//                   id={`rect-${item[indexBy]}-${keyItem}`}
//                   className="rect"
//                   color={item[`${keyItem}Color`]}
//                   rx={6}
//                   ry={6}
//                 />
//               ))}
//             </g>
//           ))}
//           {commulativeData.map((item) => (
//             <path
//               key={`line-${item.id}`}
//               id={`line-${item.id}`}
//               className={`${chartId}-overlapping-line-path`}
//               fill="none"
//               stroke={item.color}
//               strokeWidth="2"
//             />
//           ))}
//           {bottomLegend && !isMobile && !isMicro && (
//             <Legend
//               textAnchor="middle"
//               fontSize="14"
//               x={chartAreaWidth / 2}
//               y={chartAreaHeight + 52}
//             >
//               {bottomLegend}
//             </Legend>
//           )}
//           {leftLegend && !isMobile && !isMicro && (
//             <Legend
//               textAnchor="end"
//               fontSize="14"
//               x={-chartAreaHeight / 2 + 52}
//               y={-axisArea.left + 16}
//               transform="rotate(-90)"
//             >
//               {leftLegend}
//             </Legend>
//           )}
//           <g id={`${chartId}-voronoi-wrapper`}>
//             {commulativeData[0].data.map((item) => (
//               <path key={`${item.x.getTime()}`} />
//             ))}
//           </g>
//         </g>
//       </StyledSvg>
//       {!isMicro && (
//         <TooltipWrapper
//           isMobile={isMobile}
//           id={`${chartId}-line-tooltip`}
//           flexDirection="column"
//         >
//           <PointWrapper
//             id={`${chartId}-point-wrapper`}
//             flexDirection="column"
//             alignItems="center"
//           >
//             <TooltipTitle className="tooltip-title" color="gray10">
//               --
//             </TooltipTitle>
//             <TooltipTitle className="tooltip-title-lebel" color="gray10">
//               --
//             </TooltipTitle>
//           </PointWrapper>
//         </TooltipWrapper>
//       )}
//     </BarChartWrapper>
//   );
// };
