import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '../Box';
import { Table, compareRowsNumeric, compareRowsAlpha } from '../Table';
import { BarChart } from '../Charts';
import {
  // getTopCollectionsByMints,
  getTopCollectionsBySales,
} from '../../graphql/stats';
import placeholder from './assets/image-241.png';
// import { SimpleTabs } from '../Tabs';
import { prettyDate } from '../../utils/date.utils';
import { useEthPrice } from '../Sidebar/Sidebar';
import { formatETH } from '../../utils/eth';
import { roundToNearest, abbreviateNumber } from '../../utils/numbers';
// import { TableShimmer } from '../Shimmer/TableShimmer';
import { PercentChangeText } from '../Misc';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const filterList = ['5M', '15M', '1H', '12H', '1D', '7D'];

export const strToMinute = {
  '5M': 5,
  '15M': 15,
  '1H': 60,
  '12H': 720,
  '1D': 1440,
  '7D': 10080,
};

export const strToDetails = {
  '5M': '5 minutes',
  '15M': '15 minutes',
  '1H': '1 hour',
  '12H': '12 hours',
  '1D': '1 day',
  '7D': '7 days',
};

// const initialSelectedtab = {
//   id: 'trending',
//   label: 'Trending',
// };

export const AllCollectionTable = ({ onChangeTime }) => {
  // const [selectedTab, setSelectedTab] = useState(initialSelectedtab);
  const [selectedTime, setSelectedTime] = useState(filterList[2]);
  const {
    data: { getTopCollectionsBySales: getTopCollectionsBySalesData = [] } = {},
    loading,
    refetch,
  } = useQuery(getTopCollectionsBySales, { notifyOnNetworkStatusChange: true });
  // const {
  //   data: { getTopCollectionsByMints: mintsCollections = [] } = {},
  //   refetch: mintsRefetch,
  // } = useQuery(getTopCollectionsByMints);
  const etherPrice = useEthPrice();
  const navigate = useNavigate();
  const collections = getTopCollectionsBySalesData || [];

  const simplifiedData = collections.map((item) => ({
    id: item.collection?.id,
    name: [item.collection?.name, '/overview/' + item.collection?.slug],
    image: item.collection.imageUrl || placeholder,
    createdDate: item.collection.createdAt,
    floorPrice: item.floorPrice,
    volume: item.totalVolume,
    sales: item.totalSales,
    marketCap: item.marketCap,
    listings: item.totalListings,
    traitTypes: item.traitTypes,
    trends: item.trends,
    totalTokens: item.collection.totalTokens,
    sevenDayVolume: item.trends?.sevenDayVolume || [],
    sevenDayFloorPrice: item.trends?.sevenDayFloorPrice || [],
  }));

  // const mintsSimplifiedData = mintsCollections.map((item) => ({
  //   name: item.name,
  //   image: item.imageUrl || placeholder,
  //   mints: item.mints,
  //   mintPrice: item.mintPrice,
  //   totalMints: item.totalMints,
  //   mintVolume: item.mintVolume,
  //   gasVolume: item.gasVolume,
  // }));

  // const volumeDays = currentMockData
  //   .reduce((acc, item) => {
  //     if (acc.length < item.sevenDayVolume?.length) {
  //       return acc.concat(item.sevenDayVolume.map((isv) => isv.day));
  //     }

  //     return acc;
  //   }, [])
  //   .reduce((a, b) => (a.includes(b) ? a : a.concat(b)), [])
  //   .sort((a, b) => {
  //     const c = new Date(a);
  //     const d = new Date(b);
  //     return c - d;
  //   });
  const volumeDays = [...Array(7)]
    .map((_, i) => {
      const d = new Date();
      d.setDate(d.getDate() - i);
      return d;
    })
    .sort((a, b) => {
      const c = new Date(a);
      const d = new Date(b);
      return c - d;
    })
    .map((d) => d.toISOString().split('T')[0]);

  const buildData = (data = [], color) => {
    const result = {};
    result.data = volumeDays.map((item) => {
      const dataItem = data.find(
        (d) => new Date(d.day).toISOString().split('T')[0] === item,
      );
      return {
        y1: formatETH(dataItem?.value || 0),
        y1Color: color,
        value: item,
      };
    });
    result.keys = ['y1'];
    result.indexBy = ['value'];
    return result;
  };

  const columns = [
    {
      Header: 'Item',
      accessor: 'image',
      align: 'left',
      width: 160,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Flex flexDirection="row" alignItems="center">
          <img
            src={cell.value}
            className="rounded-full"
            alt="Collections"
            height={48}
            width={48}
          />
          <div className="flex flex-col justify-start align-baseline ml-2">
            <span className="text-gray-600 text-xs font-extrabold">
              {prettyDate(cell.row.original.createdDate)}
            </span>
            <span className="text-gray-600 text-xs font-extrabold">
              {cell.row.original.totalTokens.toLocaleString()}
            </span>
          </div>
        </Flex>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      align: 'left',
      width: 180,
      sortType: compareRowsAlpha,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-gray-200 font-bold text-sm break-all hover:text-white transition">
            {value[0]}
          </strong>
        </Flex>
      ),
    },
    {
      Header: 'Sales',
      accessor: 'sales',
      align: 'left',
      width: 112,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value = [] } }) => {
        const [previous = 0, current = 0] = value || [];
        let percent =
          ((Number(current) - Number(previous)) / (Number(previous) || 1)) *
          100;
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {Number(current || 0).toLocaleString()}
            </strong>
            <PercentChangeText changedValue={percent} showCaption={false} />
          </Flex>
        );
      },
    },
    {
      Header: 'Floor Price',
      accessor: 'floorPrice',
      align: 'left',
      width: 112,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        const [previous = 0, current = 0] = value || [];
        const ethPrevious = Number(formatETH(previous || 0));
        const ethCurrent = Number(formatETH(current || 0));
        const percent = roundToNearest(
          ((ethCurrent - ethPrevious) / (ethPrevious || 1)) * 100,
          2,
        );

        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(ethCurrent, 4)}Ξ
            </strong>
            <PercentChangeText changedValue={percent} showCaption={false} />
          </Flex>
        );
      },
    },
    {
      Header: 'Volume',
      accessor: 'volume',
      align: 'left',
      width: 100,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value = [] } }) => {
        const [previous = 0, current = 0] = value || [];
        const ethPrevious = Number(formatETH(previous || 0));
        const ethCurrent = Number(formatETH(current || 0));
        const percent = roundToNearest(
          ((ethCurrent - ethPrevious) / (ethPrevious || 1)) * 100,
          2,
        );
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(ethCurrent || 0, 2)}Ξ
            </strong>
            <PercentChangeText changedValue={percent} showCaption={false} />
          </Flex>
        );
      },
    },
    {
      Header: 'Market Cap',
      accessor: 'marketCap',
      align: 'left',
      width: 112,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        const [current = 0] = value || [];
        // const ethPrevious = Number(formatETH(previous || 0));
        const ethCurrent = Number(formatETH(current || 0));
        const marketCapInUsd = ( (ethCurrent || 0) * etherPrice );
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(ethCurrent || 0, (ethCurrent>99)?0:1).toLocaleString()}Ξ
            </strong>
            <span
              className={classNames(
                'text-xs font-semibold ',
                marketCapInUsd <= 0 ? 'text-red-900' : 'text-green-1100',
              )}
            >
              ${abbreviateNumber(marketCapInUsd, 1)} 
            </span>
          </Flex>
        );
      },
    },
    {
      Header: 'Listings',
      accessor: 'listings',
      align: 'left',
      sortType: compareRowsNumeric,
      width: 100,
      Cell: ({ cell: { value = [] } }) => {
        const [previous = 0, current = 0] = value || [];
        const percent =
          previous > 0
            ? roundToNearest(((current - previous) / (previous || 1)) * 100, 2)
            : 0;

        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {Number(current || 0).toLocaleString('en-US')}
            </strong>
            <PercentChangeText changedValue={percent} showCaption={false} />
          </Flex>
        );
      },
    },
    {
      Header: '7 Day Floor',
      accessor: 'sevenDayFloorPrice',
      align: 'left',
      width: 130,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const barData = buildData(cell.value, '#003CFF');
        console.log(barData);
        return (
          <Flex>
            <BarChart
              chartId={`sevenDayFloorPrice-${cell?.row?.id}`}
              data={barData.data}
              indexBy={barData.indexBy}
              keys={barData.keys}
              height={36}
              width={100}
              barWidth={8}
              isMicro
            />
          </Flex>
        );
      },
    },
    {
      Header: '7 Day Volume',
      accessor: 'sevenDayVolume',
      align: 'left',
      width: 120,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const barData = buildData(cell.value, '#E18CF9');
        return (
          <Flex>
            <BarChart
              chartId={`sevenDayVolume-${cell?.row?.id}`}
              data={barData.data}
              indexBy={barData.indexBy}
              keys={barData.keys}
              height={36}
              width={100}
              barWidth={8}
              isMicro
            />
          </Flex>
        );
      },
    },
  ];

  // const mintsColumns = [
  //   {
  //     Header: 'Item',
  //     accessor: 'image',
  //     align: 'left',
  //     width: 160,
  //     Cell: ({ cell }) => (
  //       <Flex flexDirection="row" alignItems="center">
  //         <img
  //           src={cell.value}
  //           className="rounded-full"
  //           alt="Collections"
  //           height={48}
  //           width={48}
  //         />
  //         <div className="flex flex-col justify-start align-baseline ml-2">
  //           <span className="text-gray-600">3 days ago</span>
  //           <span className="text-gray-600">
  //             {cell.row.original.totalTokens}
  //           </span>
  //         </div>
  //       </Flex>
  //     ),
  //   },
  //   {
  //     Header: 'Name',
  //     accessor: 'name',
  //     align: 'left',
  //     Cell: ({ cell: { value } }) => (
  //       <Flex flexDirection="column" alignItems="baseline">
  //         <strong className="text-gray-200 font-bold text-sm break-all">
  //           {value}
  //         </strong>
  //       </Flex>
  //     ),
  //   },
  //   {
  //     Header: 'Mints',
  //     accessor: 'mints',
  //     align: 'left',
  //     Cell: ({ cell: { value = [] } }) => {
  //       const [previous = 0, current = 0] = value || [];
  //       const percent = (
  //         ((current - previous) / (previous || 1)) *
  //         100
  //       ).toFixed(2);
  //       return (
  //         <Flex flexDirection="column" alignItems="baseline">
  //           <strong className="text-gray-200 font-bold text-sm">
  //             {Number(current || 0).toFixed(2)}
  //           </strong>
  //           <span
  //             className={classNames(
  //               'text-xs font-bold ',
  //               percent <= 0 ? 'text-red-900' : 'text-green-1100',
  //             )}
  //           >
  //             {percent}%
  //           </span>
  //         </Flex>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Mint Price',
  //     accessor: 'mintPrice',
  //     align: 'left',
  //     Cell: ({ cell: { value } }) => {
  //       return (
  //         <Flex flexDirection="column" alignItems="baseline">
  //           <strong className="text-gray-200 font-bold text-sm">
  //             {Number(value || 0).toFixed(2)}Ξ
  //           </strong>
  //         </Flex>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Total Mints',
  //     accessor: 'totalMints',
  //     align: 'left',
  //     Cell: ({ cell: { value } }) => {
  //       return (
  //         <Flex flexDirection="column" alignItems="baseline">
  //           <strong className="text-gray-200 font-bold text-sm">
  //             {Number(value || 0).toFixed(2)}
  //           </strong>
  //         </Flex>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Mint Volume',
  //     accessor: 'mintVolume',
  //     align: 'left',
  //     Cell: ({ cell: { value } }) => {
  //       return (
  //         <Flex flexDirection="column" alignItems="baseline">
  //           <strong className="text-gray-200 font-bold text-sm">
  //             {Number(value || 0).toFixed(2)}Ξ
  //           </strong>
  //         </Flex>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Gas Volume',
  //     accessor: 'gasVolume',
  //     align: 'left',
  //     Cell: ({ cell: { value } }) => {
  //       return (
  //         <Flex flexDirection="column" alignItems="baseline">
  //           <strong className="text-gray-200 font-bold text-sm">
  //             {Number(value || 0).toFixed(2)}
  //           </strong>
  //         </Flex>
  //       );
  //     },
  //   },
  // ];

  const handleChangeTimeframe = (timeFrame) => {
    setSelectedTime(timeFrame);
    onChangeTime(timeFrame);
    // if (selectedTab.id === 'minting') {
    //   mintsRefetch({ durationInMinutes: strToMinute[timeFrame] });
    // } else {
    refetch({ durationInMinutes: strToMinute[timeFrame] });
    // }
  };

  const handleRowClick = (row) => {
    const previousItems = localStorage.getItem('recentlyViewed');
    const previousItemsArray = JSON.parse(previousItems) || [];
    const { original: item } = row;
    const doesItemExists = previousItemsArray.some((pil) => pil.id === item.id);
    const newItem = {
      name: item.name?.[0],
      href: `/overview/${item.id}`,
      icon: item.image,
      id: item.id,
    };
    if (!doesItemExists) {
      if (previousItemsArray.length === 4) {
        previousItemsArray.pop();
      }
      const newItems = [newItem, ...(previousItemsArray || [])];
      localStorage.setItem('recentlyViewed', JSON.stringify(newItems));
    } else {
      const newItems = [
        newItem,
        ...(previousItemsArray || []).filter((pil) => pil.id !== item.id),
      ];
      localStorage.setItem('recentlyViewed', JSON.stringify(newItems));
    }
    navigate(`/overview/${item.id}`);
  };

  return (
    <div className="flex flex-col rounded-xl p-3 overflow-hidden">
      <div className="flex flex-row justify-end items-end rounded-2xl pb-1">
        {filterList.map((item) => (
          <button
            key={item}
            className={classNames(
              'outline-none px-2 rounded-2xl mr-1 last:mr-0 focus:outline-none ',
              selectedTime === item
                ? 'bg-blue-800 text-white'
                : 'text-gray-200',
            )}
            onClick={() => {
              handleChangeTimeframe(item);
            }}
          >
            <strong
              className={classNames(
                'text-xs font-bold',
                selectedTime === item ? 'text-white' : 'text-gray-300 hover:text-white transition',
              )}
            >
              {item}
            </strong>
          </button>
        ))}
      </div>
      <Table
        columns={columns}
        data={simplifiedData}
        loading={loading}
        hasPagination
        areRowsClickable="true"
        onRowClick={handleRowClick}
        canSort
        initialState={{ pageIndex: 0, sortBy: [{ id: 'sales', desc: true }] }}
        disableSortRemove="true"
      />
      {/* <SimpleTabs
        style={{ maxWidth: '50%' }}
        onClickTab={(selectedTab) => {
          setSelectedTab(selectedTab);
        }}
        secondaryHeader={
          <div className="flex flex-row justify-end items-end bg-gray-1200 rounded-2xl">
            {filterList.map((item) => (
              <button
                key={item}
                className={classNames(
                  'outline-none px-2 rounded-2xl mr-1 last:mr-0 focus:outline-none ',
                  selectedTime === item
                    ? 'bg-blue-800 text-white'
                    : 'text-gray-200',
                )}
                onClick={() => {
                  handleChangeTimeframe(item);
                }}
              >
                <strong
                  className={classNames(
                    'text-xs font-bold',
                    selectedTime === item ? 'text-white' : 'text-gray-200',
                  )}
                >
                  {item}
                </strong>
              </button>
            ))}
          </div>
        }
      >
        <div id="trending" label="Trending" className="overflow-x-auto">
          <Table
            columns={columns}
            data={simplifiedData}
            hasPagination
            canSort
          />
        </div>
        <div id="minting" label="Minting" className="overflow-x-auto">
          <Table
            columns={mintsColumns}
            data={mintsSimplifiedData}
            hasPagination
            canSort
          />
        </div>
      </SimpleTabs> */}
    </div>
  );
};
