import {API_ENDPOINT} from "../config";

let accessToken = undefined;

export const setAccessToken = (token) => (accessToken = token);

export const getAccessToken = () => accessToken;

export const fetchAccessToken = async () =>
  fetch(`${API_ENDPOINT}/auth/refresh_token`, {
    method: "GET",
    credentials: "include",
  });

export const refreshToken = () =>
  fetchAccessToken()
    .then((res) => res.json())
    .then(({accessToken}) => setAccessToken(accessToken))
    .catch((error) => console.error(error));
