export const Help = (props) => (
  <svg
    width={props.w || 20}
    height={props.h || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.229 13.771C8.312 15.854 11.688 15.854 13.771 13.771C15.854 11.688 15.854 8.312 13.771 6.229C11.688 4.146 8.312 4.146 6.229 6.229C4.146 8.312 4.146 11.688 6.229 13.771Z"
    />
    <path
      d="M3.4 16.6C7.045 20.245 12.955 20.245 16.6 16.6C20.245 12.955 20.245 7.045 16.6 3.4C12.955 -0.245 7.045 -0.245 3.4 3.4C-0.244 7.045 -0.244 12.955 3.4 16.6Z"
      fill="black"
    />
    <path
      d="M13.771 13.771C11.688 15.854 8.312 15.854 6.229 13.771M13.771 13.771C15.854 11.688 15.854 8.312 13.771 6.229M13.771 13.771L16.6 16.6M6.229 13.771C4.146 11.688 4.146 8.312 6.229 6.229M6.229 13.771L3.4 16.6M13.771 6.229C11.688 4.146 8.312 4.146 6.229 6.229M13.771 6.229L16.6 3.4M6.229 6.229L3.4 3.4M16.6 16.6C12.955 20.245 7.045 20.245 3.4 16.6M16.6 16.6C20.245 12.955 20.245 7.045 16.6 3.4M3.4 16.6C-0.244 12.955 -0.244 7.045 3.4 3.4M16.6 3.4C12.955 -0.245 7.045 -0.245 3.4 3.4"
      strokeWidth={1.3333333333333333}
    />
  </svg>
);

export default Help;
