import { gql } from "@apollo/client";

export const getKpis = gql`
  query getKpis($durationInHours: Float! = 24) {
    getKPIs(durationInHours: $durationInHours) {
      durationInHours
      stats {
        nftsInCollection {
          value
          percentageChange
        }
        nftsPurchased {
          value
          percentageChange
        }
        nftsTransferred {
          value
          percentageChange
        }
        nftsSold {
          value
          percentageChange
        }
        totalCollectionValue {
          value
          percentageChange
        }
        totalSpentOnNfts {
          value
          percentageChange
        }
        totalNftSales {
          value
          percentageChange
        }
        allTimeProfitLoss {
          grossSales
          costOfSales
          gasFees
          royalties
          marketFees
        }
        averageNftPrice {
          value
          percentageChange
        }
      }
    }
  }
`;

export const getStats = gql`
  query getStats($collectionSlug: String!, $durationInHours: Float = 24) {
    getCollectionStats(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        statNames: [salesVolume, avgPrice, floorPrice]
      }
    ) {
      stats {
        statName
        values {
          time
          value
        }
      }
    }
  }
`;

export const getCollection = gql`
  query getCollection($collectionSlug: String!) {
    getCollection(collectionSlug: $collectionSlug) {
      id
      name
      slug
      featured
      description
      imageUrl
      largeImageUrl
      bannerImageUrl
      featuredImageUrl
      royalty
      externalUrl
      createdAt
      totalTokens
      # numOwners
      traitTypes {
        name
        value
        count
      }
    }
  }
`;

export const getCollectionTraits = gql`
  query getCollection($collectionSlug: String!) {
    getCollection(collectionSlug: $collectionSlug) {
      totalTokens
      traitTypes {
        name
        value
        count
      }
    }
  }
`;

export const getUser = gql`
  query getUser {
    getUser {
      user {
        id
        username
        address
        otherWallets {
          address
        }
        providerName
        verified
        createdAt
        syncedAt
        pfpToken {
          contract
          tokenID
        }
        bannerTokens {
          contract
          tokenID
        }
      }
      firstBuy
      lastBuy
      lastSale
    }
  }
`;

// preferences {
//   bannerTokens {
//     contract
//     tokenID
//   }
//   pfpToken {
//     contract
//     tokenID
//   }
// }

export const getCollectionKPIs = gql`
  query getCollectionKPIs($collectionSlug: String!) {
    getCollectionKPIs(input: { collectionSlug: $collectionSlug }) {
      durationInHours
      collection {
        id
      }
      stats {
        floorPrice {
          value
          percentageChange
        }
        marketCap {
          value
          percentageChange
        }
        volume {
          value
          percentageChange
        }
        numberOfSales {
          value
          percentageChange
        }
        numberOfUniqueOwners {
          value
          percentageChange
        }
        numberOfItemsForSale {
          value
          percentageChange
        }
        maxPrice {
          value
          percentageChange
        }
        averagePrice {
          value
          percentageChange
        }
      }
    }
  }
`;

export const getCollectionListingStats = gql`
  query getCollectionStats(
    $collectionSlug: String!
    $durationInHours: Float = 168
  ) {
    getCollectionStats(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        statNames: [avgPrice, floorPrice]
      }
    ) {
      stats {
        statName
        values {
          time
          value
        }
      }
    }
  }
`;

export const getCollectionFloorStats = gql`
  query getCollectionStats(
    $collectionSlug: String!
    $durationInHours: Float = 168
  ) {
    getCollectionStats(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        statNames: [floorPrice, salesVolume]
      }
    ) {
      stats {
        statName
        values {
          time
          value
        }
      }
    }
  }
`;

export const getCollectionUniqueOwnersStats = gql`
  query getCollectionStats(
    $collectionSlug: String!
    $durationInHours: Float = 168
  ) {
    getCollectionStats(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        statNames: [uniqueOwners]
      }
    ) {
      stats {
        statName
        values {
          time
          value
        }
      }
    }
  }
`;

// export const getCollectionFloorPriceStats = gql`
//   query getCollectionStats(
//     $collectionSlug: String!
//     $durationInHours: Float = 24
//   ) {
//     getCollectionStats(
//       input: {
//         collectionSlug: $collectionSlug
//         durationInHours: $durationInHours
//         statNames: [floorPrice]
//       }
//     ) {
//       stats {
//         statName
//         values {
//           time
//           value
//         }
//       }
//     }
//   }
// `;

export const getCollectionStats = gql`
  query getCollectionStats(
    $collectionSlug: String!
    $durationInHours: Float = 24
  ) {
    getCollectionStats(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        statNames: [salesVolume, avgPrice, floorPrice]
      }
    ) {
      stats {
        statName
        values {
          time
          value
        }
      }
    }
  }
`;

export const getRecentListing = gql`
  query getRecentListing($collectionSlug: String!) {
    getRecentListing(input: { collectionSlug: $collectionSlug }) {
      tokenId
      contractAddress
      image
      listing {
        listingDate
        basePrice
        listedBy
      }
      lastSold {
        from
      }
      rarity {
        score
      }
    }
  }
`;

export const getRecentSales = gql`
  query getRecentSales($collectionSlug: String!) {
    getRecentSales(input: { collectionSlug: $collectionSlug }) {
      tokenId
      contractAddress
      image
      listing {
        listingDate
        basePrice
      }
      lastSold {
        from
        saleDate
        salePrice
        to
      }
      rarity {
        score
      }
    }
  }
`;

//purchasedAt (after image) - timestamp (within transfer)
export const getTokens = gql`
  query getTokens($next: String) {
    getTokens(input: { next: $next }) {
      next
      tokens {
        tokenId
        contractAddress
        image
        purchasedAt
        transaction {
          from
        }
        collection {
          id
          name
          slug
          royalty
          floorPrice
          alchemyFloorPrice
        }
        listing {
          basePrice
        }
        rarity {
          score
        }
        transfer {
          to
          from
          value
          gasFee
          currency
        }
      }
    }
  }
`;

export const getSellWall = gql`
  query getSellWall($collectionSlug: String!) {
    getSaleWall(input: { collectionSlug: $collectionSlug }) {
      groups {
        start
        end
        count
      }
      floorPrice
    }
  }
`;

export const getTopCollectionsBySales = gql`
  query getTopCollectionsBySales($durationInMinutes: Float = 60) {
    getTopCollectionsBySales(input: { durationInMinutes: $durationInMinutes }) {
      collection {
        id
        name
        slug
        imageUrl
        totalTokens
        createdAt
      }
      totalSales
      totalListings
      marketCap
      totalVolume
      floorPrice
      trends {
        sevenDayVolume {
          day
          value
        }
        sevenDayFloorPrice {
          day
          value
        }
      }
    }
  }
`;

export const getTransactions = gql`
  query getTransactions(
    $collectionSlug: String!
    $durationInMinutes: Float! = 1440
  ) {
    getTransactions(
      input: {
        collectionSlug: $collectionSlug
        durationInMinutes: $durationInMinutes
      }
    ) {
      token {
        tokenId
        image
      }
      saleDate
      salePrice
      ndUser
    }
  }
`;

export const getTopCollectionsByMints = gql`
  query getTopCollectionsByMints($durationInMinutes: Float! = 5) {
    getTopCollectionsByMints(input: { durationInMinutes: $durationInMinutes }) {
      createdAt
      updatedAt
      id
      name
      slug
      featured
      description
      imageUrl
      bannerImageUrl
      largeImageUrl
      featuredImageUrl
      royalty
      floorPrice
      totalVolume
      totalSales
      marketCap
      totalTokens
      # numOwners
      traitTypes {
        value
        name
        count
      }
      mints
      gasVolume
      mintVolume
      mintPrice
    }
  }
`;

export const getProfitLoss = gql`
  query getProfitLoss($durationObj: ProfitLossInput! = {}) {
    getProfitLoss(input: $durationObj) {
      grossSales
      costOfSales
      gasFees
      royalties
      marketFees
    }
  }
  # query getProfitLoss {
  #   getProfitLoss(input: {}) {
  #     grossSales
  #     costOfSales
  #     gasFees
  #     royalties
  #   }
  # }
`;

export const getTraitsBreakdown = gql`
  query getTraitsBreakdown($collectionSlug: String!) {
    getTraitsBreakdown(input: { collectionSlug: $collectionSlug }) {
      category
      value
      floorPrice
      averagePrice
      maxPrice
      sales
    }
  }
`;

// export const getTraitsFloorPrice = gql`
//   query getTraitsFloorPrice(
//     $collectionSlug: String!
//     $durationInMinutes: Float! = 1440
//     $traits: [TraitInput!]!
//     $op: String!
//   ) {
//     getTraitsFloorPrice(
//       input: {
//         collectionSlug: $collectionSlug
//         durationInMinutes: $durationInMinutes
//         traits: $traits
//         op: $op
//       }
//     ) {
//       name
//       value
//       prices {
//         date
//         value
//       }
//     }
//   }
// `;

export const getTraitsFloorPrice = gql`
  query getTraitsFloorPrice(
    $collectionSlug: String!
    $durationInHours: Float! = 168
    $traits: [TraitInput!]!
  ) {
    getTraitsFloorPrice(
      input: {
        collectionSlug: $collectionSlug
        durationInHours: $durationInHours
        traits: $traits
      }
    ) {
      name
      value
      data {
        date
        floorPrice
      }
    }
  }
`;

export const getTraitsTransactions = gql`
  query getTransactions(
    $collectionSlug: String!
    $durationInMinutes: Float! = 1440
  ) {
    getTransactions(
      input: {
        collectionSlug: $collectionSlug
        durationInMinutes: $durationInMinutes
      }
    ) {
      id
      saleDate
      salePrice
      token {
        tokenId
        name
        uri
        image
        traits {
          value
          name
          count
        }
      }
    }
  }
`;

export const getTransfers = gql`
  query getTransfers {
    getTransfers {
      from
      to
      category
      timestamp
      token {
        contractAddress
        tokenId
        type
        name
        uri
        image
        decimals
      }
      transaction {
        from
        to
        value
        gas
        gasPrice
      }
    }
  }
`;

export const searchCollections = gql`
  query searchCollections($slug: String! = "boss") {
    searchCollections(slug: $slug) {
      id
      name
      slug
      imageUrl
      totalTokens
      marketCap
    }
  }
`;
