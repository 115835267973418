import { useQuery } from "@apollo/client";
import { getKpis } from "../../graphql/stats";
import { getCollectionKPIs } from "../../graphql/stats";
import { KPI } from "./KPI";
import { useParams } from "react-router-dom";
import { formatETH, formatETHNum } from "../../utils/eth";

function getNetIncome(plDat = 0) {
  const incomePreTax =
    formatETHNum(plDat?.grossSales || 0) -
    (formatETHNum(plDat?.costOfSales || 0) +
      formatETHNum(plDat?.gasFees || 0) +
      formatETHNum(plDat?.royalties || 0) +
      formatETHNum(plDat?.marketFees || 0));

  console.log("getNetIncome plDat", plDat);

  // const estTax = incomePreTax * 0.28; //removed tax for beta launch
  // const netIncome = incomePreTax - estTax; //removed tax for beta launch

  // console.log('gross sales: ' + formatETHNum(plDat.grossSales));
  // console.log('minus: ' + (formatETHNum(plDat.costOfSales) + formatETHNum(plDat.gasFees) + formatETHNum(plDat.royalties)));
  // console.log('income pre tax: ' + incomePreTax);
  // console.log('est tax: ' + estTax);
  // console.log('net: ' + netIncome);

  // return netIncome; //removed tax for beta launch
  return incomePreTax;
}

function percentChange(startVal, endVal) {
  return ((endVal - startVal) / startVal) * 100;
}

export default function KPIs(props) {
  const params = useParams();
  var currentAPI;
  let currentVars = { variables: "" };
  if (props.page === "my collection") {
    currentAPI = getKpis;
  } else {
    currentAPI = getCollectionKPIs;
    currentVars.variables = {
      collectionSlug: params.collection,
    };
  }

  const { data = {}, loading } =
    useQuery(currentAPI, {
      ...currentVars,
      notifyOnNetworkStatusChange: true,
    }) || {};

  const kpiStatsData = data[Object.keys(data)[0]]?.stats; //<- best way I could come up w/ to deal with dynamic (conditional) currentAPI

  //getNetIncome(kpiStatsData.allTimeProfitLoss[1]);
  //console.log(kpiStatsData.allTimeProfitLoss);

  if (props.page === "my collection") {
    return (
      <div className="flex flex-col mt-6">
        <h1 className="text-white text-2xl font-extrabold">KPIs</h1>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-4">
          <KPI
            label="NFTs in collection"
            type="count"
            places="0"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.nftsInCollection?.value || 0}
            changedValue={kpiStatsData?.nftsInCollection?.percentageChange || 0}
          />
          <KPI
            label="NFTs purchased"
            type="count"
            places="0"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.nftsPurchased?.value || 0}
            changedValue={kpiStatsData?.nftsPurchased?.percentageChange || 0}
          />
          <KPI
            label="NFTs sold"
            type="count"
            places="0"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.nftsSold?.value || 0}
            changedValue={kpiStatsData?.nftsSold?.percentageChange || 0}
          />
          <KPI
            label="Total collection value"
            type="currency"
            places="1"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.totalCollectionValue?.value || 0)}
            changedValue={
              kpiStatsData?.totalCollectionValue?.percentageChange || 0
            }
          />
          <KPI
            label="Total spent on NFTs"
            type="currency"
            places="1"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.totalSpentOnNfts?.value || 0)}
            //value={formatETH("8743857485768587747584754")} // for testing
            changedValue={kpiStatsData?.totalSpentOnNfts?.percentageChange || 0}
          />
          <KPI
            label="Total NFT sales"
            type="currency"
            places="1"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.totalNftSales?.value || 0)}
            changedValue={kpiStatsData?.totalNftSales?.percentageChange || 0}
          />
          <KPI
            label="All-time P&L"
            type="currency"
            places="1"
            aria-hidden="true"
            loading={loading}
            value={getNetIncome(kpiStatsData?.allTimeProfitLoss?.[1] || 0)}
            changedValue={percentChange(
              kpiStatsData?.allTimeProfitLoss?.[0] || 0,
              kpiStatsData?.allTimeProfitLoss?.[1] || 0
            )}
          />
          <KPI
            label="Average NFT price"
            type="currency"
            places="2"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.averageNftPrice?.value || 0)}
            changedValue={kpiStatsData?.averageNftPrice?.percentageChange || 0}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col mt-6">
        <h1 className="text-white text-2xl font-extrabold">KPIs</h1>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-4">
          <KPI
            label="Floor Price"
            type="currency"
            aria-hidden="true"
            places="3"
            loading={loading}
            value={formatETH(kpiStatsData?.floorPrice?.value || 0)}
            changedValue={kpiStatsData?.floorPrice?.percentageChange || 0}
          />
          <KPI
            label="Market Cap"
            places="1"
            type="currency"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.marketCap?.value || 0)}
            changedValue={kpiStatsData?.marketCap?.percentageChange || 0}
          />
          <KPI
            label="Volume 24H"
            places="1"
            type="currency"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.volume?.value || 0)}
            changedValue={kpiStatsData?.volume?.percentageChange || 0}
          />
          <KPI
            label="Sales 24H"
            places="0"
            type="count"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.numberOfSales?.value || 0}
            changedValue={kpiStatsData?.numberOfSales?.percentageChange || 0}
          />
          <KPI
            label="Owners"
            type="count"
            places="1"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.numberOfUniqueOwners?.value || 0}
            changedValue={
              kpiStatsData?.numberOfUniqueOwners?.percentageChange || 0
            }
          />
          <KPI
            label="Listings"
            type="count"
            places="0"
            aria-hidden="true"
            loading={loading}
            value={kpiStatsData?.numberOfItemsForSale?.value || 0}
            changedValue={
              kpiStatsData?.numberOfItemsForSale?.percentageChange || 0
            }
          />
          <KPI
            label="Max Price"
            type="currency"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.maxPrice?.value || 0)}
            changedValue={kpiStatsData?.maxPrice?.percentageChange || 0}
          />
          <KPI
            label="Average price"
            type="currency"
            aria-hidden="true"
            loading={loading}
            value={formatETH(kpiStatsData?.averagePrice?.value || 0)}
            changedValue={kpiStatsData?.averagePrice?.percentageChange || 0}
          />
        </div>
      </div>
    );
  }
}
