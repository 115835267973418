import { darkTheme } from "./utils/theme";
import { ThemeProvider } from "styled-components";
import { Routes, Route, Navigate } from "react-router-dom";
import MyCollection from "./pages/MyCollection";
import AllCollection from "./pages/AllCollection";
import Overview from "./pages/Overview";
import Traits from "./pages/Traits";
import Gallery from "./pages/Gallery";
import Landing from "./pages/Landing";
import { Maintenance } from "./components/Misc/Maintenance";
import { RequireAuth } from "./pages/RequireAuth";
import { useEffect } from "react";
import ReactGA from "react-ga";
import ErrorBoundary from "./components/Misc/ErrorBoundary";

ReactGA.initialize("UA-245187430-1");

// this is the main app component
// it is responsible for routing and theme
// it also handles the maintenance page, google analytics
// it also handles the error boundary and the require auth component
// which is responsible for checking if the user is logged in
// and redirecting them to the login page if they are not
function App(props) {
  const maintenance = false;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {maintenance ? (
        <Maintenance />
      ) : (
        <ThemeProvider theme={darkTheme}>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <ErrorBoundary>
                    <MyCollection />
                  </ErrorBoundary>
                </RequireAuth>
              }
            />
            <Route path="login" element={<Landing />} />
            {/* <Route path="FAQ" element={<FAQ />} /> */}
            <Route
              path="all-collection"
              element={
                <RequireAuth>
                  <AllCollection />
                </RequireAuth>
              }
            />
            <Route
              path="overview"
              element={
                <RequireAuth>
                  <Overview />
                </RequireAuth>
              }
            />
            <Route
              path="overview/:collection"
              element={
                <RequireAuth>
                  <Overview />
                </RequireAuth>
              }
            />
            <Route
              path="traits"
              element={
                <RequireAuth>
                  <Traits />
                </RequireAuth>
              }
            />
            <Route
              path="traits/:collection"
              element={
                <RequireAuth>
                  <Traits />
                </RequireAuth>
              }
            />
            <Route
              path="gallery"
              element={
                <RequireAuth>
                  <Gallery />
                </RequireAuth>
              }
            />
            <Route
              path="gallery/:collection"
              element={
                <RequireAuth>
                  <Gallery />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
