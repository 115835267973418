import React from "react";
import styled from "styled-components";

const StyledSVG = styled.svg``;

const LogoLarge = (props) => {
  return (
    <StyledSVG
      width={props.width || "151"}
      height={props.height || "45"} //45
      viewBox="0 0 151 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path
      d="M69.3545 36.7374H70.7759V43.9283H69.3545V36.7374ZM74.9869 36.7374V38.1129H71.5959V36.7374H74.9869ZM71.5959 39.628H73.187V41.0035H71.5959V39.628ZM71.5959 42.5525H74.9869V43.9281H71.5959V42.5525Z"
      fill="#D96FF8"
    />
    <path
      d="M79.9318 38.2404C80.26 37.7355 80.6934 37.3403 81.232 37.0549C81.7706 36.7696 82.3695 36.6267 83.0296 36.6267C83.6732 36.6267 84.2561 36.7638 84.7784 37.0381C85.3005 37.3122 85.7221 37.6915 86.0421 38.1757L84.8876 39.0319C84.6966 38.7178 84.4387 38.4708 84.1137 38.2912C83.7887 38.1117 83.4255 38.0218 83.0247 38.0218C82.6366 38.0218 82.2816 38.1044 81.9598 38.2695C81.638 38.4346 81.3751 38.6623 81.1714 38.9525C80.9676 39.2427 80.8382 39.5737 80.7833 39.9458H79.3521C79.4102 39.3138 79.6036 38.7454 79.9318 38.2404ZM81.2344 43.6107C80.6976 43.3254 80.2642 42.9302 79.9343 42.4252C79.6044 41.9203 79.4102 41.3519 79.3521 40.7201H80.7833C80.8382 41.089 80.9676 41.4193 81.1714 41.711C81.3751 42.0029 81.637 42.2313 81.9573 42.3964C82.2776 42.5615 82.6332 42.6441 83.0247 42.6441C83.4226 42.6441 83.784 42.5535 84.109 42.3722C84.434 42.191 84.6937 41.945 84.8876 41.6338L86.0421 42.4804C85.7221 42.9678 85.3005 43.3496 84.7784 43.6251C84.2561 43.9009 83.6729 44.0387 83.0296 44.0387C82.3695 44.0387 81.7713 43.8961 81.2344 43.6107Z"
      fill="#D96FF8"
    />
    <path
      d="M90.1406 36.7374H95.7779V38.1129H90.1406V36.7374ZM92.2462 38.868H93.6726V43.9281H92.2462V38.868Z"
      fill="#D96FF8"
    />
    <path
      d="M101.275 36.7374H102.697V43.9283H101.275V36.7374Z"
      fill="#D96FF8"
    />
    <path
      d="M107.968 36.7374H109.424L111.757 43.9283H110.311L107.968 36.7374ZM111.451 40.5709L112.698 36.7374H114.125L112.16 42.7499L111.451 40.5709Z"
      fill="#D96FF8"
    />
    <path
      d="M118.602 36.7374H120.023V43.9283H118.602V36.7374ZM124.234 36.7374V38.1129H120.843V36.7374H124.234ZM120.843 39.628H122.434V41.0035H120.843V39.628ZM120.843 42.5525H124.234V43.9281H120.843V42.5525Z"
      fill="#D96FF8"
    /> */}
      <path
        d="M116.87 0.698181H109.933V28.2085H116.87V0.698181Z"
        fill="#1A50FF"
      />
      <path
        d="M118.47 0.698181V7.5757H143.432V21.331H118.47V28.2085H150.369V0.698181H118.47Z"
        fill="#1A50FF"
      />
      <path
        d="M83.3708 0.698181H76.4341V28.2085H83.3708V0.698181Z"
        fill="#D96FF8"
      />
      <path
        d="M108.336 7.57576V0.698242L84.9685 0.698242V7.57576L108.336 7.57576Z"
        fill="#D96FF8"
      />
      <path
        d="M74.8379 28.2084V21.3308L42.9374 21.3308V28.2084L74.8379 28.2084Z"
        fill="#FFDE54"
      />
      <path
        d="M74.8379 7.57558V0.698059L42.9374 0.698059V7.57558L74.8379 7.57558Z"
        fill="#FFDE54"
      />
      <path
        d="M74.8379 17.892V11.0144L42.9374 11.0144V17.892L74.8379 17.892Z"
        fill="#FFDE54"
      />
      <path
        d="M7.83956 0.698303H0.902832V28.2086H7.83956V0.698303Z"
        fill="#FA0082"
      />
      <path
        d="M41.3386 0.698181H34.4019V28.2085H41.3386V0.698181Z"
        fill="#FF8B34"
      />
      <path
        d="M9.43701 1.84241V10.3271L32.8042 27.0645V18.5799L9.43701 1.84241Z"
        fill="#FA0082"
      />
    </StyledSVG>
  );
};

const LogoShort = () => {
  return (
    <StyledSVG
      width="96"
      height="90"
      viewBox="0 0 96 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0656 77.6699C3.37076 77.1957 3.77371 76.8249 4.27446 76.5569C4.77522 76.2889 5.33235 76.1548 5.94587 76.1548C6.54443 76.1548 7.08633 76.2835 7.57212 76.5411C8.05791 76.7987 8.44964 77.1545 8.74732 77.6089L7.67366 78.4127C7.49623 78.1177 7.25628 77.8858 6.95406 77.7173C6.65185 77.5487 6.31409 77.4642 5.94133 77.4642C5.58059 77.4642 5.25032 77.5418 4.95104 77.6967C4.65177 77.8518 4.40754 78.0655 4.21808 78.3378C4.02863 78.6104 3.90838 78.9212 3.85708 79.2703H2.52637C2.58061 78.6778 2.76018 78.1442 3.0656 77.6699ZM4.2766 82.7118C3.77745 82.4441 3.3745 82.0728 3.06774 81.5988C2.76098 81.1246 2.58061 80.5912 2.52637 79.9979H3.85708C3.90812 80.344 4.02863 80.6543 4.21808 80.9282C4.40754 81.2021 4.65123 81.4167 4.94891 81.5718C5.24658 81.7269 5.57739 81.8043 5.94133 81.8043C6.31115 81.8043 6.6473 81.7192 6.94952 81.5491C7.25174 81.3789 7.49303 81.1478 7.67366 80.856L8.74732 81.6507C8.44964 82.1084 8.05791 82.4666 7.57212 82.7255C7.08633 82.9844 6.54443 83.1139 5.94587 83.1139C5.33235 83.1136 4.77602 82.9799 4.2766 82.7118Z"
        fill="#D96FF8"
      />
      <path
        d="M12.8899 77.6745C13.1996 77.2002 13.6071 76.8284 14.1124 76.559C14.6177 76.2897 15.1799 76.1548 15.7996 76.1548C16.419 76.1548 16.9823 76.2897 17.4889 76.559C17.9955 76.8284 18.4038 77.2002 18.7135 77.6745C19.0232 78.1487 19.2052 78.6808 19.2594 79.2708H17.9242C17.8729 78.9215 17.7513 78.6101 17.5586 78.336C17.3663 78.0621 17.1172 77.8483 16.8121 77.6948C16.5069 77.5413 16.1691 77.4645 15.7993 77.4645C15.4295 77.4645 15.0918 77.5413 14.7866 77.6948C14.4814 77.8483 14.2324 78.0621 14.04 78.336C13.8476 78.6099 13.7258 78.9215 13.6745 79.2708H12.3438C12.3983 78.6808 12.5802 78.1487 12.8899 77.6745ZM14.1124 82.7097C13.6071 82.4403 13.1996 82.0693 12.8899 81.5967C12.5802 81.124 12.3983 80.5912 12.344 79.9982H13.6747C13.7258 80.3443 13.8484 80.6546 14.0424 80.9285C14.2364 81.2024 14.4852 81.4169 14.789 81.5721C15.0928 81.7272 15.4295 81.8045 15.7996 81.8045C16.1694 81.8045 16.5064 81.7269 16.8099 81.5721C17.1137 81.4169 17.3625 81.2027 17.5565 80.9285C17.7505 80.6546 17.8729 80.3446 17.9242 79.9982H19.2594C19.2052 80.5912 19.0232 81.1243 18.7135 81.5967C18.4038 82.0693 17.9955 82.4403 17.4889 82.7097C16.982 82.9793 16.419 83.1139 15.7996 83.1139C15.1799 83.1136 14.6177 82.9791 14.1124 82.7097Z"
        fill="#D96FF8"
      />
      <path
        d="M23.4521 76.2589H24.774V81.0095H23.4521V76.2589ZM23.4521 81.7184H28.6895V83.0098H23.4521V81.7184V81.7184Z"
        fill="#D96FF8"
      />
      <path
        d="M32.7554 76.2589H34.0773V81.0095H32.7554V76.2589ZM32.7554 81.7184H37.9927V83.0098H32.7554V81.7184V81.7184Z"
        fill="#D96FF8"
      />
      <path
        d="M41.874 76.2589H43.1959V83.0098H41.874V76.2589ZM47.1114 76.2589V77.5504H43.9583V76.2589H47.1114ZM43.9583 78.9729H45.4378V80.2643H43.9583V78.9729ZM43.9583 81.7184H47.1114V83.0098H43.9583V81.7184Z"
        fill="#D96FF8"
      />
      <path
        d="M51.7092 77.6699C52.0143 77.1957 52.4173 76.8249 52.918 76.5569C53.4188 76.2889 53.9759 76.1548 54.5894 76.1548C55.1877 76.1548 55.7299 76.2835 56.2157 76.5411C56.7012 76.7984 57.0932 77.1545 57.3906 77.6089L56.3169 78.4127C56.1395 78.1177 55.8996 77.8858 55.5973 77.7173C55.2951 77.5487 54.9576 77.4642 54.5846 77.4642C54.2239 77.4642 53.8936 77.5418 53.5943 77.6967C53.2951 77.8518 53.0508 78.0655 52.8614 78.3378C52.6719 78.6104 52.5517 78.9212 52.5006 79.2703H51.1699C51.2242 78.6778 51.404 78.1442 51.7092 77.6699ZM52.9204 82.7118C52.421 82.4441 52.0183 82.0728 51.7113 81.5988C51.4045 81.1246 51.2242 80.5912 51.1699 79.9979H52.5006C52.5517 80.344 52.6719 80.6543 52.8614 80.9282C53.0508 81.2021 53.2945 81.4167 53.5922 81.5718C53.8899 81.7269 54.2207 81.8043 54.5846 81.8043C54.9544 81.8043 55.2906 81.7192 55.5928 81.5491C55.895 81.3789 56.1363 81.1478 56.3169 80.856L57.3906 81.6507C57.0929 82.1084 56.7012 82.4666 56.2157 82.7255C55.7299 82.9844 55.1877 83.1139 54.5894 83.1139C53.9759 83.1136 53.4196 82.9799 52.9204 82.7118Z"
        fill="#D96FF8"
      />
      <path
        d="M61.2021 76.2589H66.444V77.5504H61.2021V76.2589ZM63.1597 78.2592H64.4859V83.0098H63.1597V78.2592Z"
        fill="#D96FF8"
      />
      <path
        d="M71.5552 76.2589H72.8771V83.0098H71.5552V76.2589Z"
        fill="#D96FF8"
      />
      <path
        d="M77.7788 76.2589H79.1322L81.302 83.0098H79.9576L77.7788 76.2589ZM81.0179 79.858L82.1774 76.2589H83.5035L81.6766 81.9038L81.0179 79.858Z"
        fill="#D96FF8"
      />
      <path
        d="M87.666 76.2589H88.9879V83.0098H87.666V76.2589ZM92.9034 76.2589V77.5504H89.75V76.2589H92.9034ZM89.7503 78.9729H91.2298V80.2643H89.7503V78.9729ZM89.7503 81.7184H92.9036V83.0098H89.7503V81.7184Z"
        fill="#D96FF8"
      />
      <path
        d="M51.6578 38.1685H44.1348V68.2925H51.6578V38.1685Z"
        fill="#1A50FF"
      />
      <path
        d="M53.3931 38.1685V45.6995H80.4659V60.7616H53.3931V68.2925H87.9889V38.1685H53.3931Z"
        fill="#1A50FF"
      />
      <path
        d="M15.3268 38.1685H7.80371V68.2925H15.3268V38.1685Z"
        fill="#D96FF8"
      />
      <path
        d="M42.4023 45.6995V38.1686L17.0592 38.1686V45.6995L42.4023 45.6995Z"
        fill="#D96FF8"
      />
      <path
        d="M87.9883 36.1956V28.6647L53.3913 28.6647V36.1956L87.9883 36.1956Z"
        fill="#FFDE54"
      />
      <path
        d="M87.9883 13.6026V6.07166L53.3913 6.07166V13.6026L87.9883 13.6026Z"
        fill="#FFDE54"
      />
      <path
        d="M87.9883 24.8991V17.3682L53.3913 17.3682V24.8991L87.9883 24.8991Z"
        fill="#FFDE54"
      />
      <path
        d="M15.3268 6.07181H7.80371V36.1958H15.3268V6.07181Z"
        fill="#FA0082"
      />
      <path
        d="M51.6578 6.07178H44.1348V36.1958H51.6578V6.07178Z"
        fill="#FF8B34"
      />
      <path
        d="M17.0591 7.32471V16.6155L42.4012 34.9431V25.6524L17.0591 7.32471Z"
        fill="#FA0082"
      />
    </StyledSVG>
  );
};

export const Logo = (props) => {
  return props.varient === "short" ? (
    <LogoShort />
  ) : (
    <LogoLarge width={props.width} height={props.height} />
  );
};

Logo.displayName = "Logo";
