import React, { useEffect, useRef } from 'react';
import { select, selectAll, create } from 'd3-selection';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import { line, curveMonotoneX } from 'd3-shape';
import { scaleLinear, scaleTime, scaleLog } from 'd3-scale';
import { extent, max } from 'd3-array';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
} from 'd3-time';
import { Flex } from './../Box';
import { Delaunay } from 'd3-delaunay';
import styled from 'styled-components';
import { roundToNearest } from '../../utils/numbers';

const d3 = {
  select,
  selectAll,
  create,
  axisBottom,
  axisRight,
  format,
  scaleLinear,
  axisLeft,
  line,
  scaleTime,
  extent,
  max,
  Delaunay,
  curveMonotoneX,
  scaleLog,
  timeFormat,
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
};

const ScatterPlotWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

const TooltipWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.gray3};
  color: inherit;
  font-size: inherit;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 12px;
  visibility: ${(props) => (props.isMobile ? 'visible' : 'hidden')};
  position: ${(props) => (props.isMobile ? 'initial' : 'absolute')};
  z-index: 999;
`;

const PointWrapper = styled(Flex)`
  .point-title-wrapper {
    margin-bottom: 0;
  }
`;

const TooltipTitle = styled.strong`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray11};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const TooltipText = styled.strong`
  color: ${(props) => props.theme.colors.gray8};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const Legend = styled.text`
  fill: ${(props) => props.theme.colors.gray7};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
`;

const Axis = styled.g`
  text {
    fill: ${(props) => props.theme.colors.gray6};
    font-family: ${(props) => props.theme.fonts.nunito};
    font-size: 10px;
  }
  line {
    stroke: ${(props) => props.theme.colors.gray2};
    stroke-width: 1px;
  }
`;

const addSuffixPrefix = (num, format) => {
  const unformattedNumber = num.toString().replace(/,/g, '');
  const equivalentPositiveNumber = num.toString().replace(/-/g, '');
  switch (format) {
    case 'Ξ':
      return parseFloat(unformattedNumber, 10) < 0
        ? `-${equivalentPositiveNumber}Ξ`
        : `${num}Ξ`;
    case '%':
      return `${num}%`;
    default:
      return num;
  }
};

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

function abbreviateNumber(number, format) {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return addSuffixPrefix(number, format);
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return addSuffixPrefix(roundToNearest(scaled, 2), format) + suffix;
}

const withZeroPadding = (value) => (value < 10 ? `0${value}` : value);

const formatDate = (day, hourly) =>
  hourly
    ? `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
        day.getDate(),
      )}/${withZeroPadding(day.getFullYear())} ${withZeroPadding(
        day.getHours(),
      )}:${withZeroPadding(day.getMinutes())}`
    : `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
        day.getDate(),
      )}/${withZeroPadding(day.getFullYear())}`;

const makeDivId = (str) =>
  str
    .replace(/[^\w\s]/gi, '')
    .split(' ')
    .join('-')
    .toLowerCase();

const createPoint = (accumulator, item) => [
  ...accumulator,
  ...item.data.map((dataItem) => ({
    id: item.id,
    xId: item.xId,
    yId: item.yId,
    divId: makeDivId(item.xId.concat(`-${dataItem.point}`)),
    image: dataItem.image,
    color: (dataItem.color || item.color),
    xFormat: item.xFormat,
    yFormat: item.yFormat,
    y: dataItem.y,
    x: dataItem.x,
    point: dataItem.point,
  })),
];

const getTooltipTitle = (point, format) => {
  switch (format) {
    case 'id':
      return point.point;
    case 'x':
    default:
      return point.point;
  }
};

const getTooltipText = (point, format) => {
  switch (format) {
    case 'idy':
      return {
        lebel: point.yId,
        value: `${point.yFormat.prefix || ''}${roundToNearest(point.y, 4)}${
          point.yFormat.suffix || ''
        }`,
      };
    case 'xy':
    default:
      return {
        lebel: point.x,
        value: `${point.yFormat.prefix || ''}${roundToNearest(point.y, 4)}${
          point.yFormat.suffix || ''
        }`,
      };
  }
};

const formatMinute = d3.timeFormat('%H:%M'),
  formatHour = d3.timeFormat('%H:%M'),
  formatDay = d3.timeFormat('%a %d'),
  formatWeek = d3.timeFormat('%b %d'),
  formatMonth = d3.timeFormat('%b'),
  formatYear = d3.timeFormat('%Y');

function multiFormat(date) {
  return (
    d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

export const ScatterPlot = ({
  chartId = 'test',
  width = 960,
  height = 360,
  maxHeight = 720,
  data = [],
  leftLegend,
  bottomLegend,
  lineStrokeWidth = 2,
  showSliceTooltip = false,
  tooltipTitleFormat = 'x',
  tooltipTextFormat = 'idy',
  onDotClick = () => {},
}) => {
  const axisArea = {
    top: 8,
    right: 8,
    bottom: 24,
    left: 36,
  };
  const chartAreaWidth = width - axisArea.left - axisArea.right;
  const chartAreaHeight = height - axisArea.top - axisArea.bottom;
  const svgNode = useRef(null);
  const svgNodeWrapper = useRef(null);

  useEffect(() => {
    renderChart();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width, height]);

  const primarySimplifiedSeries = data.reduce(createPoint, []);

  const hasSomeActivePoints = data.some((item) => !item.isHidden);

  const getTranslateValues = (transform) => {
    const translate = transform.match(/translate\((.*)\)/);
    return translate
      ? translate[1].split(',').map((item) => Number(item))
      : [0, 0];
  };

  const calculateTooltipDiamension = (event) => {
    const tooltipHoverMargin = 24;
    const point = event.target.__data__;
    const pointTransform = d3.select(`#line-${point.divId}`).node().attributes
      .transform.value;
    const { offsetX: offx } = event;
    const [, y] = getTranslateValues(pointTransform);
    const { width: tooltipWidth, height: tooltipHeight } = document
      .getElementById(`${chartId}-scatter-tooltip`)
      .getBoundingClientRect();

    const left = offx - tooltipWidth / 2;
    const top =
      chartAreaHeight > y + tooltipHoverMargin + tooltipHeight
        ? y + tooltipHoverMargin
        : y - tooltipHeight - tooltipHoverMargin;

    return { left, top };
  };

  function handleMouseOverVoronoiWrapper(event) {
    const point = event.target.__data__;
    const { left, top } = calculateTooltipDiamension(event);

    d3.select(`#${chartId}-scatter-tooltip`)
      .style('visibility', 'visible')
      .style('top', `${top}px`)
      .style('left', `${left}px`);

    d3.select(`#${chartId}-point-wrapper .tooltip-title`).text(
      getTooltipText(point, tooltipTextFormat).value,
    );

    d3.select(`#line-${point.divId}`)
      .attr('r', 4)
      .style('outline', '2px solid #FFFFFF')
      .style('outline-offset', '0')
      .style('border-radius', '50%');

    d3.select(`#point-ls-${makeDivId(point.id)}-x`).style('display', 'flex');
    d3.select(`#point-ls-${makeDivId(point.id)}-x strong.point-label`)
      .text(formatDate(new Date(point.x), true))
      .style('display', 'block');

    d3.select(`#point-ls-${makeDivId(point.id)}`).style('display', 'flex');
    d3.select(`#point-ls-${makeDivId(point.id)} strong.point-label`)
      .text(getTooltipTitle(point, tooltipTitleFormat))
      .style('display', 'block');

    d3.select(`#point-ls-${makeDivId(point.id)} img.point-image`)
      .attr('src', point.image)
      .style('display', 'block');
  }

  function handleMouseMove(event) {
    const point = event.target.__data__;
    const pointTransform = d3.select(`#line-${point.divId}`).node().attributes
      .transform.value;

    const [x, y] = getTranslateValues(pointTransform);
    const { left, top } = calculateTooltipDiamension(event);
    d3.select(`#${chartId}-scatter-tooltip`)
      .style('top', `${top}px`)
      .style('left', `${left}px`);

    d3.select(`#${chartId}-crosshair`)
      .attr('transform', `translate(${x}, 0)`)
      .attr('opacity', 1);

    d3.select(`#${chartId}-horizontal-crosshair`)
      .attr('transform', `translate(0, ${y})`)
      .attr('opacity', 1);
  }

  const handleMouseOut = (event) => {
    const point = event.target.__data__;
    if (!showSliceTooltip) {
      d3.selectAll('.point-ls').style('display', 'none');
    }
    d3.select(`#${chartId}-scatter-tooltip`).style('visibility', 'hidden');
    d3.select(`#${chartId}-crosshair`).attr('opacity', 0);
    d3.select(`#${chartId}-horizontal-crosshair`).attr('opacity', 0);

    d3.select(`#line-${point.divId}`)
      .attr('r', 3)
      .style('outline', '0')
      .style('outline-offset', '0')
      .style('border-radius', '50%');
  };

  const renderChart = () => {
    const svgObj = d3
      .select(svgNode.current)
      .select(`#${chartId}-chart-wrapper`);
    const x = d3
      .scaleTime()
      .domain(d3.extent(primarySimplifiedSeries, (d) => new Date(d.x)))
      .range([0, chartAreaWidth])
      .nice();

    const maxY =
      Number(d3.max(primarySimplifiedSeries, (d) => d.y)) +
      d3.max(primarySimplifiedSeries, (d) => d.y) * 0.2;
    const y0 = d3.scaleLinear().domain([0, maxY]).range([chartAreaHeight, 0]);

    svgObj
      .select('#bottom-axis')
      .attr('stroke-width', 0)
      .attr('transform', `translate(0, ${chartAreaHeight})`)
      .call(
        d3
          .axisBottom(x)
          .tickPadding(12)
          .ticks(8)
          .tickSize(0)
          .tickFormat((d) => multiFormat(d)),
      );

    svgObj
      .select('#left-axis')
      .attr('stroke-width', 0)
      .call(
        d3
          .axisLeft(y0)
          .tickSize(-chartAreaWidth)
          .tickPadding(24)
          .tickFormat((d) => {
            const [sampleData] = data;
            const {
              yFormat: { prefix, suffix },
            } = sampleData;
            return abbreviateNumber(d, prefix || suffix);
          })
          .ticks(5),
      );

    svgObj
      .selectAll('.dot')
      .data(primarySimplifiedSeries)
      .attr('stroke-width', '2')
      .attr('stroke', (d) => d.color)
      // .attr('fill', (d) => d.color)
      .attr('transform', (d) => `translate(${x(new Date(d.x))}, ${y0(d.y)})`)
      .attr('r', 3);

    const voronoi = d3.Delaunay.from(
      primarySimplifiedSeries,
      (d) => x(new Date(d.x)),
      (d) => y0(d.y),
    ).voronoi([0, 0, chartAreaWidth, chartAreaHeight]);

    svgObj
      .select(`#${chartId}-voronoi-wrapper`)
      .selectAll('path')
      .data(primarySimplifiedSeries)
      .join('path')
      .attr('opacity', 0.5)
      // .attr('stroke', 'pink')
      .attr('fill', 'none')
      .style('pointer-events', 'all')
      .attr('d', (d, i) => voronoi.renderCell(i))
      .on('mouseover', handleMouseOverVoronoiWrapper)
      .on('mousemove', handleMouseMove)
      .on('mouseout', handleMouseOut)
      .on('click', function (event) {
        const point = event.target.__data__;
        onDotClick(point);
      });
  };

  return (
    <ScatterPlotWrapper ref={svgNodeWrapper} maxHeight={maxHeight}>
      <StyledSvg ref={svgNode} viewBox={`0 0 ${width} ${height}`}>
        <g
          id={`${chartId}-chart-wrapper`}
          transform={`translate(${axisArea.left},${axisArea.top})`}
          className="chart-wrapper"
        >
          <Axis id="bottom-axis" />
          <Axis id="left-axis" />
          <Axis id="right-axis" />
          {hasSomeActivePoints && (
            <line
              id={`${chartId}-crosshair`}
              className="crosshair"
              opacity="0"
              stroke="#FC4DA8"
              strokeWidth={'1'}
              y1="0"
              y2={chartAreaHeight}
            />
          )}
          {hasSomeActivePoints && (
            <line
              id={`${chartId}-horizontal-crosshair`}
              className="crosshair"
              opacity="0"
              stroke="#FC4DA8"
              strokeWidth={'1'}
              x1={chartAreaWidth}
              x2="0"
            />
          )}
          {primarySimplifiedSeries.map((item) => (
            <circle
              key={`${item.point}-${item.divId}-${new Date(item.x).getTime()}`}
              id={`line-${item.divId}`}
              className={`dot`}
              fill="rgb(15, 13, 21)"
              stroke={item.color}
              strokeWidth={lineStrokeWidth}
              style={{ opacity: item.isHidden ? 0 : 1 }}
            />
          ))}
          {bottomLegend && (
            <Legend
              textAnchor="middle"
              fontSize="14"
              x={chartAreaWidth / 2}
              y={chartAreaHeight + 52}
            >
              {bottomLegend}
            </Legend>
          )}
          {leftLegend && (
            <Legend
              textAnchor="end"
              fontSize="14"
              x={-chartAreaHeight / 2 + 52}
              y={-axisArea.left + 16}
              transform="rotate(-90)"
            >
              {leftLegend}
            </Legend>
          )}
          <g id={`${chartId}-voronoi-wrapper`}>
            {primarySimplifiedSeries.map((item) => (
              <path
                key={`${makeDivId(item.divId)}-${item.point}-${new Date(
                  item.x,
                ).getTime()}`}
              />
            ))}
          </g>
        </g>
      </StyledSvg>
      {hasSomeActivePoints && (
        <TooltipWrapper
          id={`${chartId}-scatter-tooltip`}
          flexDirection="column"
        >
          <PointWrapper
            id={`${chartId}-point-wrapper`}
            flexDirection="column"
            alignItems="center"
          >
            <TooltipTitle
              className="point-title-wrapper"
              id={`${chartId}-point-title-wrapper`}
            >
              <TooltipText className="tooltip-title-lebel" />
              <TooltipTitle id="top" className="tooltip-title" />
            </TooltipTitle>
            {data.map((item) => (
              <>
                <Flex
                  key={`tooltip-item-${makeDivId(item.id)}-x`}
                  id={`point-ls-${makeDivId(item.id)}-x`}
                  className="point-ls"
                >
                  <TooltipText className="point-label" />
                </Flex>
                <Flex
                  key={`tooltip-item-${makeDivId(item.id)}`}
                  id={`point-ls-${makeDivId(item.id)}`}
                  className="point-ls"
                  mb="1"
                >
                  <TooltipText className="point-label" />
                </Flex>
                <Flex
                  key={`tooltip-item-${makeDivId(item.id)}`}
                  id={`point-ls-${makeDivId(item.id)}`}
                  className="point-image"
                  mb="1"
                >
                  <img
                    className="point-image h-24 w-24 rounded-lg"
                    src="https://via.placeholder.com/100"
                    alt={item.id}
                    color="white"
                  />
                </Flex>
              </>
            ))}
          </PointWrapper>
        </TooltipWrapper>
      )}
    </ScatterPlotWrapper>
  );
};

export default ScatterPlot;
