import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const ChevronDown = withTheme(({ size = 15, color, theme }) => {
  return (
    <StyledSVG
    className='icon'
    width={`${size}px`}
    height={`${size}px`}
    viewBox='0 0 15 15'
    fill='none'
    stroke={theme.colors[color]}
    strokeWidth='1.5'
    strokeLinecap='square'
  >
    <path d="M4 6L7 9L10 6" />
  </StyledSVG>
  );
});

ChevronDown.displayName = 'ChevronDown';
