import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const SortAscending = withTheme(({ size = 16, color, theme }) => {
  return (
    <StyledSVG size={size} viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill={theme.colors.iconSecondary}
        d='m 13.432177,10.265218 v 7.009766 h -3 l 4,3.990234 4,-3.990234 h -3 v -7.009766 z'
      />
      <path
        fill={theme.colors[color]}
        d='m 8.4321765,3.2647498 -4,3.9902342 h 3 v 7.009765 h 2 V 7.254984 h 3.0000005 z'
      />
    </StyledSVG>
  );
});

SortAscending.displayName = 'SortAscending';
