import { roundToMaxChars } from "../../utils/numbers";
import { Etherium, Calculator } from "../Icons";
import { PercentChangeText } from "../Misc";
import KPILoader from "../Shimmer/KPILoader";

const addSuffixPrefix = (num = 0, format) => {
  const unformattedNumber = num.toString().replace(/,/g, "");
  const equivalentPositiveNumber = num.toString().replace(/-/g, "");

  switch (format) {
    case "$":
      return parseFloat(unformattedNumber, 10) < 0
        ? `-$${equivalentPositiveNumber}`
        : `$${num}`;
    case "%":
      return `${num}%`;
    default:
      return num;
  }
};

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
// JM added the "tier === -1" condition to this because it was failing on .001 (result was 1)
function abbreviateNumber(number = 0, places = 2, format) {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0 || tier === -1)
    return addSuffixPrefix(Number(roundToMaxChars(number, 3), format));
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  return addSuffixPrefix(
    Number(roundToMaxChars(scaled, 3), format) + (suffix || "")
  );
}
// todo places param no longer used?
export const KPI = (props) => {
  return props.loading ? (
    <KPILoader>
      <div className="relative flex flex-row items-center py-6 pl-6 pr-6 rounded-2xl gap-4 overflow-hidden">
        <div className="bg-gray-900 rounded-full p-3 h-12 w-12">
          {props.type === "count" ? (
            <Calculator className="h-6 w-6 text-white" aria-hidden="true" />
          ) : (
            <Etherium className="h-6 w-6 text-white" aria-hidden="true" />
          )}
        </div>
        <div className="flex flex-col justify-between w-2/3" />
      </div>
    </KPILoader>
  ) : (
    <div className="relative flex flex-row items-center bg-gray-1200 py-6 pl-6 pr-6 rounded-2xl gap-4 overflow-hidden">
      <div className="bg-gray-900 rounded-full p-3 h-12 w-12">
        {props.type === "count" ? (
          <Calculator className="h-6 w-6 text-white" aria-hidden="true" />
        ) : (
          <Etherium className="h-6 w-6 text-white" aria-hidden="true" />
        )}
      </div>
      <div className="flex flex-col justify-between w-2/3">
        <div className="flex items-center justify-between">
          <p className="text-4xl font-extrabold text-white leading-117">
            {abbreviateNumber(props.value, props.places)}
          </p>
          <PercentChangeText
            changedValue={abbreviateNumber(props.changedValue, 2)}
            showCaption={true}
          />
        </div>
        <p className="text-sm font-bold text-gray-400 leading-130">
          {props.label}
        </p>
      </div>
    </div>
  );
};
