import React from "react";
import { useState } from "react";
import styled, { css } from "styled-components";

import { Flex } from "../Box";

const StyledTabs = styled(Flex)`
  width: 100%;
  ${(props) =>
    props.display === "column"
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}
`;

const StyledOl = styled.ol`
  padding-left: ${(props) => props.theme.space[0]}px;
  margin-bottom: 0px;
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-direction: row;
`;

const StyledLi = styled.li`
  display: inline-block;
  list-style: none;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  padding: ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[5]}px;
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-family: ${(props) => props.theme.fonts.nunito};
  line-height: 1.4;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.gray9};
  border-radius: 5px;
  ${(props) =>
    props.display !== "column"
      ? css`
          border-bottom: ${props.theme.space[1]}px solid transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          border-bottom: 0;
        `}
  &:hover {
    color: ${(props) => props.theme.colors.blue5};
  }
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.blue5};
      border-color: ${(props) => props.theme.colors.blue5};
    `}
`;

const StyledContent = styled.div`
  ${(props) =>
    props.display !== "column"
      ? css`
          width: 100%;
        `
      : css`
          width: 70%;
        `}
`;

export const SimpleTab = ({
  id,
  active,
  label,
  icon,
  type,
  display,
  hasIconOnly,
  onClick,
}) => {
  return (
    <StyledLi
      active={active}
      display={display}
      onClick={() => {
        onClick({ id, label, type });
      }}
    >
      {hasIconOnly ? icon : label}
    </StyledLi>
  );
};

export const SimpleTabs = ({
  children: childrenFromProps,
  display,
  onClickTab,
  secondaryHeader,
  justifyHeaderItems = "space-between",
  ...props
}) => {
  const children = childrenFromProps.filter((item) => !!item && item.props);
  const [activeTab, setActiveTab] = useState(children[0].props.id);
  const onClickTabItem = (tab) => {
    //console.log(tab);
    setActiveTab(tab.id);
    onClickTab(tab);
  };
  return (
    <StyledTabs display={display}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent={justifyHeaderItems}
      >
        <StyledOl display={display} style={props.style}>
          {children.length > 0 &&
            children.map(
              ({ props: { id, label, type, icon, hasIconOnly } }) => (
                <SimpleTab
                  id={id}
                  active={id === activeTab}
                  key={label}
                  label={label}
                  icon={icon}
                  type={type}
                  display={display}
                  hasIconOnly={hasIconOnly}
                  onClick={onClickTabItem}
                />
              )
            )}
        </StyledOl>
        {secondaryHeader}
      </Flex>
      <StyledContent>
        {children.map(({ props: { id, children } }) =>
          id === activeTab ? children : null
        )}
      </StyledContent>
    </StyledTabs>
  );
};
