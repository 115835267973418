import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const Calculator = withTheme(({ ...props }) => {
  return (
    <StyledSVG
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.06689 1.8C1.06689 0.805888 1.78324 0 2.66689 0H13.3336C14.2172 0 14.9336 0.805888 14.9336 1.8V16.2C14.9336 17.1941 14.2172 18 13.3336 18H2.66689C1.78324 18 1.06689 17.1941 1.06689 16.2V1.8ZM4.26689 6H11.7336V4.8H4.26689V6ZM4.26689 10.8H5.33356V9.6H4.26689V10.8ZM8.53356 10.8H7.4669V9.6H8.53356V10.8ZM10.6669 10.8H11.7336V9.6H10.6669V10.8ZM5.33356 14.4H4.26689V13.2H5.33356V14.4ZM7.4669 14.4H8.53356V13.2H7.4669V14.4ZM11.7336 14.4H10.6669V13.2H11.7336V14.4Z'
        fill='#E18CF9'
      />
    </StyledSVG>
  );
});

Calculator.displayName = 'Calculator';
