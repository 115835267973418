import { Linechart, ScatterPlot } from '../Charts';
import { useState } from 'react';
// import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  getTraitsFloorPrice,
  getTraitsTransactions,
} from '../../graphql/stats';
import { useParams } from 'react-router-dom';
import { possibleChartColors } from '../../utils/theme';
import { formatETH } from '../../utils/eth';
import ChartLoader from '../Shimmer/ChartLoader';
import { Flex } from '../Box';
// import { generateTraitsFloorPriceData } from '../../utils/mockData';
import styled from 'styled-components';

//['1H', '1D', '1W', '1M', '1Y', 'ALL'];
const filterList = ['1H', '1D', '1W', '1M', '1Y'];

export const strToHour = {
  '1H': 1,
  '1D': 24,
  '1W': 168,
  '1M': 744,
  '1Y': 8760,
  ALL: 87600,
};

export const strToMinute = {
  '1H': 60,
  '1D': 1440,
  '1W': 10080,
  '1M': 43200,
  '1Y': 525600,
  ALL: 5256000,
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Pointer = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  display: block;
`;

function titleCase(str) {
  //console.log(str);
  const result = str
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return result;
}

// const getFirstUppercase = (str) =>
//   str.replace(/./, (firstChar) => firstChar.toUpperCase());

export default function TraitsCharts({ selectedTraits = [], selectedOps }) {
  const params = useParams();
  const [selectedFloorPricesTime, setSelectedFloorPricesTime] = useState(
    filterList[2],
  );
  
  const [selectedTransactionsTime, setSelectedTransactionsTime] = useState(
    filterList[1],
  );

  // Maps selected traits (Name+Value pairs) to chart colors, which are shared between the line and scatter charts
  // Note: key is a tring combining name + value (e.g. "EYESNight Out"). This could break if very long trait names are passed in.
  // Todo could limit to first 15 chars 
  const legendColors = selectedTraits.reduce( (acc, trait) => {
    trait.values.forEach( (traitValue, index) => {
      acc[trait.id + traitValue] = possibleChartColors[index % (possibleChartColors.length - 1)];
    });
    
    return acc;
  }, {} );

  // console.log(legendColors);

  // Fetch Floor price data
  // console.log('Fetch Floor price data!');
  // console.log(selectedTraits);
  const {
    data: { getTraitsFloorPrice: floorPriceData } = {},
    loading: floorPriceLoading,
    refetch: refetchTraitsFloorPrice,
  } = useQuery(getTraitsFloorPrice, {
    skip: selectedTraits.length === 0,
    variables: {
      collectionSlug: params.collection,
      durationInHours: 168,
      traits: selectedTraits.reduce(
        (acc, item) =>
          acc.concat(item.values.map((iv) => ({ name:item.id, value: iv}))),
        [],
      ),
    },
    notifyOnNetworkStatusChange: true,
  });

  // Fetch Transactions data
  const {
    data: { getTransactions: transactionData = [] } = {},
    loading: transactionLoading,
    refetch: refetchTransactions,
  } = useQuery(getTraitsTransactions, {
    skip: selectedTraits.length === 0,
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });

  

  function getMatchedTrait(trait) {
    const categoryMatch = selectedTraits.find( (selectedTrait) => selectedTrait.id?.toLowerCase()?.replace(' ', '') === trait.name?.toLowerCase()?.replace(' ', '') );
    let valueMatch;

    if(categoryMatch) {
      valueMatch = categoryMatch.values?.find( (selectedTraitValue) => selectedTraitValue.toLowerCase()?.replace(' ', '') === trait.value?.replace(' ', '').toLowerCase());
    } else{
      return undefined;
    }
    
    if (categoryMatch && valueMatch) {
      return {name: categoryMatch.id , value: valueMatch};
    } else {
      return undefined;
    };
    
  };

  const reduceTransactionData = (transactions = []) => {
    let data = [];

    if(selectedTraits.length > 0) {
      transactions.forEach((transaction) => {
         transaction?.token?.traits?.forEach((transactionTrait) => {
          const matchedTrait = getMatchedTrait(transactionTrait);
          // console.log(matchedTrait);
          if(matchedTrait){
            const filteredTransaction = {
              point: `#${transaction.token?.tokenId}`,
              x: transaction.saleDate,
              y: formatETH(transaction.salePrice || 0),
              image: transaction?.token?.image,
              name: matchedTrait.name,
              value: matchedTrait.value,
              color: legendColors[matchedTrait.name + matchedTrait.value],
            };
          
            data.push(filteredTransaction);
          }
        })
      }
    )
  }

    return {
      id: 'Transactions',
      xId: 'Time',
      yId: 'Transactions',
      data,
      color: '#3689FC',
      xFormat: {
        prefix: '',
      },
      yFormat: {
        suffix: 'Ξ',
      },
    };
  };



  const reduceFloorStatsData = (floorStatsData = []) => {
    //console.log(floorStatsData);

    if (!floorStatsData || !floorStatsData.length) return [];

    const reducedFloorStatsData =  floorStatsData
      .filter((item) => item.data.length)
      .reduce((acc, item, index) => {
        const data = item.data.map((item) => ({
          x: item.date,
          y: formatETH(item.floorPrice),
        }));
        return acc.concat({
          id: `${item.name} - ${titleCase(item.value)}`,
          data,
          color: legendColors[item.name + item.value],
          name: `${titleCase(item.name)}: ${titleCase(item.value)}`,
          suffix: 'Ξ',
        });
      }, []);

     // console.log(reducedFloorStatsData);
      return reducedFloorStatsData;
  };

  const handleChangeFloorPrices = (timeFrame) => {
    setSelectedFloorPricesTime(timeFrame);
    refetchTraitsFloorPrice({
      collectionSlug: params.collection,
      durationInHours: strToHour[timeFrame],
      traits: selectedTraits.reduce(
        (acc, item) =>
          acc.concat(item.values.map((iv) => ({ name:item.id, value: iv}))),
        [],
      ),
    });

    // setTraitsData(
    //   generateTraitsFloorPriceData(strToHour[timeFrame], selectedTraits),
    // );
  };

  const handleChangeTransactions = (timeFrame) => {
    setSelectedTransactionsTime(timeFrame);
    refetchTransactions({ durationInMinutes: strToMinute[timeFrame] });
  };

  // useEffect(() => {
  //   setTraitsData(
  //     generateTraitsFloorPriceData(
  //       strToHour[selectedFloorPricesTime],
  //       selectedTraits,
  //     ),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedTraits]);

  const reducedTransactionsData = reduceTransactionData(transactionData);
  //console.log(reducedTransactionsData)

  return (
    <div className="flex flex-col mt-5">
      <h1 className="text-white text-2xl font-extrabold">Charts</h1>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col justify-center bg-gray-1200 p-3 rounded-2xl relative flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">Floor Price</h2>
            <div className="flex flex-col items-end">
              <div className="flex flex-row">
                {reduceFloorStatsData(floorPriceData).map((item, index) => (
                  <div
                    className={`flex flex-row items-center ${
                      index === 4 ? '' : 'mr-6'
                    }`}
                  >
                    <Pointer color={item.color} />
                    <h3 className="text-gray-200 text-sm font-semibold">
                      {item.name}
                    </h3>
                  </div>
                ))}
              </div>
              <div className="flex flex-row">
                {filterList.map((item) => (
                  <button
                    key={item}
                    className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                    onClick={() => {
                      handleChangeFloorPrices(item);
                    }}
                  >
                    <span
                      className={classNames(
                        'text-sm',
                        selectedFloorPricesTime === item
                          ? 'text-gray-300 font-black'
                          : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                      )}
                    >
                      {item}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/* <Linechart
            chartId="traits-floor-price-chart"
            data={traitsData}
            height={300}
            width={1160}
            canHighlightLine
            hourly
          /> */}
          {floorPriceLoading ? (
            <ChartLoader />
          ) : reduceFloorStatsData(floorPriceData).length > 0 ? (
            <Linechart
              data={reduceFloorStatsData(floorPriceData)}
              height={300}
              width={1160}
              canHighlightLine
              hourly
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                Select traits to populate this chart.
              </h1>
            </Flex>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col justify-center bg-gray-1200 p-3 rounded-2xl relative flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">
              Transactions (
              {selectedTraits.length > 0
                ? reducedTransactionsData.data.length
                : 0}
              )
            </h2>
            <div className="flex flex-row">
              {filterList.map((item) => (
                <button
                  key={item}
                  className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                  onClick={() => {
                    handleChangeTransactions(item);
                  }}
                >
                  <span
                    className={classNames(
                      'text-sm',
                      selectedTransactionsTime === item
                        ? 'text-gray-300 font-black'
                        : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                    )}
                  >
                    {item}
                  </span>
                </button>
              ))}
            </div>
          </div>
          {transactionLoading ? (
            <ChartLoader />
          ) : reducedTransactionsData?.data?.length > 0 ? (
            <ScatterPlot
              chartId="traits-scatter-plot"
              data={[reducedTransactionsData]}
              height={300}
              showSliceTooltip
            />
          ) : (
            
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                {selectedTraits.length > 0 ? "No transactions were made with these traits during the selected time period." : "Select traits to populate this chart." }
              </h1>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
}
