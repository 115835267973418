import { ImageGallery } from '../components/ImageGallery';
import Banner from '../components/Banner/Banner';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';

function Gallery() {
  return (
    <Sidebar>
      <Banner />
      <ImageGallery />
      <Footer />
    </Sidebar>
  );
}

export default Gallery;
