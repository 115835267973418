import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const ChevronUp = withTheme(({ size = 15, color, theme }) => {
  return (
    <StyledSVG
      className='icon'
      width={`${size}px`}
      height={`${size}px`}
      viewBox='0 0 15 15'
      fill='none'
      stroke={theme.colors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d="M10 9L7 6L4 9" />
    </StyledSVG>
  );
});

ChevronUp.displayName = 'ChevronUp';
