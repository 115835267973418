import { UpFilled, DownFilled } from "../Icons";
import { abbreviateNumber } from "../../utils/numbers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getPercentChangeColor(val) {
  let textColor = "text-gray-500";
  if (val > 0) {
    textColor = "text-green-1200";
  } else if (val < 0) {
    textColor = "text-red-800";
  }
  return textColor;
}

function getChangeIcon(val) {
  if (val > 0) {
    return (
      <UpFilled
        className="self-center flex-shrink-0 text-green-1200"
        aria-hidden="true"
        fill="#18BA92"
      />
    );
  } else if (val < 0) {
    return (
      <DownFilled
        className="self-center flex-shrink-0 text-red-800"
        aria-hidden="true"
        fill="#F65354"
      />
    );
  } else {
    return (
      <DownFilled
        className="self-center flex-shrink-0 text-red-800"
        aria-hidden="true"
        fill="#a5a3a9"
      />
    );
  }
}

export const PercentChangeText = (props) => {
  //console.log(props);
  const val = props.changedValue;
  const showCaption = props.showCaption;
  const roundPlaces = props.roundPlaces || 1;
  return (
    <div className="flex flex-col items-end">
      <p
        className={classNames(
          getPercentChangeColor(val),
          "flex items-baseline text-xs font-semibold",
          showCaption ? "ml-2" : "-ml-1"
        )}
      >
        {getChangeIcon(val)}
        <span className="sr-only">
          {val > 0 ? "Increased" : "Decreased"} by
        </span>
        {val === null
          ? "--"
          : abbreviateNumber(Math.abs(val) || 0, roundPlaces)}
        %
      </p>
      {showCaption && (
        <p className="text-xs text-gray-500 font-semibold leading-130">24H</p>
      )}
    </div>
  );
};
