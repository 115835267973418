import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const SendLeft = withTheme(({ size = 16, color, theme }) => {
  return (
    <StyledSVG
      size={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1036 0.396454L14.1036 0.396454L14.1036 13.3965H13.1036L13.1036 0.396454ZM2.8107 7.39645L5.95714 10.5429L5.25004 11.25L0.896484 6.89645L5.25004 2.5429L5.95714 3.25001L2.8107 6.39645L12.1036 6.39645V7.39645L2.8107 7.39645Z'
        fill={theme.colors[color]}
      />
    </StyledSVG>
  );
});

SendLeft.displayName = 'SendLeft';
