import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const SendRight = withTheme(({ size = 16, color, theme }) => {
  return (
    <StyledSVG
      size={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.396485 13.8965L0.396484 0.896484L1.39648 0.896484L1.39648 13.8965H0.396485ZM9.25004 3.04293L13.6036 7.39648L9.25004 11.75L8.54293 11.0429L11.6894 7.89648L2.39648 7.89648V6.89648L11.6894 6.89648L8.54293 3.75004L9.25004 3.04293Z'
        fill={theme.colors[color]}
      />
    </StyledSVG>
  );
});

SendRight.displayName = 'SendRight';
