import { ethers } from "ethers";

export const convertEValue = (num) => {
  if (Math.abs(num) < 1.0) {
    const exponent = parseInt(num.toString().split("e-")[1]);
    if (exponent) {
      num *= Math.pow(10, exponent - 1);
      num = "0." + new Array(exponent).join("0") + num.toString().substring(2);
    }
  } else {
    let exponent = parseInt(num.toString().split("+")[1]);
    if (exponent > 20) {
      exponent -= 20;
      num /= Math.pow(10, exponent);
      num += new Array(exponent + 1).join("0");
    }
  }
  return num;
};

export function formatETH(num) {
  const currentNum = Math.round(Number(num || 0)).toString();
  const doesIncluedsEValue = currentNum.includes("e");
  const resNum = doesIncluedsEValue ? convertEValue(currentNum) : currentNum;
  const result = Number(
    ethers.utils.formatEther(ethers.BigNumber.from(resNum))
  );
  return result;
}

// todo reconcile with above
// todo BigNumber throws an error if decimal passed,
// which happens in rare cases.
// there was an instance where the rounding approach in the above function choked
// so below I used string manipulation. Don't feel great about this.
export function formatETHNum(num = "0") {
  //console.log("formatETHNum", num);
  const numWithoutDecimals = Number(num).toString().split(".")[0];
  //console.log("numWithoutDecimals", numWithoutDecimals);
  const doesIncluedsEValue = numWithoutDecimals.includes("e");
  const resNum = doesIncluedsEValue
    ? convertEValue(numWithoutDecimals)
    : numWithoutDecimals;

  return Number(ethers.utils.formatEther(ethers.BigNumber.from(resNum)));
}

export function isValidWalletAddress(address) {
  return ethers.utils.isAddress(address);
}
