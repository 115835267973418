import { useEffect, useState, useContext } from "react";
import { useSelector } from "@xstate/react";
import { AuthStateContext } from "../../lib/auth.machine";
// import isEqual from 'lodash/isEqual';

import {
  Logo,
  // User,
  // Collection,
  Etherium2, //todo reconcile Etherium & Etherium2
  // Rollar,
  // BarChartIcon,
  // SendLeft,
  // SendRight,
  //Help,
  IconWrapper,
} from "../Icons";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import * as Tooltip from "@radix-ui/react-tooltip";
// import CheckboxTree from "./CheckboxTree";
// import InputSlider from "./InputSlider";
// import Search from "./Search";
// import { useLazyQuery, useQuery } from "@apollo/client";
import { useQuery } from "@apollo/client";
// import { getCollection, getTokens } from "../../graphql/stats";
import { getTokens } from "../../graphql/stats";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import { FAQ } from "../FAQ";
import { UserMenuPopover } from "./UserMenuPopover";

// const CustomIcon = ({ icon, ...props }) => (
//   <img
//     {...props}
//     className="inline-block w-4 h-4 rounded-full"
//     src={
//       props.src ||
//       "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//     }
//     alt=""
//   />
// );

// const initialNavigation = [{ name: "My Collection", href: "/", icon: User }];

// const initialNavigation = [
//   {name: "My Collection", href: "/", icon: User},
//   {
//     name: "All Collections",
//     href: "/all-collection",
//     icon: Collection,
//   },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// const defaultRange = [3000, 8000];

export const useEthPrice = () => {
  const [ethPrice, setEthPrice] = useState();
  const getEthPrice = async () => {
    const response = await fetch(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
    );
    const data = await response.json();
    setEthPrice(data.USD);
  };
  useEffect(() => {
    getEthPrice();
  }, []);
  return ethPrice;
};

// const shouldShowFilters = (url) => {
//   const result = url.includes("traits") || url.includes("gallery");
//   return result;
// };

// const shouldShowTraits = (url) => {
//   const result = url.includes("traits") || url.includes("gallery");
//   return result;
// };

// const shouldShowRange = (url) => {
//   const result = url.includes("gallery");
//   return result;
// };

// const shouldShowCollectionMenu = (url) => {
//   const result =
//     url.includes("overview") ||
//     url.includes("traits") ||
//     url.includes("gallery");
//   return result;
// };

// const getFirstUppercase = (str) =>
//   str.replace(/./, (firstChar) => firstChar.toUpperCase());

// const splitCamelCaseToString = (s) => {
//   return s
//     .split(/(?=[A-Z])/)
//     .map((p) => {
//       return p[0].toUpperCase() + p.slice(1);
//     })
//     .join(" ");
// };

// const MyDialog = forwardRef((props, ref) => {
//   let [isOpen, setIsOpen] = useState(false);

//   // Expose a way to open the dialog from outside the component
//   useImperativeHandle(ref, () => ({
//     showDialog() {
//       setIsOpen(true);
//     },
//   }));

//   return (
//     <Transition show={isOpen} as={Fragment}>
//       <Dialog
//         open={isOpen}
//         onClose={() => setIsOpen(false)}
//         className="relative z-50"
//       >
//         {/* The backdrop, rendered as a fixed sibling to the panel container */}
//         <div className="fixed inset-0 bg-black/80" aria-hidden="true" />

//         {/* Full-screen container to center the panel */}
//         <div className="fixed inset-0 flex items-center justify-center">
//           {/* The actual dialog panel  */}
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0 scale-95"
//             enterTo="opacity-100 scale-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100 scale-100"
//             leaveTo="opacity-0 scale-95"
//           >
//             <Dialog.Panel className="mx-auto w-[600px] rounded-3xl">
//               <FAQ />
//             </Dialog.Panel>
//           </Transition.Child>
//         </div>
//       </Dialog>
//     </Transition>
//   );
// });

const stateSelector = (state) => ({
  user: state.context.user,
});

export function Sidebar({
  children,
  //onTratsSelect,
  //onOpsSelect,
  pfpToken,
  onAddWalletSave,
  userWallets,
}) {
  //const dialogRef = useRef();
  const gaEventTracker = useAnalyticsEventTracker("SideAndTopBar");
  const authService = useContext(AuthStateContext);
  const { user } = useSelector(authService, stateSelector);
  //const newUserTest = newUser;
  //newUserTest.username = "testetst";
  // console.log("newUser", newUserTest);

  //console.log("Sidebar: pfpToken :", pfpToken);

  // useEffect(() => {
  //   console.log("Sidebar Render");
  // });
  // useEffect(() => {
  //   console.log("Sidebar: The pfpToken has changed: ", pfpToken);
  // }, [pfpToken]);

  // const [sidebarOpen, setSidebarOpen] = useState(true); //aabbdd
  // const [dataNodes, updateDataNodes] = useState([]);
  // const [priceRange, setPriceRange] = useState(defaultRange);
  // const [idRange, setIdRange] = useState(defaultRange);
  const ethPrice = useEthPrice();
  // const location = useLocation();
  // const navigate = useNavigate();

  // const showCollectionMenu = shouldShowCollectionMenu(location?.pathname);
  // const showFilters = shouldShowFilters(location?.pathname);
  // const canShowTraits = shouldShowTraits(location?.pathname);
  // const canShowRange = shouldShowRange(location?.pathname);

  const walletAddress = user?.address;
  let isActive = !!walletAddress;

  const [isFAQOpen, setIsFAQOpen] = useState(false);

  const { data: { getTokens: getTokensData } = {} } = useQuery(getTokens, {
    notifyOnNetworkStatusChange: true,
  });

  const tokensData = getTokensData?.tokens || [];

  // Find a token in our wallet that matches the user pref passed from My Collectables
  let ourTokenData = tokensData.find((token) => {
    return (
      (token?.contractAddress || "").toLowerCase() ===
        (pfpToken?.contract || "").toLowerCase() &&
      token?.tokenId === pfpToken?.tokenID
    );
  });

  // if not, fall back to any nerd in their wallet
  if (!ourTokenData) {
    //console.log("Sidebar: couldnt find pfpToken, picking random nerd");
    ourTokenData = tokensData.find(
      (token) => token?.collection?.slug === "nerdcollective-genesis-pfp"
    );
  }

  // Fetch Collection data for Traits filter menu
  // const [getCollectionData, { data: { getCollection: collectionData } = {} }] =
  //   useLazyQuery(getCollection, {
  //     // useParams will not work here, because this component isn't wrapped in a Route.
  //     variables: { collectionSlug: window.location.pathname.split("/")[2] },
  //     notifyOnNetworkStatusChange: true,
  //   });

  // Waits for showCollectionMenu to be true
  // useEffect(() => {
  //   if (showCollectionMenu) {
  //     getCollectionData({
  //       variables: { collectionSlug: window.location.pathname.split("/")[2] },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.location.pathname.split("/")[2]]);

  // For collectionData?.traitTypes
  // useEffect(() => {
  //   const reduceTraitsTypes = (traits) => {
  //     //console.log(traits);

  //     const stuff = traits.reduce((acc, item) => {
  //       // console.log("traits item:");
  //       // console.log(item);
  //       // if the prev item and the current item's names (categories) match
  //       const itemIndexInAcc = acc.findIndex(
  //         (ai) => ai.name.toLowerCase() === item.name.toLowerCase()
  //       );
  //       if (itemIndexInAcc !== -1) {
  //         acc[itemIndexInAcc] = {
  //           ...acc[itemIndexInAcc],
  //           children: [
  //             ...acc[itemIndexInAcc].children,
  //             {
  //               ...item,
  //               id: item.value,
  //               name: splitCamelCaseToString(item.value),
  //               label: item.value, //This is where the Trait name displayed in the UI is set
  //             },
  //           ],
  //         };
  //         return acc;
  //       } else {
  //         return acc.concat({
  //           ...item,
  //           id: item.name,
  //           name: getFirstUppercase(item.name),
  //           label: item.name, //This is where the Category name displayed in the UI is set
  //           children: [
  //             {
  //               ...item,
  //               id: item.value,
  //               name: splitCamelCaseToString(item.value),
  //               label: item.value,
  //             },
  //           ],
  //         });
  //       }
  //     }, []);
  //     // an array of objects, each representing a trait category
  //     return stuff;
  //   };

  //   // If collection data exists, process for UI
  //   // if (collectionData?.traitTypes && collectionData?.traitTypes.length) {
  //   //   const reducedTraits = reduceTraitsTypes(collectionData.traitTypes);

  //   //   // useState
  //   //   updateDataNodes(reducedTraits);
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [collectionData?.traitTypes]);

  // End useEffect for collectionData?.traitTypes

  // const selectedItem = [
  //   {
  //     name: collectionData?.name,
  //     href: collectionData?.slug || "/",
  //     icon: CustomIcon,
  //     isExpanded: true,
  //     isCollection: true,
  //     children: [
  //       {
  //         name: "Overview",
  //         href: `/overview/${collectionData?.slug}`,
  //         icon: BarChartIcon,
  //       },
  //       {
  //         name: "Traits",
  //         href: `/traits/${collectionData?.slug}`,
  //         icon: Rollar,
  //       },
  //       // {
  //       //   name: 'Gallery',
  //       //   href: `/gallery/${collectionData?.slug}`,
  //       //   icon: GridIcon,
  //       // },
  //     ],
  //   },
  // ];

  // const inWalletLinks = [...tokensData]
  //   .reduce((acc, token) => {
  //     const idExists = acc.find(
  //       (item) => item.collection?.id === token.collection?.id
  //     );
  //     if (!idExists) {
  //       return [...acc, token];
  //     }
  //     return acc;
  //   }, [])
  //   .sort((prev, next) => {
  //     const prevTime = new Date(prev.purchasedAt).getTime();
  //     const nextTime = new Date(next.purchasedAt).getTime();
  //     return nextTime - prevTime;
  //   })
  //   .map((token) => ({
  //     name: token.collection?.name,
  //     href: `/overview/${token.collection?.slug}`,
  //     icon: token.image,
  //     id: token.collection?.id,
  //   }))
  //   .slice(0, 4);

  // const previousItems = localStorage.getItem("recentlyViewed");
  // const previousItemsArray = JSON.parse(previousItems) || [];

  // const navigation = showCollectionMenu
  //   ? [...initialNavigation, ...selectedItem]
  //   : initialNavigation;

  // const handleChangeTraitsFilter = (items) => {
  //   const areEqual = items.every((item) => {
  //     const dataNodeItem = dataNodes.find((dn) => dn.id === item.id);
  //     return (
  //       item.isChecked === dataNodeItem.isChecked &&
  //       item.children.every((ci) => {
  //         const dataNodeItemChild = dataNodeItem.children.find(
  //           (dn) => dn.id === ci.id
  //         );
  //         return ci.isChecked === dataNodeItemChild.isChecked;
  //       })
  //     );
  //   });
  //   if (areEqual) {
  //     updateDataNodes(items);
  //     return;
  //   }
  //   const selectedTraits = items.reduce((acc, item) => {
  //     if (item.isChecked) {
  //       return acc.concat({
  //         id: item.id,
  //         values: item.children.filter((ci) => ci.isChecked).map((ci) => ci.id),
  //       });
  //     }
  //     const checkedSubItems = item.children.filter((ci) => ci.isChecked);
  //     if (checkedSubItems.length) {
  //       return acc.concat({
  //         id: item.id,
  //         values: checkedSubItems
  //           .filter((ci) => ci.isChecked)
  //           .map((ci) => ci.id),
  //       });
  //     }
  //     //console.log('Adding Label to selectedTraits data here');
  //     //console.log(acc);
  //     return acc;
  //   }, []);

  //   const flattenedPreviousChange = dataNodes.reduce((acc, item) => {
  //     const currentNodeInItems = selectedTraits.find((tn) => tn.id === item.id);
  //     return acc.concat(
  //       item.children
  //         ?.filter(
  //           (ci) => currentNodeInItems?.values?.includes(ci.id) && ci.isChecked
  //         )
  //         .map((ci) => ({ id: item.id, value: ci.value }))
  //     );
  //   }, []);

  //   const newChange = selectedTraits.reduce((acc, item) => {
  //     const prevValues = dataNodes
  //       .find((dn) => dn.id === item.id)
  //       ?.children?.filter((ci) => !ci.isChecked && item.values.includes(ci.id))
  //       .map((ci) => ({ id: item.id, value: ci.id }));
  //     return [...acc, ...prevValues];
  //   }, []);

  //   //console.log('newChange: label added here');
  //   //console.log(newChange);

  //   const getNewData = () => {
  //     let result = [];

  //     if (newChange.length < 5) {
  //       result = [
  //         ...flattenedPreviousChange.slice(
  //           flattenedPreviousChange.length + newChange.length <= 5
  //             ? 0
  //             : newChange.length,
  //           5
  //         ),
  //         ...newChange,
  //       ];
  //     } else {
  //       result = newChange.slice(0, 5);
  //     }

  //     return result;
  //   };

  //   const newChangeData = getNewData();
  //   //console.log(newChangeData);

  //   const currentSelectedItems = newChangeData.reduce((acc, item) => {
  //     const currItemIndex = acc.findIndex((ai) => ai.id === item.id);
  //     if (currItemIndex !== -1) {
  //       acc[currItemIndex].values = [...acc[currItemIndex].values, item.value];
  //       return acc;
  //     }

  //     return acc.concat({ id: item.id, values: [item.value] });
  //   }, []);

  //   const newSelectedItems = items.reduce((iacc, ci) => {
  //     let res = { ...ci };
  //     res.isChecked = currentSelectedItems.some((item) => item.id === ci.id);
  //     res.children = ci.children.map((child) => {
  //       let resChild = { ...child };
  //       resChild.isChecked = currentSelectedItems.some(
  //         (item) => item.id === ci.id && item.values.includes(child.id)
  //       );
  //       return resChild;
  //     });

  //     return iacc.concat(res);
  //   }, []);
  //   //console.log(currentSelectedItems);
  //   updateDataNodes(newSelectedItems);
  //   onTratsSelect(currentSelectedItems);
  // };

  // For And/Or filter Options (ops)
  // const handleChangeFilterType = (ops) => {
  //   onOpsSelect(ops);
  // };

  return (
    <div className="flex flex-col w-full min-h-screen">
      {/* FAQ module */}
      <FAQ open={isFAQOpen} setIsOpen={setIsFAQOpen} />
      <div className="sticky bg-gray-1200 rounded-b-xl top-0 z-20 flex-shrink-0 flex h-20 py-2 rounded-t-none shadow-topbar w-[74.5rem] mx-auto">
        <div className="flex justify-between flex-1 px-4">
          <div className="flex flex-1 p-2 items-center">
            {/* <Search /> */}
            <a href="/" onClick={() => gaEventTracker("click", "Logo")}>
              <Logo varient="long" />
            </a>
            <div className="ml-3 text-xs text-gray-1200 bg-pink-800 rounded-lg h-5 px-2 pt-[2px] text-center font-extrabold">
              BETA
            </div>
          </div>
          {isActive ? (
            <div className="flex items-center justify-end gap-8">
              {/* Current ETH Price */}
              <div className="flex items-center justify-start">
                <IconWrapper
                  size="22"
                  color="gray10"
                  hoverColor="white"
                  vbH="40"
                  vbW="40"
                >
                  <Etherium2 />
                </IconWrapper>
                <div className="flex flex-col ml-2">
                  <span className="text-blue-500 text-sm font-bold">
                    ${ethPrice ? ethPrice.toLocaleString() : ""}
                  </span>
                  {/* <span className="text-gray-500 text-xs font-semibold">
                    Current ETH Price
                  </span> */}
                </div>
              </div>

              {/* Seperator line */}
              <div className="h-7 w-1 border-l-2 border-gray-900" />

              <div className="flex gap-2">
                {/* Help/FAQ Dialog */}
                <button
                  onClick={() => {
                    setIsFAQOpen(true);
                  }}
                  className="flex items-center justify-end cursor-pointer focus:outline-none focus-visible:outline-1 focus-visible:outline-white hover:bg-gray-1100 rounded-[14px] px-2 transition group"
                >
                  {/* <Help
                  w="20"
                  h="20"
                  className="transition stroke-gray-100 fill-black hover:fill-gray-800"
                /> */}

                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4999 18.6V15.5H16.5332C18.2453 15.5 19.6332 14.1121 19.6332 12.4V12.1933C19.6332 10.5954 18.3378 9.29999 16.7399 9.29999H15.4999C13.7878 9.29999 12.3999 10.6879 12.3999 12.4M14.4665 21.7H16.5332M15.4999 29.9667C7.51015 29.9667 1.0332 23.4897 1.0332 15.5C1.0332 7.51027 7.51015 1.03333 15.4999 1.03333C23.4896 1.03333 29.9665 7.51027 29.9665 15.5C29.9665 23.4897 23.4896 29.9667 15.4999 29.9667Z"
                      stroke="#E8E8EA"
                      stroke-width="2"
                    />
                  </svg>
                  <span className="text-gray-200 text-sm font-bold ml-2 group-hover:text-white">
                    Help
                  </span>
                </button>

                {/* User Menu Popover */}
                <UserMenuPopover
                  user={user}
                  walletAddress={walletAddress}
                  ourTokenData={ourTokenData}
                  userWallets={userWallets}
                  authService={authService}
                  gaEventTracker={gaEventTracker}
                  onAddWalletSave={onAddWalletSave}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <main className="flex-1 h-full mx-auto">
        <div className="flex flex-col h-full py-6 justify-between w-[74.5rem]">
          {children}
        </div>
      </main>
    </div>
  );
}
