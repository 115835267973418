//import everything that is needed
import { useState, useRef, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
//import { ChevronDown2 } from "../Icons";
import { resizeImgURL } from "../../utils/URL";
import { isValidWalletAddress } from "../../utils/eth";
import styled from "styled-components";

// Using this styled-component because Tailwind
// was choking on image URLS for backgroud images
const ImageWrapper = styled.div`
  background-image: url("${(props) => props.bgImg}");
`;

export const UserMenuPopover = ({
  ourTokenData,
  user,
  walletAddress,
  userWallets,
  authService,
  gaEventTracker,
  onAddWalletSave,
}) => {
  console.log("UserMenuPopover Render Function Called");
  const [errorText, setErrorText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [saveReqPending, setSaveReqPending] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [walletJustAdded, setWalletJustAdded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [wallets, setWallets] = useState([...userWallets]); // detatch from prop

  function onSaveResponse(newWallets) {
    console.log("onSaveResponse: ", newWallets);
    setIsEditing(false);
    setSaveReqPending(false);
    setErrorText("Wallet added! Data will sync next time you log in.");
    setInputValue("");
    setWalletJustAdded(true);
    setWallets(newWallets);
  }

  const textInputRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setErrorText("");
      setIsEditing(false);
      setInputValue("");
      setWalletJustAdded(false);
    }
  }, [isOpen]);

  // Reset UI state when popover is closed
  const onPopoverChange = (isOpen) => {
    // console.log("onPopoverChange: ", isOpen);
    setIsOpen(isOpen);
  };

  // Save button click handler
  async function handleSaveClick(e) {
    const walletAddress = textInputRef.current.value;
    if (
      !isValidWalletAddress(walletAddress) ||
      wallets.includes(walletAddress)
    ) {
      setErrorText("Please enter a valid wallet address.");
      return;
    }

    console.log("Submitting valid wallet address");
    setSaveReqPending(true);
    const startTime = Date.now();
    // callback passed down from My Collection
    const response = await onAddWalletSave(walletAddress);
    if (response.success) {
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;
      if (timeElapsed < 2000) {
        console.log('setting timeout for "onSaveResponse"');
        setTimeout(() => {
          onSaveResponse(response.newWallets);
        }, 2000 - timeElapsed);
      } else {
        console.log('calling "onSaveResponse" immediately');
        onSaveResponse(response.newWallets);
      }
    } else {
      console.log("wallet save failed", response.message);
      setErrorText(response.message);
      setSaveReqPending(false);
    }
  }

  return (
    <Popover className="relative">
      {/* Begin Popover Button */}

      {/*
      This is a "render prop" pattern used by the Headless UI library
       To expose the open / closed state
       Todo this is causing an error in the console. Still works, but need to refactor at some point
       Probably just take control of the open / closed functionality completely
       */}
      {({ open }) => {
        onPopoverChange(open);
        return (
          <>
            <Popover.Button className="text-white py-[7px] hover:bg-gray-1100 rounded-[14px] px-2 transition  focus:outline-none ">
              <div className="flex flex-row items-center group">
                <div className="mr-2">
                  <ImageWrapper
                    bgImg={resizeImgURL(ourTokenData?.image)}
                    className="w-12 h-12 bg-blue-700 bg-cover rounded-full"
                  ></ImageWrapper>
                </div>
                <div className="flex flex-col items-start justify-start pt-[0px]">
                  <strong className="text-sm text-gray-200 group-hover:text-white">
                    {user?.username}
                  </strong>
                  {/* <span className="text-xs font-semibold text-gray-500">
                    {walletAddress.substring(0, 6)}...
                    {walletAddress.substring(
                      walletAddress.length - 4,
                      walletAddress.length - 1
                    )}
                  </span> */}
                </div>
                {/* <div className="ml-2 transition group-hover:translate-y-1">
                  <ChevronDown2
                    w="15"
                    h="15"
                    className="transition stroke-white"
                  />
                </div> */}
              </div>
            </Popover.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              {/* Begin Panel */}
              <Popover.Panel className="absolute z-10 top-[0px] left-[133px]">
                <div className="absolute z-10 flex flex-col justify-center w-auto px-8 py-8 mt-2 origin-top-right border border-gray-900 shadow-lg right-0 top-0 rounded-3xl bg-gray-1200 focus:outline-none">
                  <div className="relative">
                    <div className="font-bold text-lg text-white leading-[16px] mb-1">
                      Your wallets
                    </div>
                    {/* Show all wallets */}
                    <ul className="text-gray-600 text-sm font-semibold leading-none pr-4">
                      {wallets.map((wallet, idx) => (
                        <li
                          className="pl-3 pt-4 whitespace-nowrap flex items-center leading-3"
                          key={wallet}
                        >
                          {/*tailwind bug didnt allow me to only dymanically change the border color below*/}
                          <div
                            className={
                              [
                                "h-[3px] w-[3px] border-[3px] border-green-1000 rounded-[99px] mr-[4px]",
                                "h-[3px] w-[3px] border-[3px] border-gray-1200 rounded-[99px] mr-[4px]",
                                "h-[3px] w-[3px] border-[3px] border-gray-1200 rounded-[99px] mr-[4px]",
                              ][idx]
                            }
                          ></div>
                          {idx + 1 + " - " + wallet}
                        </li>
                      ))}
                      {/* <li className="p-1 text-gray-200 font-bold hover:bg-gray-800">
                      + Add wallet (3 max)
                    </li> */}
                    </ul>
                    {/* Show input textfield (add wallet) */}
                    {isEditing && (
                      <>
                        <input
                          ref={textInputRef}
                          autoFocus
                          readOnly={saveReqPending}
                          type="text"
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          className={
                            "transition w-full h-full pl-4 pr-0 py-3 mt-4 mb-1 border-gray-900 focus:outline-offset-0 bg-gray-1100 placeholder-gray-400 focus:outline-1 focus:outline-blue-1200 focus:placeholder-gray-700 focus:ring-0 focus:border-blue-700 focus:border rounded-[14px] text-sm placeholder:text-sm " +
                            (saveReqPending
                              ? "text-gray-600 focus:outline-0 focus:border-0"
                              : "text-gray-200 hover:border-gray-700")
                          }
                          placeholder="Add a wallet (3 max)"
                          autoComplete="off"
                          onClick={(e) => {
                            //setInputTextVisible(true);
                            e.stopPropagation();
                          }}
                        />

                        {/* Show Save Button (Add Wallet) */}
                        <button
                          type="button"
                          disabled={saveReqPending || inputValue === ""}
                          className={
                            "transition text-sm text-white bg-blue-800 mt-2 w-[70px] py-[10px] font-semibold tracking-wide leading-none text-center border border-transparent rounded-xl shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 " +
                            (saveReqPending || inputValue === ""
                              ? "opacity-50"
                              : "hover:bg-blue-600 focus:ring-blue-1000")
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSaveClick(e);
                          }}
                        >
                          {saveReqPending ? (
                            <svg
                              role="status"
                              class="inline w-4 h-4 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          ) : (
                            "Add"
                          )}
                        </button>

                        {/* Cancel Button (Add Wallet) */}
                        <button
                          type="button"
                          disabled={saveReqPending}
                          className={
                            "text-sm text-blue-800 w-[70px] mt-2 ml-3 py-[10px] leading-none font-bold tracking-wide text-center transition border border-transparent rounded-xl shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 " +
                            (saveReqPending
                              ? "opacity-50"
                              : "border-gray-900 hover:border-blue-700")
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(false);
                            setErrorText("");
                            setInputValue("");
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    {/* Show Add Wallet Text Button*/}
                    {!isEditing && wallets.length < 3 && !walletJustAdded && (
                      <div
                        className="text-blue-700 text-sm font-bold cursor-pointer pr-0 pt-[16px] leading-none transition hover:text-blue-900"
                        onClick={(e) => {
                          //updateAddWalletUIState("activeNew");
                          setIsEditing(true);
                          e.stopPropagation();
                        }}
                      >
                        Add a wallet (3 max)
                        {/* <span className="text-xs font-bold italic text-blue-900">
                            (3 max)
                          </span> */}
                      </div>
                    )}

                    {/* Show Error Message */}
                    <div
                      className={
                        "font-bold text-sm pr-0 pt-4 leading-none transition-opacity " +
                        (errorText.length > 0 ? "opacity-100 " : "opacity-0 ") +
                        (!walletJustAdded ? "text-red-1000" : "text-green-1200")
                      }
                    >
                      {errorText || "..."}
                    </div>
                  </div>

                  {/* Show Disconnect Button */}
                  <div className="flex flex-row items-center border-t border-gray-900 pt-6 mt-6">
                    <button
                      type="button"
                      className="w-min tracking-wide mx-auto px-4 py-4 text-sm leading-[13px] whitespace-nowrap font-semibold text-center text-white transition border border-transparent rounded-2xl shadow-sm bg-pink-1000 hover:bg-pink-800    focus:outline-none focus:ring-2 focus:ring-pink-500"
                      onClick={() => {
                        authService.send("LOG_OUT");
                        gaEventTracker("click", "Disconnect");
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
