import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useGlobalFilter,
  useBlockLayout,
  useGroupBy,
} from "react-table";
import ReactPaginate from "react-paginate";
import styled, { css } from "styled-components";
import { Flex } from "../Box";
import {
  ChevronDown,
  ChevronUp,
  ChevronLgLeft,
  ChevronLgRight,
} from "../Icons";
import { TableShimmer } from "../Shimmer/TableShimmer";

const StyledTable = styled.table`
  color: ${(props) => props.theme.colors.white};
  border-spacing: 0;
  border: 0;
  width: 100%;
  ${(props) =>
    !props.rowsHaveBorders &&
    css`
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    `}
  th,
  td {
    margin: 0;
    :last-child {
      border-right: 0;
    }
  }
`;

const StyledTableHead = styled.thead`
  background-color: transparent;
  border-radius: 16px;
  white-space: pre-wrap;
`;

const StyledTh = styled.th`
  font-family: ${(props) => props.theme.fonts.nunito};
  color: ${(props) =>
    props.isSorted ? props.theme.colors.white : props.theme.colors.gray6};
  padding: 6px 8px;
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 16px;
  align-items: center;
`;

const StyledHeadItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledHeadTr = styled.tr`
  font-family: ${(props) => props.theme.fonts.nunito};
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 12px;
  align-items: center;
  ${(props) =>
    props.areRowsClickable &&
    css`
      cursor: pointer;
    `}
`;

const StyledTr = styled.tr`
  font-family: ${(props) => props.theme.fonts.nunito};
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.colors.gray1};
  &:hover {
    background-color: ${(props) => props.theme.colors.gray2};
  }
  border-radius: 16px;
  align-items: center;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  ${(props) =>
    props.areRowsClickable &&
    css`
      cursor: pointer;
    `}
`;

const StyledTd = styled.td`
  font-family: ${(props) => props.theme.fonts.nunito};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  line-height: 1.5;
  padding: 6px 8px;
  text-align: ${(props) => props.align};
  font-weight: ${(props) =>
    props.isFooter
      ? props.theme.fontWeight.bold
      : props.theme.fontWeight.regular};
`;

const StyledPaginateContainer = styled(Flex)`
  margin-top: 8px;
  .table-pagination {
    display: flex;
    flex-direction: row;
    padding-left: 14px;
    align-items: center;
  }
  .page {
    margin: 0 4px;
    list-style: none;
    > a {
      border-radius: 2px;
      font-size: 14px;
      font-weight: 700;
      outline: none;
      font-family: ${(props) => props.theme.fonts.nunito};
      padding: 3px 8px;
      background: initial;
      color: ${(props) => props.theme.colors.gray10};
      &:hover {
        color: white;
      }
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &.active {
      > a {
        background: ${(props) => props.theme.colors.gray1};
        color: ${(props) => props.theme.colors.blue6};
      }
    }
    &.break-page {
      > a {
        background: initial;
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const StyledTableWrapper = styled(Flex)`
  position: relative;
`;

const PaginationTitle = styled.strong`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-family: ${(props) => props.theme.fonts.nunito};
  background: initial;
  color: ${(props) => props.theme.colors.gray9};
`;

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

// Custom sort function used when column value is an array,
// Assumes the value you want to sort on is at index 0 of the array
// This is for numerical sorts, alpha sorts use an inline function (localeCompare)
// React-table calls this on Sort passing these args
export function compareRowsNumeric(rowA, rowB, columnId) {
  const rowAVal = Number(rowA.values[columnId][1]) || 0; // if NaN then 0
  const rowBVal = Number(rowB.values[columnId][1]) || 0;
  let result;
  // console.log(rowA.values[columnId]);
  // console.log('A: '+rowAVal);
  // console.log('B: '+rowBVal);

  if (rowAVal > rowBVal) {
    result = 1;
  } else if (rowAVal < rowBVal) {
    result = -1;
  } else {
    result = 0;
  }

  // console.log('result: '+result);
  return result;
}

export function compareRowsAlpha(rowA, rowB, columnId) {
  return rowA.values[columnId][0].localeCompare(rowB.values[columnId][0]);
}

export function compareRowsDateTime(rowA, rowB, columnId) {
  const date1 = new Date(rowA.values[columnId]);
  const date2 = new Date(rowB.values[columnId]);

  if (date1 > date2) {
    return 1;
  } else if (date1 < date2) {
    return -1;
  } else {
    return 0;
  }
}

//todo reconcile with above, this was done in a rush specifically for My Tokens tokens table
export function compareRowsDateTimeHiddenField(rowA, rowB, columnId) {
  const date1 = new Date(rowA.original.purchased);
  const date2 = new Date(rowB.original.purchased);

  if (date1 > date2) {
    return 1;
  } else if (date1 < date2) {
    return -1;
  } else {
    return 0;
  }
}

export function Table({
  columns,
  data,
  pageCount: controlledPageCount = 20,
  hasPagination = false,
  areRowsClickable = false,
  onRowClick = () => {},
  onTableHeaderClick = () => {},
  canExpandTd = false,
  canSort = false,
  hasNoHeaderBackground = false,
  rowsHaveBorders = false,
  canChangePageSize = false,
  loading = false,
  initialState = { pageIndex: 0 },
  disableSortRemove = true,
  filterText,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    state,
    setGlobalFilter,
    allColumns = [],
  } = useTable(
    {
      columns,
      data,
      initialState,
      manualPagination: false,
      pageCount: controlledPageCount,
      autoResetPage: false,
      disableSortBy: !canSort,
      disableSortRemove,
    },
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout
  );

  useEffect(() => {
    if (filterText) {
      setGlobalFilter(filterText);
    }
  }, [filterText, setGlobalFilter]);

  const isMobile = useMobileScreen();

  const updateSelectedColumn = (column) => {
    allColumns.forEach((item, index) => {
      if (item.id === column || index === 0) {
        item.toggleHidden(false);
      } else {
        item.toggleHidden(true);
      }
    });
  };

  useEffect(() => {
    if (isMobile) {
      allColumns.forEach((item, index) => {
        if (index !== 0) {
          item.toggleHidden();
        }
      });
      updateSelectedColumn(allColumns[1].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, isMobile]);

  const startRowNum = state.pageIndex * state.pageSize + 1;
  const endRowNum =
    data.length < startRowNum + state.pageSize - 1
      ? data.length
      : startRowNum + state.pageSize - 1;

  const [expandedCell, setExpandedCell] = useState({});

  const handleExpandCell = (cell, i) => {
    if (expandedCell.i === i && expandedCell.cell.value === cell.value) {
      setExpandedCell({});
      return;
    }
    setExpandedCell({ cell, i });
  };

  return (
    <StyledTableWrapper flexDirection="column">
      {/* <Search filter={globalFilter} setFilter={setGlobalFilter} /> */}
      {isMobile && (
        <div className="mb-5">
          <label
            htmlFor="columSelected"
            className="block text-sm font-medium text-gray-700"
          >
            Select columns
          </label>
          <select
            id="columSelected"
            name="columSelected"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={allColumns[0].accessor()}
            onChange={(e) => {
              updateSelectedColumn(e.target.value);
            }}
          >
            {allColumns.slice(1).map((item) => (
              <option key={item.id} value={item.id}>
                {item.Header}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-visible">
          <div className="py-2 align-middle inline-block min-w-full">
            <StyledTable rowsHaveBorders={rowsHaveBorders} {...getTableProps()}>
              <StyledTableHead
                rowsHaveBorders={rowsHaveBorders}
                hasNoHeaderBackground={hasNoHeaderBackground}
              >
                {headerGroups.map((headerGroup) => (
                  <StyledHeadTr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <StyledTh
                        key={column.id}
                        rowsHaveBorders={rowsHaveBorders}
                        isSorted={column.isSorted}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <StyledHeadItem
                          onClick={() => {
                            onTableHeaderClick(column);
                          }}
                          hasColumnOptions={
                            column.columnOptions &&
                            column.columnOptions.length > 0
                          }
                          className={
                            column.canSort ? "hover:text-white transition" : ""
                          }
                        >
                          <div
                            className="cell-header text-left"
                            title={column.render("Header")}
                          >
                            {column.render("Header")}
                          </div>
                          <div>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDown color="white" />
                              ) : (
                                <ChevronUp color="white" />
                              )
                            ) : null}
                          </div>
                        </StyledHeadItem>
                      </StyledTh>
                    ))}
                  </StyledHeadTr>
                ))}
              </StyledTableHead>
              {loading ? (
                <tbody>
                  <tr>
                    <td>
                      <TableShimmer />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={i}>
                        <StyledTr
                          onClick={() => {
                            onRowClick(row);
                          }}
                          areRowsClickable={areRowsClickable}
                          key={i}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            const expandable =
                              canExpandTd || cell.column.canExpand;
                            return (
                              <StyledTd
                                onClick={() => {
                                  if (!expandable) return;
                                  handleExpandCell(cell, i);
                                }}
                                key={cell.value}
                                align={cell.column.align}
                                rowsHaveBorders={rowsHaveBorders}
                                expandableCell={expandable}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </StyledTd>
                            );
                          })}
                        </StyledTr>
                        {!!expandedCell && expandedCell.i === i && (
                          <StyledTr>
                            <StyledTd rowsHaveBorders={rowsHaveBorders}>
                              {expandedCell.cell.value}
                            </StyledTd>
                          </StyledTr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              )}
            </StyledTable>
          </div>
        </div>
      </div>
      {hasPagination && (
        <StyledPaginateContainer
          flexDirection="row"
          justifyContent={canChangePageSize ? "space-between" : "flex-end"}
          alignItems="center"
          borderTop="1px solid gray"
          paddingTop="10px"
          mb={3}
        >
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <PaginationTitle>
              Showing {startRowNum} to {endRowNum} of {data.length} items
            </PaginationTitle>
            <ReactPaginate
              pageClassName="page"
              nextClassName="page"
              previousClassName="page"
              previousLabel={
                <ChevronLgLeft color="gray11" size="12" viewBx="15" />
              }
              nextLabel={
                <ChevronLgRight color="gray11" size="12" viewBx="15" />
              }
              breakLabel="..."
              breakClassName="page break-page"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => gotoPage(data.selected)}
              containerClassName="table-pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
          </Flex>
        </StyledPaginateContainer>
      )}
    </StyledTableWrapper>
  );
}
