import { useState } from 'react';
import styled from 'styled-components';
import {
  AllCollectionTable,
  filterList,
  strToDetails,
} from '../components/AllCollectionTable/AllCollectionTable';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';

const Header = styled.div`
  margin-left: 8px;
  margin-bottom: 24px;
`;

const StyledHead = styled.h1`
  margin-bottom: 0px;
  font-weight: bolder;
  font-size: 34px;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.white};
`;

const HeaderP = styled.p`
  line-height: 22px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray6};
`;

const Case = styled.span`
  color: ${(props) => props.theme.colors.pink7};
`;

function AllCollection() {
  const [selectedTime, setSelectedTime] = useState(filterList[2]);
  return (
    <Sidebar>
      <div className="flex flex-col">
        <Header>
          <StyledHead><Case>Trending Collections</Case> by Sales</StyledHead>
          <HeaderP className="font-semibold">
            What collectors bought during the{' '}
            <strong className="text-gray-300">
              last {strToDetails[selectedTime]}
            </strong>
            .
          </HeaderP>
        </Header>
        <AllCollectionTable
          onChangeTime={(time) => {
            setSelectedTime(time);
          }}
        />
      </div>
      <Footer />
    </Sidebar>
  );
}

export default AllCollection;
