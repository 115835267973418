import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const DownFilled = withTheme(({ ...props }) => {
  //console.log(props);
  return (
    <StyledSVG
      width='15'
      height='16'
      viewBox='0 0 15 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.10359 10.1036L12.3107 5.89648L3.89648 5.89648L8.10359 10.1036Z'
      />
    </StyledSVG>
  );
});

DownFilled.displayName = 'DownFilled';
