import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  & label {
    position: relative;
    min-height: 25px;
    display: block;
    font-size: ${(props) => props.theme.fontSizes.xs}px;
    font-family: ${(props) => props.theme.fonts.nunito};
    color: ${(props) => props.theme.colors[props.labelColor]};
    padding-left: 30px;
    padding-top: 4px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  & label span {
    position: absolute;
    top: 50%;
  }
  & label:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 2px;
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.gray1};
    border: 2px solid ${(props) => props.theme.colors.gray4};
  }
  & input[type="checkbox"]:checked ~ label:before {
    background-color: ${(props) => props.theme.colors[props.color]};
    border: 2px solid ${(props) => props.theme.colors[props.color]};
  }
  & label:after {
    display: none;
  }
  & input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
  }
  & input[type="checkbox"]:checked ~ label:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjc5OTgwNSA0LjYwMDAyTDQuMzk5OCA4LjIwMDAyTDExLjE5OTggMS40MDAwMiIgc3Ryb2tlPSIjRThFOEVBIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=);
    display: block;
    position: absolute;
    top: 3px;
    left: 8px;
  }
`;

export const Checkbox = ({ label, id, color, labelColor, ...props }) => {
  return (
    <Wrapper color={color} labelColor={labelColor}>
      <input type="checkbox" id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </Wrapper>
  );
};
