import { useQuery } from '@apollo/client';
import { Flex } from '../Box';
import { Table } from '../Table';
import { getRecentListing, getRecentSales } from '../../graphql/stats';
import { prettyDate } from '../../utils/date.utils';
import { useParams } from 'react-router-dom';
import { formatETH } from '../../utils/eth';
import { roundToNearest } from '../../utils/numbers';

const getFormattedAddress = (address) => {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length - 1,
  )}`;
};

export const CollectionItems = () => {
  const handleRowClick = (row) => {
    console.log(row);
    window.open(row.original.openSeaURL);
  };
  const params = useParams();

  const { data: { getRecentListing: listingStats = [] } = {} } = useQuery(
    getRecentListing,
    {
      variables: { collectionSlug: params.collection },
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: { getRecentSales: salesStats = [] } = {} } = useQuery(
    getRecentSales,
    {
      variables: { collectionSlug: params.collection },
      notifyOnNetworkStatusChange: true,
    },
  );

  const simplifiedSalesStats = salesStats
    //.filter((item) => item.lastSold !== null)
    .map((item) => ({
      lastSoldTime: item?.lastSold?.saleDate,
      salePrice: roundToNearest(formatETH(item?.lastSold?.salePrice), 2),
      myRarity: roundToNearest(item?.rarity?.score, 2) || 'not available',
      openSeaURL:
        'https://opensea.io/assets/ethereum/' +
        item.contractAddress +
        '/' +
        item.tokenId,
      ...item,
    }));

  const simplifiedListingStats = listingStats.map((item) => ({
    listedTime: item?.listing?.listingDate || 'not available',
    seller: item?.listing?.listedBy || 'not available',
    listedPrice: roundToNearest(formatETH(item?.listing?.basePrice), 2),
    myRarity: roundToNearest(item?.rarity?.score, 2) || 'not available',
    openSeaURL:
      'https://opensea.io/assets/ethereum/' +
      item.contractAddress +
      '/' +
      item.tokenId,
    ...item,
  }));

  // console.log(simplifiedListingStats);

  const salesColumns = [
    {
      Header: 'Item',
      accessor: 'image',
      align: 'left',
      width: 120,
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <img
          src={value}
          alt="Collections"
          height={96}
          width={96}
          className="rounded-2xl"
        />
      ),
    },
    {
      Header: 'ID',
      accessor: 'tokenId',
      align: 'center',
      width: 74,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <div className="truncate">
            <span className="text-sm text-gray-100 font-bold">#{value}</span>
          </div>
        </Flex>
      ),
    },
    {
      Header: 'Sold',
      accessor: 'lastSoldTime',
      align: 'left',
      width: 120,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <span className="text-sm text-gray-400 font-bold">
            {prettyDate(value)}
          </span>
        </Flex>
      ),
    },
    // {
    //   Header: 'Rarity',
    //   accessor: 'myRarity',
    //   align: 'left',
    //   width: 80,
    //   Cell: ({ cell: { value } }) => (
    //     <Flex flexDirection="column" alignItems="baseline">
    //       <span className="text-gray-400">{value}</span>
    //     </Flex>
    //   ),
    // },
    {
      Header: 'Transfer',
      accessor: 'lastSold',
      align: 'left',
      disableSortBy: true,
      width: 100,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <span className="text-gray-400">
            {getFormattedAddress(value?.from || '')}
          </span>
          <span className="text-gray-400">
            {getFormattedAddress(value?.to || '')}
          </span>
        </Flex>
      ),
    },
    {
      Header: 'Price',
      accessor: 'salePrice',
      align: 'left',
      width: 80,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-white">{value}Ξ</strong>
        </Flex>
      ),
    },
  ];

  const recentListingsColumns = [
    {
      Header: 'Item',
      accessor: 'image',
      align: 'left',
      width: 120,
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <img
          src={value}
          alt="Collections"
          height={96}
          width={96}
          className="rounded-2xl"
        />
      ),
    },
    {
      Header: 'ID',
      accessor: 'tokenId',
      align: 'center',
      width: 74,
      Cell: ({ cell: { value } }) => (
        <div className="truncate">
            <span className="text-sm text-gray-100 font-bold">#{value}</span>
        </div>
      ),
    },
    {
      Header: 'Listed',
      accessor: 'listedTime',
      align: 'left',
      width: 120,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <span className="text-sm text-gray-400 font-bold">
            {prettyDate(value)}
          </span>
        </Flex>
      ),
    },
    // {
    //   Header: 'Rarity',
    //   accessor: 'myRarity',
    //   align: 'left',
    //   width: 80,
    //   Cell: ({ cell: { value } }) => (
    //     <Flex flexDirection="column" alignItems="baseline">
    //       <span className="text-gray-400">{value}</span>
    //     </Flex>
    //   ),
    // },
    {
      Header: 'Seller',
      accessor: 'seller',
      align: 'left',
      width: 100,
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <span className="text-gray-400">
            {getFormattedAddress(value || '')}
          </span>
        </Flex>
      ),
    },
    {
      Header: 'Price',
      accessor: 'listedPrice',
      align: 'left',
      width: 80,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-white">{value}Ξ</strong>
        </Flex>
      ),
    },
  ];

  return (
    <div className="flex flex-col rounded-xl overflow-hidden mt-5">
      <h1 className="text-white text-2xl font-extrabold pt-3">
        Collection Items
      </h1>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col w-1/2 px-3 relative flex-1">
          <h2 className="text-white text-lg pt-1 pb-3 font-bold">
            Recent Sales
          </h2>
          <div className="no-scrollbar">
            <Table
              columns={salesColumns}
              data={simplifiedSalesStats}
              onRowClick={handleRowClick}
              areRowsClickable="true"
              canSort="true"
              initialState={{
                pageIndex: 0,
                sortBy: [{ id: 'lastSoldTime', desc: true }],
              }}
              disableSortRemove="true"
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2 ml-5 px-3 relative flex-1">
          <h2 className="text-white text-lg pt-1 pb-3 font-bold">
            Recent Listings
          </h2>
          <div className="no-scrollbar">
            <Table
              columns={recentListingsColumns}
              data={simplifiedListingStats}
              onRowClick={handleRowClick}
              areRowsClickable="true"
              canSort="true"
              initialState={{
                pageIndex: 0,
                sortBy: [{ id: 'listedTime', desc: true }],
              }}
              disableSortRemove="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
