import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const SortDescending = withTheme(({ size = 16, color, theme }) => {
  return (
    <StyledSVG size={size} viewBox='0 0 24 24'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        d='m 13.432177,10.265218 v 7.009766 h -3 l 4,3.990234 4,-3.990234 h -3 v -7.009766 z'
        fill={theme.colors[color]}
      />
      <path
        d='m 8.4321765,3.2647498 -4,3.9902344 h 3 v 7.0097648 h 2 V 7.2549842 h 3.0000005 z'
        fill={theme.colors.iconSecondary}
      />
    </StyledSVG>
  );
});

SortDescending.displayName = 'SortDescending';
