import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const User = withTheme(({ ...props }) => {
  return (
    <StyledSVG
      width='13'
      height='16'
      viewBox='0 0 13 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.5 0.5C4.56717 0.5 3 2.06567 3 3.99804C3 5.93041 4.56717 7.49609 6.5 7.49609C8.43283 7.49609 10 5.93041 10 3.99804C10 2.06567 8.43283 0.5 6.5 0.5Z'
        fill='#34303E'
      />
      <path
        d='M4.5 9.49414C2.56711 9.49414 1 11.0605 1 12.9936V15.4909H12V12.9936C12 11.0605 10.4329 9.49414 8.5 9.49414H4.5Z'
        fill='#34303E'
      />
      <path
        d='M6.5 0.5C4.56717 0.5 3 2.06567 3 3.99804C3 5.93041 4.56717 7.49609 6.5 7.49609C8.43283 7.49609 10 5.93041 10 3.99804C10 2.06567 8.43283 0.5 6.5 0.5Z'
        stroke='#A5A3A9'
        strokeLinecap='square'
      />
      <path
        d='M4.5 9.49414C2.56711 9.49414 1 11.0605 1 12.9936V15.4909H12V12.9936C12 11.0605 10.4329 9.49414 8.5 9.49414H4.5Z'
        stroke='#A5A3A9'
        strokeLinecap='square'
      />
    </StyledSVG>
  );
});

User.displayName = 'User';
