import jwtDecode from "jwt-decode";
import {ApolloClient, InMemoryCache, from, HttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {TokenRefreshLink} from "apollo-link-token-refresh";
import {API_ENDPOINT} from "./config";

import {
  getAccessToken,
  setAccessToken,
  fetchAccessToken,
} from "./lib/accessToken";

const authLink = setContext(() => {
  const accessToken = getAccessToken();
  if (!accessToken) return {};
  return {
    headers: {authorization: `bearer ${accessToken}`},
  };
});

const tokenLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();
    if (!token) return true;
    try {
      const {exp} = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Unable to decode JWT token");
      return false;
    }
  },
  fetchAccessToken,
  handleFetch: (accessToken) => setAccessToken(accessToken),
  handleError: (error) => console.error(error),
});

const link = from([
  tokenLink,
  authLink,
  new HttpLink({
    uri: `${API_ENDPOINT}/gql`,
    credentials: "include",
  }),
]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
