import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const SortDefault = withTheme(({ size = 16, color, theme }) => {
  return (
    <StyledSVG height={size} viewBox='0 0 24 24' width={size}>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill={theme.colors[color]}
        d='M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z'
      />
    </StyledSVG>
  );
});

SortDefault.displayName = 'SortDefault';
