import { Disclosure, Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";

function DisclosureSet({ q, a }) {
  return (
    <div className="pb-3">
      <Disclosure>
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-900 px-4 transition py-2 text-left text-sm font-bold text-gray-200 hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              {q}
              <div
                className={
                  open
                    ? "transform duration-300 ease-in-out rotate-180 h-5 w-5"
                    : "transform duration-300 ease-in-out rotate-0 h-5 w-5"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-400">
              {a}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export function FAQ({ open, setIsOpen }) {
  const FAQContent = [
    {
      id: 1,
      q: "Something looks weird, what's going on?",
      a: 'This is app is currently in a "Beta phase", which means it is still in development. There may be bugs and the data may not be accurate or up-to-date.',
    },
    {
      id: 2,
      q: "Can I use this to do my taxes?",
      a: "This app does not represent financial advice. Please seek professional help for tax-related questions.",
    },
    {
      id: 3,
      q: "Which tokens and networks are supported?",
      a: "We are currently supporting ERC-721 tokens on the Ethereum network with transactions conducted in ETH or WETH.",
    },
    {
      id: 4,
      q: "Can I add my other wallets?",
      a: "The app will only support a single wallet at launch. Support for multiple wallets has been the most requested feature, so we plan on adding that soon.",
    },
    {
      id: 5,
      q: "In My Collectibles, why do I see some tokens that I don't recognize?",
      a: "We pull your wallet's data from the blockchain, so, if you're used to looking at your tokens on OpenSea, there may be tokens that they hide or that you elected to hide which will be visible in our app. We plan on supporting token hiding in the future.",
    },
    {
      id: 6,
      q: "In My Collectibles, why are some of my tokens missing?",
      a: "We have some basic Spam filtering which filters out tokens that have been delisted on OpenSea or that are missing key data fields. If you think a token has been filtered out by mistake, please send us a message here. Also, we are only supporting ERC-721 tokens, for now.",
    },
    {
      id: 7,
      q: "How does the P&L Chart work?",
      a: "Only tokens bought and sold from this wallet are calculated for the P&L chart. So, if you bought a token using a different wallet, then transferred it to this one, and then sold it, it won't be counted. Multi-wallet support, which is coming soon, will improve this.",
    },
    {
      id: 8,
      q: "How are you calculating the Total Collection Value?",
      a: "We multiply the number of tokens from each collection times the current floor price for that collection.",
    },
    {
      id: 9,
      q: "Why do some token images load slowly or not at all?",
      a: "Our data provider caches most images, but some are coming directly from IPFS, which is known to be slow and can sometimes block requests.",
    },
    {
      id: 10,
      q: "How can I get in touch?",
      a: "For help, suggestions, or questions, please reach connect via Discord.",
    },
  ];

  //let [isOpen, setIsOpen] = useState(false);
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        open={open}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto w-[600px] rounded-3xl">
              <div class="w-full min-h-[600px] border border-gray-800 rounded-2xl bg-gray-1100 p-6">
                <div className="text-xl font-bold text-gray-200 ml-1">
                  Need some help?
                </div>
                <div className="text-sm text-gray-400 pb-6 ml-1">
                  Below are some frequently asked questions. For additional
                  help, suggestions, or questions, please connect via{" "}
                  <a
                    className="underline font-bold"
                    href="https://discord.com/channels/891928857899327519/891928857899327522"
                  >
                    Discord
                  </a>
                  .
                </div>
                {FAQContent.map((faq) => {
                  return <DisclosureSet q={faq.q} a={faq.a} key={faq.id} />;
                })}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default FAQ;
