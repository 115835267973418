import { Flex } from '../Box';
import React from 'react';
import styled from 'styled-components';

const KPILoader = ({ children }) => {
  return (
    <Flex flexDirection="column">
      <Loader aria-hidden="true">{children}</Loader>
    </Flex>
  );
};

const Loader = styled.div`
  background-color: #0f0d15;
  height: 103px;
  width: 268px;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 16px;

  @keyframes color {
    0% {
      background-color: #0f0d15;
    }
    50% {
      background-color: #1d1929;
    }
    100% {
      background-color: #1d1929;
    }
  }
`;

export default KPILoader;
