import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col w-full h-screen min-h-screen text-white items-center justify-center">
          <div className="w-1/3 p-12 mx-auto bg-gray-1100 rounded-3xl">
            <h1 className="text-white text-center">
              We're sorry, something seems to have gone wrong. <br />
              Please visit our{" "}
              <a
                className="font-semibold underline"
                href="https://discord.com/invite/thenerdcollective"
              >
                Discord
              </a>{" "}
              server if you need additional assistance.
            </h1>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
