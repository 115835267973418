import { useContext } from "react";
import { useSelector } from "@xstate/react";
import { AuthStateContext } from "../../lib/auth.machine";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import { Logo } from "../components/Icons";

import { ConnectButtons } from "./ConnectButtons";
import { SetupUser } from "./SetupUser";

// use state selector to get the current state of the auth machine
// and the authError if there is one
const stateSelector = (state) => ({
  isState: state.matches,
  authError: state.context.authError,
});

// Login component
// this component is the entry point to the app
// it will render the ConnectButtons component if the user is not logged in
// or the SetupUser component if the user is logged in but has not setup their profile
// or it will redirect to the home page if the user is logged in and has setup their profile

export function Login() {
  const authService = useContext(AuthStateContext);
  const { isState, authError } = useSelector(authService, stateSelector);

  //const isMobile = true;

  if (isMobile) {
    // if the user is on a mobile device, render the mobile message
    return (
      <div className="flex flex-col items-center gap-4">
        {/* <Logo varient="long" width="200" height="86" /> */}
        <div className="w-full p-6 mx-auto bg-gray-1100 rounded-3xl">
          <h2 className="text-lg font-medium leading-6 text-center text-white">
            For the best experience, please use a desktop browser.
          </h2>
        </div>
      </div>
    );
  } else {
    //
    return (
      <div className="w-[42rem] mx-auto bg-gray-1100 rounded-3xl flex flex-col items-center justify-center min-h-[100px]">
        <div className="flex flex-col items-center gap-4">
          {/* <Logo varient="long" width="240" height="86" /> */}
          {isState("loading") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Loading...
            </h2>
          )}
          {isState("loginScreen") && <ConnectButtons error={authError} />}
          {/* {isState("loginScreen") && <SetupUser />} */}
          {/* //use this to work on user setup UI */}

          {isState("connecting") && <ConnectButtons loading />}
          {isState("verifying") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Logging in...
            </h2>
          )}
          {isState("verifySignature") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Verifying signature...
            </h2>
          )}
          {isState("userSetup") && <SetupUser />}
          {isState("updateUser") && (
            <SetupUser loading={isState("updateUser.savingUsername")} />
          )}
          {isState("syncing.syncingWallet") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Fetching your trading history. This can take several minutes...
            </h2>
          )}
          {isState("syncing.stillSyncing") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Still working on it...
            </h2>
          )}
          {isState("syncing.needMoreTime") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              This is taking a long time. Please come back later.
            </h2>
          )}
          {isState("goToHome") && <Navigate to="/" />}
          {isState("logOut") && (
            <h2 className="text-lg font-medium leading-6 text-center text-white">
              Logging out...
            </h2>
          )}
        </div>
      </div>
    );
  }
}
export default Login;
