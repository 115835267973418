import { Flex } from '../Box';
import React from 'react';
import styled from 'styled-components';

const ChartLoader = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="320px"
    >
      <Loader aria-hidden="true" />
    </Flex>
  );
};

const Loader = styled.div`
  border-radius: 80%;
  display: block;
  height: 64px;
  width: 64px;
  position: relative;
  animation: spin 1s linear 0s infinite normal;
  background: #1d1929;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }
  &:before {
    border-radius: 0 90px 90px 0;
    height: 64px;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 1;
    background: #005041;
    background-image: linear-gradient(#0f0d15, #1d1929);
  }
  &:after {
    border-radius: 80%;
    height: 52px;
    width: 52px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: #0f0d15;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export default ChartLoader;
