export const Etherium2 = () => {
  return (
    <>
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#627EEA"
      />
      <path
        d="M20.6225 5V16.0875L29.9936 20.2749L20.6225 5Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path d="M20.6226 5L11.25 20.2749L20.6226 16.0875V5Z" fill="white" />
      <path
        d="M20.6225 27.4601V34.9939L29.9999 22.02L20.6225 27.4601Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path
        d="M20.6226 34.9939V27.4588L11.25 22.02L20.6226 34.9939Z"
        fill="white"
      />
      <path
        d="M20.6225 25.7162L29.9936 20.275L20.6225 16.09V25.7162Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M11.25 20.275L20.6226 25.7162V16.09L11.25 20.275Z"
        fill="white"
        fillOpacity="0.602"
      />
    </>
  );
};
