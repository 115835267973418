import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const ChevronLgDown = withTheme(({ size = 15, color, theme }) => {
  return (
    <StyledSVG
    className='icon'
        width={`${size}px`}
        height={`${size}px`}
        viewBox='0 0 15 15'
        fill='none'
        stroke={theme.colors[color]}
        strokeWidth='1'
        strokeLinecap='square'
    >
    <path d="M14 5L7.5 12L1 5" />
  </StyledSVG>
  );
});

ChevronLgDown.displayName = 'ChevronLgDown';
