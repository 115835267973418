import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useMemo,
} from "react";
import { useQuery } from "@apollo/client";
import { getTokens } from "../../graphql/stats";
import { prettyDate } from "../../utils/date.utils";
import { formatETH } from "../../utils/eth";
import { roundToNearest, roundToMaxChars } from "../../utils/numbers";
import { Flex } from "../Box";
import {
  Table,
  compareRowsNumeric,
  compareRowsAlpha,
  compareRowsDateTimeHiddenField,
} from "../Table";
import placeholderImg from "../../assets/img/placeholder.png";
import { SimpleTabs } from "../Tabs";
import { useSelector } from "@xstate/react";
import { AuthStateContext } from "../../lib/auth.machine";
import Search from "./Search";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import { ImageGallery } from "../ImageGallery";
import { Switch } from "@headlessui/react";
import { resizeImgURL } from "../../utils/URL";

const stateSelector = (state) => ({
  user: state.context.user,
});

// todo reconcile this with other group function used elsewhere
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const Collectables = ({ onPFPTokenChange, onBannerTokensChange }) => {
  const gaEventTracker = useAnalyticsEventTracker("Collectables");
  const authService = useContext(AuthStateContext);
  const [filterTextState, setfilterTextState] = useState("");
  const { user } = useSelector(authService, stateSelector);
  const walletAddress = user?.address;
  const otherWalletAddresses = (user?.otherWallets || []).map((wallet) =>
    wallet.address.toLowerCase()
  );
  const allAddresses = [walletAddress, ...otherWalletAddresses];
  const [enabled, setEnabled] = useState(false);

  const {
    data: { getTokens: getTokensData = {} } = {},
    loading: tokensLoading,
    error,
  } = useQuery(getTokens, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
  });
  if (error) {
    console.log(error);
  }

  let tokensData = getTokensData?.tokens || [];

  // Alphabetize array which cascades to default sort order for gallery view
  let arrayForSort = [...tokensData];
  if (arrayForSort.length) {
    arrayForSort.sort((a, b) =>
      a?.collection?.name > b?.collection?.name
        ? 1
        : b?.collection?.name > a?.collection?.name
        ? -1
        : 0
    );
  }
  tokensData = arrayForSort;

  // some bad tokens dont have collection data
  if (tokensData) tokensData = tokensData.filter((token) => token.collection);

  const getListingStyle = (listingPrice, breakEvenPrice) => {
    if (!breakEvenPrice) {
      return "text-white";
    } else if (listingPrice - breakEvenPrice >= 0) {
      return "text-green-1200";
    } else {
      return "text-red-800";
    }
  };

  // simplify the data for table rendering
  function simplifyData(item) {
    let royalty = item?.collection?.royalty / 10000;
    let purchasePrice = formatETH(item?.transfer?.value);
    let gasPrice = formatETH(item?.transfer?.gasFee);
    let breakEvenListPrice =
      (purchasePrice + gasPrice) / (1 - (royalty + 0.025));
    let marginListPrice = breakEvenListPrice * 1.25;
    let basePrice = formatETH(item?.listing?.basePrice);
    let listing = !item?.listing
      ? ["-", null]
      : [roundToNearest(basePrice, 3), breakEvenListPrice];
    const soldFor = formatETH(item?.transfer?.value);
    const fromWallet = (item?.transfer?.from || "").toLowerCase();
    const transactionInitiator = (item?.transaction?.from || "").toLowerCase();
    const wallet = (item?.transfer?.to || "").toLowerCase();
    const walletID = allAddresses.indexOf(wallet);

    simplifiedData.push({
      wallet,
      walletID,
      type:
        fromWallet === "0x0000000000000000000000000000000000000000"
          ? "Mint"
          : purchasePrice === 0
          ? "Transfer"
          : "Purchase",
      image: resizeImgURL(item?.image, 500) || "",
      collection: [
        "/overview/" + item?.collection?.slug,
        item?.collection?.name || "",
      ],
      id: item?.tokenId,
      contractAddress: item?.contractAddress,
      openSeaURL:
        "https://opensea.io/assets/ethereum/" +
        item?.contractAddress +
        "/" +
        item?.tokenId,
      purchased: item?.purchasedAt || "",
      purchasedDisplay: prettyDate(item?.purchasedAt),
      rarity: roundToNearest(item?.rarity?.score, 0) || "not available",
      purchasePrice: [
        allAddresses.includes(transactionInitiator)
          ? roundToNearest(gasPrice, 3)
          : "0",
        roundToNearest(purchasePrice, 3),
      ],
      royaltyServiceFee: [2.5, roundToNearest(royalty * 100, 1)],
      breakEvenListPrice: roundToNearest(breakEvenListPrice, 2),
      marginListPrice: roundToNearest(marginListPrice, 2),
      listedAt: listing,
      soldFor,
    });
  }

  // process the data from server for the table
  let simplifiedData = [];
  tokensData.map(simplifyData);

  //filter the simplified data based on the text in the "Search" component
  simplifiedData = simplifiedData.filter((token) => {
    const collectionName = token?.collection?.[1];
    if (collectionName) {
      return collectionName.toLowerCase().includes(filterTextState);
    } else {
      return true;
    }
  });

  // Calling OpenSea directly, no API key required aa
  const [OSCollectionsData, setOSCollectionsData] = useState([]);
  const tempOSCollectionData = [];
  let currentOffset = 0;

  const OpenSeaReqOptions = {
    method: "GET",
    headers: { accept: "application/json" },
  };

  const OSCollectionsURL =
    "https://api.opensea.io/api/v1/collections?asset_owner=";

  function fetchOSCollectionData() {
    console.log("Starting fetchOSCollectionData from collectables");
    fetch(
      OSCollectionsURL +
        walletAddress +
        "&offset=" +
        currentOffset +
        "&limit=300",
      OpenSeaReqOptions
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        console.log("Finish fetchOSCollectionData from collectables");
        gatherOSCollectionData(data);
        //setOSCollectionsData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  function gatherOSCollectionData(newData) {
    tempOSCollectionData.push(...newData); //mutable merge
    if (newData.length < 300) {
      // last page reached
      setOSCollectionsData(tempOSCollectionData);
    } else {
      currentOffset += 300;
      fetchOSCollectionData(); // fetch again with offset
    }
  }

  // passing the empty array means this only runs "On Mount"
  useEffect(() => {
    fetchOSCollectionData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCollectionsFromTokens(tokensData) {
    let collections = [];
    // group token data by collection name
    const grouped = groupBy(
      tokensData,
      (token) => token?.collection?.name || ""
    );
    //console.log(grouped);
    grouped.forEach((group) => {
      const exampleToken = group[0];
      //console.log(exampleToken);
      const collection = {
        name: exampleToken?.collection?.name || "",
        contractAddress: exampleToken?.contractAddress || "",
        openSeaURL:
          "https://opensea.io/collection/" + exampleToken?.collection?.slug,
        purchased: "--",
        image: exampleToken?.image || "",
        floor:
          roundToMaxChars(
            formatETH(exampleToken?.collection?.alchemyFloorPrice),
            4
          ) || "--",
        royalty: exampleToken?.collection?.royalty / 10000,
        tokenCount: group.length,
        totalSpent: [
          roundToNearest(
            group.reduce(
              (total, token) => total + formatETH(token?.transfer?.gasFee),
              0
            ),
            3
          ),
          roundToNearest(
            group.reduce(
              (total, token) => total + formatETH(token?.transfer?.value),
              0
            ),
            3
          ),
        ],
        listings: group.reduce(
          (total, token) => total + (token?.listing ? 1 : 0),
          0
        ),
      };
      collections.push(collection);
    });

    //filter the collection data based on the text in the "Search" component
    collections = collections.filter((collection) => {
      return (collection?.name || "").toLowerCase().includes(filterTextState);
    });

    return collections;
  }

  const collectionsData = getCollectionsFromTokens(tokensData);
  //console.log("collections data", collectionsData);

  // Collections: combine the two data sets for the table
  // todo: it would seem that none of the OS collections that dont have addresses
  // Are found in the Alchemy collections, at least by the name check below.
  if (collectionsData.length && OSCollectionsData.length) {
    collectionsData.forEach((collection) => {
      const matchingOSCollection = OSCollectionsData.find(
        (OSCollection) =>
          OSCollection?.primary_asset_contracts?.[0]?.address ===
          collection.contractAddress
      );

      if (matchingOSCollection) {
        // add new or replace some of the collection fields with data from OpenSea
        collection.image = matchingOSCollection?.image_url || placeholderImg;
        //collection.floor = matchingOSCollection?.stats?.floor_price || 0;
        collection.name = [
          matchingOSCollection?.name || "Name not found",
          matchingOSCollection?.stats?.count || 0,
        ];
        collection.num_owners = matchingOSCollection?.stats?.num_owners || 0;
        collection.market_cap = roundToMaxChars(
          matchingOSCollection?.stats?.market_cap || 0,
          4
        );
        collection.one_day_sales =
          matchingOSCollection?.stats?.one_day_sales || 0;
      } else {
        //console.log("Cant find match", collection.name);
        collection.name = [collection.name, 0];
        collection.floor = 0;
        collection.num_owners = 0;
        collection.market_cap = 0;
        collection.one_day_sales = 0;
      }
    });
  }

  const tokenColumns = useMemo(
    () => [
      {
        Header: "Item",
        accessor: "image",
        align: "left",
        width: 140,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <img
            src={value}
            className="rounded-2xl bg-gray-1100"
            alt="NFT Thumbnail"
            height={96}
            width={96}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholderImg;
            }}
          />
        ),
      },
      {
        Header: "Collection",
        accessor: "collection",
        align: "left",
        width: 180,
        sortType: compareRowsAlpha,
        Cell: ({ cell: { value } }) => (
          // <a href={value[0]}>
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-white font-bold">{value[1]}</span>
          </Flex>
          // </a>
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        align: "center",
        width: 80,
        Cell: ({ cell: { value } }) => (
          <Flex
            flexDirection="column"
            alignItems="baseline"
            className="truncate"
          >
            <span className="text-gray-500 font-bold">#{value}</span>
          </Flex>
        ),
      },
      {
        Header: "Acquired",
        accessor: "purchasedDisplay",
        id: "purchased",
        align: "left",
        width: 100,
        sortType: compareRowsDateTimeHiddenField,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-500 font-bold">{value}</span>
          </Flex>
        ),
      },
      {
        Header: "Method",
        accessor: "type",
        align: "left",
        width: 90,
        sortType: compareRowsAlpha,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-500 font-bold">{value}</span>
          </Flex>
        ),
      },
      {
        Header: "Wallet",
        accessor: "wallet",
        align: "left",
        width: 120,
        sortType: (rowA, rowB) => {
          const ID1 = rowA.original.walletID;
          const ID2 = rowB.original.walletID;

          if (ID1 > ID2) {
            return 1;
          } else if (ID1 < ID2) {
            return -1;
          } else {
            return 0;
          }
        },
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-500 font-bold">
              {value.substring(0, 6)}...
              {value.substring(value.length - 4, value.length - 1)}
            </span>
          </Flex>
        ),
      },
      // {
      //   Header: 'Rarity',
      //   accessor: 'rarity',
      //   align: 'left',
      //   width: 80,
      //   Cell: ({ cell: { value } }) => (
      //     <Flex flexDirection="column" alignItems="baseline">
      //       <span className="text-gray-400">{value}</span>
      //     </Flex>
      //   ),
      // },
      {
        Header: "Price \n& Gas",
        accessor: "purchasePrice",
        align: "left",
        width: 80,
        sortType: compareRowsNumeric, //using custom sort function because data contains an array of 2 values
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value[1]}Ξ</strong>
            <span className="text-gray-400 text-xs font-bold">{value[0]}</span>
          </Flex>
        ),
      },
      {
        Header: "Royalty \n& Fees",
        accessor: "royaltyServiceFee",
        align: "left",
        width: 80,
        sortType: compareRowsNumeric,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value[1]}%</strong>
            <span className="text-gray-400 text-xs font-bold">{value[0]}%</span>
          </Flex>
        ),
      },
      {
        Header: "Break even\nlist price",
        accessor: "breakEvenListPrice",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value}Ξ</strong>
          </Flex>
        ),
      },
      {
        Header: "25% margin\nlist price",
        accessor: "marginListPrice",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value}Ξ</strong>
          </Flex>
        ),
      },
      {
        Header: "Listed at",
        accessor: "listedAt",
        align: "left",
        width: 100,
        sortType: compareRowsNumeric,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className={getListingStyle(value[0], value[1])}>
              {value[0]}
              {value[0] !== "-" ? "Ξ" : ""}
            </strong>
          </Flex>
        ),
      },
    ],
    []
  );

  const collectionsColumns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        align: "left",
        width: 140,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <img
            src={value}
            className="rounded-2xl bg-gray-1100"
            alt="NFT Thumbnail"
            height={96}
            width={96}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholderImg;
            }}
          />
        ),
      },
      {
        Header: "Collection",
        accessor: "name",
        align: "left",
        width: 180,
        sortType: compareRowsAlpha,
        Cell: ({ cell: { value } }) => (
          // <a href={value[0]}>
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-white font-bold">{value[0]}</span>
            <span className="text-gray-600 text-xs font-extrabold">
              {value[1].toLocaleString()}
            </span>
          </Flex>
          // </a>
        ),
      },
      {
        Header: "Floor price",
        accessor: "floor",
        align: "left",
        width: 120,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value || 0}Ξ</strong>
          </Flex>
        ),
      },
      {
        Header: "Market Cap",
        accessor: "market_cap",
        align: "left",
        width: 120,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-white font-bold">
              {Number(value).toLocaleString()}Ξ
            </span>
          </Flex>
        ),
      },
      {
        Header: "24H Sales",
        accessor: "one_day_sales",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-400">{value || 0}</span>
          </Flex>
        ),
      },
      {
        Header: "Owners",
        accessor: "num_owners",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-400">
              {Number(value).toLocaleString()}
            </span>
          </Flex>
        ),
      },
      {
        Header: "You own",
        accessor: "tokenCount",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-400">{value}</span>
          </Flex>
        ),
      },
      {
        Header: "Total spent / Gas",
        accessor: "totalSpent",
        align: "left",
        width: 120,
        sortType: compareRowsNumeric, //using custom sort function because data contains an array of 2 values
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-white font-bold">{value[1]}Ξ</strong>
            <span className="text-gray-400 text-xs font-bold">{value[0]}</span>
          </Flex>
        ),
      },
      {
        Header: "Your listings",
        accessor: "listings",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-400">{value}</span>
          </Flex>
        ),
      },
      {
        Header: "Royalty",
        accessor: "royalty",
        align: "left",
        width: 100,
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="baseline">
            <span className="text-gray-400">
              {roundToMaxChars(value * 100, 3)}%
            </span>
          </Flex>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    gaEventTracker("click", "Row");
    window.open(
      row?.original?.openSeaURL ||
        "https://opensea.io/collection/nerdcollective-genesis-pfp"
    );
  };

  const handleItemClick = (item) => {
    gaEventTracker("click", "Item");
    window.open(
      item?.openSeaURL ||
        "https://opensea.io/collection/nerdcollective-genesis-pfp"
    );
  };

  return (
    <div className="flex flex-col rounded-xl p-4 overflow-hidden mt-5">
      <h1 className="text-white text-2xl font-extrabold mb-5">
        Your Collectibles
      </h1>

      <div className="mb-2 flex flex-row justify-between">
        <Search
          defaultText={filterTextState}
          changeCallBack={setfilterTextState}
        />
        <Switch checked={enabled} onChange={setEnabled} as={Fragment}>
          {({ checked }) => (
            /* Use the `checked` state to conditionally style the button. */
            <button className="relative inline-flex h-9 w-[74px] items-center rounded-xl bg-gray-1100 ml-2 border border-gray-900">
              <span className="sr-only">Switch layouts</span>
              <div className="absolute inset-y-0 left-[48px] flex items-center pointer-events-none z-10">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 0C0.671573 0 0 0.671573 0 1.5V5.5C0 6.32843 0.671573 7 1.5 7H5.5C6.32843 7 7 6.32843 7 5.5V1.5C7 0.671573 6.32843 0 5.5 0H1.5Z"
                    fill="#d2d1d4"
                  />
                  <path
                    d="M9.5 0C8.67157 0 8 0.671573 8 1.5V5.5C8 6.32843 8.67157 7 9.5 7H13.5C14.3284 7 15 6.32843 15 5.5V1.5C15 0.671573 14.3284 0 13.5 0H9.5Z"
                    fill="#d2d1d4"
                  />
                  <path
                    d="M1.5 8C0.671573 8 0 8.67157 0 9.5V13.5C0 14.3284 0.671573 15 1.5 15H5.5C6.32843 15 7 14.3284 7 13.5V9.5C7 8.67157 6.32843 8 5.5 8H1.5Z"
                    fill="#d2d1d4"
                  />
                  <path
                    d="M9.5 8C8.67157 8 8 8.67157 8 9.5V13.5C8 14.3284 8.67157 15 9.5 15H13.5C14.3284 15 15 14.3284 15 13.5V9.5C15 8.67157 14.3284 8 13.5 8H9.5Z"
                    fill="#d2d1d4"
                  />
                </svg>
              </div>
              <div className="absolute inset-y-0 left-[10px] flex items-center pointer-events-none z-10">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1C0.671573 1 0 1.67157 0 2.5V4.5C0 5.32843 0.671573 6 1.5 6H3.5C4.32843 6 5 5.32843 5 4.5V2.5C5 1.67157 4.32843 1 3.5 1H1.5Z"
                    fill="#d2d1d4"
                  />
                  <path d="M7 4H15V3H7V4Z" fill="#d2d1d4" />
                  <path
                    d="M1.5 9C0.671573 9 0 9.67157 0 10.5V12.5C0 13.3284 0.671573 14 1.5 14H3.5C4.32843 14 5 13.3284 5 12.5V10.5C5 9.67157 4.32843 9 3.5 9H1.5Z"
                    fill="#d2d1d4"
                  />
                  <path d="M7 12H15V11H7V12Z" fill="#d2d1d4" />
                </svg>
              </div>
              <span
                className={`${
                  checked ? "translate-x-9" : "translate-x-0"
                } inline-block h-9 w-9 transform rounded-xl bg-blue-800 transition hover:bg-blue-900`}
              />
            </button>
          )}
        </Switch>
      </div>
      <SimpleTabs
        style={{ maxWidth: "50%" }}
        onClickTab={(tab) => {
          gaEventTracker("click", "Tab-" + tab.label);
        }}
      >
        {!enabled ? (
          <div id="tab-1" label="Tokens" className="overflow-x-auto">
            <div className="min-h-[1000px]">
              <Table
                columns={tokenColumns}
                data={simplifiedData}
                onRowClick={handleRowClick}
                loading={tokensLoading}
                hasPagination
                areRowsClickable="true"
                canSort
                filterText={filterTextState}
                initialState={{
                  pageIndex: 0,
                  sortBy: [{ id: "purchased", desc: true }],
                }}
                disableSortRemove="true"
              />
            </div>
          </div>
        ) : (
          <div id="tab-1" label="Tokens" className="overflow-x-auto">
            <ImageGallery
              data={simplifiedData}
              itemsPerPage={20}
              onItemClick={handleItemClick}
              onPFPTokenChange={onPFPTokenChange}
              onBannerTokensChange={onBannerTokensChange}
            />
          </div>
        )}
        {!enabled ? (
          <div id="tab-2" label="Collections" className="overflow-x-auto">
            <div className="min-h-[800px]">
              <Table
                columns={collectionsColumns}
                data={collectionsData}
                onRowClick={handleRowClick}
                areRowsClickable="true"
                hasPagination
                canSort
                filterBy={filterTextState}
                initialState={{
                  pageIndex: 0,
                  sortBy: [{ id: "name", desc: false }],
                }}
              />
            </div>
          </div>
        ) : (
          <div id="tab-2" label="Collections" className="overflow-x-auto">
            <ImageGallery
              data={collectionsData}
              itemsPerPage={20}
              isCollection="true"
              onItemClick={handleItemClick}
            />
          </div>
        )}
      </SimpleTabs>
    </div>
  );
};
