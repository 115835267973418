export const borders = {
  small: '5px',
  medium: '10px',
  large: '20px',
};

export const fontWeight = {
  light: '300',
  regular: '400',
  semibold: '600',
  bold: '700',
  extrabold: '800',
};

export const fonts = {
  openSans: "'Open Sans', sans-serif",
  nunito: "'Nunito Sans', sans-serif",
};

//export const fontSizes = [14, 18, 20, 24, 36];
// can we reference tailwind utility classes in here? I duplicated them for now.
export const fontSizes = {
  xs: 12,
  sm: 14,
  base: 16,
  lg: 18,
};

export const shadowDark = {
  shadowGrey: '0px 5px 15px 0px rgba(13, 18, 28, 0.6)',
};
export const shadowLight = {
  shadowGrey: '0px 5px 15px 0px rgba(177, 184, 191, 0.2)',
};

export const space = [
  0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768,
];

const colorsDark = {
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#0f0d15',
  gray2: '#1d1929',
  gray3: '#34303e',
  gray4: '#4a4754',
  gray5: '#615e69',
  gray6: '#77757f',
  gray7: '#8e8c94',
  gray8: '#a5a3a9',
  gray9: '#bbbabf',
  gray10: '#d2d1d4',
  gray11: '#e8e8ea',
  gray12: '#f9f9f9',
  blue1: '#0030cc',
  blue2: '#0036e6',
  blue3: '#003cff',
  blue4: '#1a50ff',
  blue5: '#3363ff',
  blue6: '#4d77ff',
  blue7: '#668aff',
  blue8: '#809eff',
  blue10: '#b3c5ff',
  blue11: '#ccd8ff',
  blue12: '#e6ecff',
  pink1: '#c80068',
  pink3: '#fa0082',
  pink5: '#fb339b',
  pink6: '#fc4da8',
  pink7: '#fc66b4',
  pink8: '#fd80c1',
  pink11: '#fecce6',
  pink12: '#ffe6f3',
  green1: '#18ba92',
  green2: '#1bd2a4',
  green3: '#1ee9b6',
  green8: '#8ff4db',
  green10: '#bcf8e9',
  green11: '#d2fbf0',
  green12: '#e9fdf8',
  yellow1: '#ccb243',
  yellow2: '#e6c84c',
  yellow3: '#ffde54',
  yellow5: '#ffe576',
  yellow6: '#ffe887',
  yellow8: '#ffefaa',
  yellow11: '#fff8dd',
  yellow12: '#fffcee',
  orange1: '#cc5801',
  orange3: '#ff6e01',
  orange5: '#ff8b34',
  orange6: '#ff9a4d',
  orange8: '#ffb780',
  orange9: '#ffc599',
  orange11: '#ffe2cc',
  orange12: '#fff1e6',
  red1: '#c32021',
  red2: '#dc2425',
  red3: '#f42829',
  red4: '#f53e3e',
  red5: '#f65354',
  red6: '#f76969',
  red8: '#f87e7f',
  red10: '#fcbfbf',
  red11: '#fdd4d4',
  red12: '#feeaea',
  purple1: '#ae59c6',
  purple2: '#c364df',
  purple3: '#d96ff8',
  purple5: '#e18cf9',
  purple6: '#e49afa',
  purple8: '#ecb7fc',
  purple9: '#f0c5fc',
  purple10: '#f4d4fd',
  purple11: '#f7e2fe',
  purple12: '#fbf1fe',
  transparent: 'transparent',
};

const colorsLight = {
  black: '#000000',
  white: '#FFFFFF',
  gray1: '#0f0d15',
  gray2: '#1d1929',
  gray3: '#34303e',
  gray4: '#4a4754',
  gray5: '#615e69',
  gray6: '#77757f',
  gray7: '#8e8c94',
  gray8: '#a5a3a9',
  gray9: '#bbbabf',
  gray10: '#d2d1d4',
  gray11: '#e8e8ea',
  gray12: '#f9f9f9',
  blue1: '#0030cc',
  blue2: '#0036e6',
  blue3: '#003cff',
  blue4: '#1a50ff',
  blue5: '#3363ff',
  blue6: '#4d77ff',
  blue7: '#668aff',
  blue8: '#809eff',
  blue10: '#b3c5ff',
  blue11: '#ccd8ff',
  blue12: '#e6ecff',
  pink1: '#c80068',
  pink3: '#fa0082',
  pink5: '#fb339b',
  pink6: '#fc4da8',
  pink7: '#fc66b4',
  pink8: '#fd80c1',
  pink11: '#fecce6',
  pink12: '#ffe6f3',
  green1: '#18ba92',
  green2: '#1bd2a4',
  green3: '#1ee9b6',
  green8: '#8ff4db',
  green10: '#bcf8e9',
  green11: '#d2fbf0',
  green12: '#e9fdf8',
  yellow1: '#ccb243',
  yellow2: '#e6c84c',
  yellow3: '#ffde54',
  yellow5: '#ffe576',
  yellow6: '#ffe887',
  yellow8: '#ffefaa',
  yellow11: '#fff8dd',
  yellow12: '#fffcee',
  orange1: '#cc5801',
  orange3: '#ff6e01',
  orange5: '#ff8b34',
  orange6: '#ff9a4d',
  orange8: '#ffb780',
  orange9: '#ffc599',
  orange11: '#ffe2cc',
  orange12: '#fff1e6',
  red1: '#c32021',
  red2: '#dc2425',
  red3: '#f42829',
  red4: '#f53e3e',
  red5: '#f65354',
  red6: '#f76969',
  red8: '#f87e7f',
  red10: '#fcbfbf',
  red11: '#fdd4d4',
  red12: '#feeaea',
  purple1: '#ae59c6',
  purple2: '#c364df',
  purple3: '#d96ff8',
  purple5: '#e18cf9',
  purple6: '#e49afa',
  purple8: '#ecb7fc',
  purple9: '#f0c5fc',
  purple10: '#f4d4fd',
  purple11: '#f7e2fe',
  purple12: '#fbf1fe',
  transparent: 'transparent',
};

export const possibleChartColors = [
  '#4D77FF',
  '#fb339b',
  '#1ee9b6',
  '#ffe576',
  '#ff8b34',
  '#f65354',
  '#e18cf9',
  '#fcbfbf',
  '#3363ff',
];

export const lightTheme = {
  colors: { ...colorsLight },
  space,
  shadows: { ...shadowLight },
  fontSizes,
  fontWeight,
  fonts,
  borders,
};

export const darkTheme = {
  colors: { ...colorsDark },
  space,
  shadows: { ...shadowDark },
  fontSizes,
  fontWeight,
  fonts,
  borders,
};
