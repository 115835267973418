import { createMachine, assign } from "xstate";
import React, { createContext } from "react";
import { useInterpret } from "@xstate/react";
import { useNavigate } from "react-router-dom";
import { utils } from "ethers";

import { apolloClient } from "../apollo";
import { setAccessToken, refreshToken } from "./accessToken";
import { getUser as getUserQuery } from "../graphql/stats";

import {
  requestLogin as requestLoginQuery,
  verifyLogin as verifyLoginQuery,
  loginWithAddress as loginWithAddressQuery,
  logOut as logOutQuery,
  setUsername as setUsernameMutation,
} from "../graphql/login";

const getCoinbaseWallet = () => {
  if (!window.ethereum) return;
  if (window.ethereum.providers) {
    // user has multiple wallets
    return window.ethereum.providers.find(
      (provider) => provider.isCoinbaseWallet
    );
  }
  return window.ethereum.isCoinbaseWallet ? window.ethereum : undefined;
};

const getMetamaskWallet = () => {
  if (!window.ethereum) return;
  if (window.ethereum.providers) {
    // user has multiple wallets
    return window.ethereum.providers.find((provider) => provider.isMetaMask);
  }
  return window.ethereum.isMetaMask ? window.ethereum : undefined;
};

const getWallet = (providerName) => {
  if (providerName === "Metamask") return getMetamaskWallet();
  if (providerName === "Coinbase") return getCoinbaseWallet();
  return;
};

const getConnectedAccounts = async (providerName) => {
  try {
    const provider = getWallet(providerName);
    if (!provider) return [];
    const accounts = await provider.request({
      method: "eth_accounts",
    });
    return accounts;
  } catch (error) {
    return [];
  }
};

const initAuth = async () => {
  await refreshToken();
  const resp = await apolloClient.query({
    query: getUserQuery,
    fetchPolicy: "network-only",
  });
  const user = resp.data.getUser.user;
  if (user) {
    const { address = "", providerName } = user;
    const accounts = await getConnectedAccounts(providerName);
    const normalizedAccounts = (accounts || []).map((account) =>
      account.toLowerCase()
    );
    if (!normalizedAccounts.includes(address.toLowerCase())) return null;
  }
  return user;
};

const loginWithAddress = async (context, event) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: loginWithAddressQuery,
      variables: { address: event.address },
    });
    return resp.data.loginWithAddress;
  } catch (error) {
    throw new Error(error.message);
  }
};

const setUsername = async (context) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: setUsernameMutation,
      variables: { username: context.username },
    });
    return resp.data.setUsername;
  } catch (error) {
    throw new Error(error.message);
  }
};

const requestLogin = async (address) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: requestLoginQuery,
      variables: { address },
    });
    return resp.data.requestLogin;
  } catch (error) {
    throw new Error(error.message);
  }
};

const verifyLogin = async (context) => {
  const { providerName } = context;
  let provider = getWallet(providerName);
  if (!provider)
    throw new Error(`${providerName} wallet is not installed in this browser`);
  const { accountAddress = "", username, signData } = context;
  const signature = await provider.send("personal_sign", [
    utils.hexlify(utils.toUtf8Bytes(signData.messageToSign.join("\n"))),
    accountAddress.toLowerCase(),
  ]);
  if (!signature) throw new Error("Wallet signature not found.");

  const variables = {
    address: accountAddress,
    signature: signature.result || signature,
    providerName,
  };
  if (username) variables.userName = username;
  try {
    const resp = await apolloClient.mutate({
      mutation: verifyLoginQuery,
      variables,
    });
    return resp.data.verifyLogin;
  } catch (error) {
    throw new Error(error.message);
  }
};

const logOut = async () => {
  try {
    const resp = await apolloClient.mutate({ mutation: logOutQuery });
    await apolloClient.resetStore();
    return resp.data.logout;
  } finally {
    setAccessToken();
  }
};

const connectMetamaskWallet = async () => {
  const provider = getMetamaskWallet();
  if (!provider)
    throw new Error("Metamask wallet is not installed in this browser.");
  const accounts = await provider.request({
    method: "eth_requestAccounts",
  });
  return { accounts };
};

const connectCoinbaseWallet = async () => {
  const provider = getCoinbaseWallet();
  if (!provider)
    throw new Error("Coinbase wallet is not installed in this browser.");
  const accounts = await provider.request({
    method: "eth_requestAccounts",
  });
  return { accounts };
};

const watchConnectedAddresses = (context) => (callback) => {
  const { providerName, user } = context;
  let provider = getWallet(user.providerName || providerName);

  if (!provider) return;
  function handleAccountsChanged(accounts = []) {
    if (!accounts.includes(context.accountAddress)) {
      callback("LOG_OUT");
    }
  }
  provider.on("accountsChanged", handleAccountsChanged);
  return () => {
    provider.removeListener("accountsChanged", handleAccountsChanged);
  };
};

const watchSyncStatus = (context) => (callback) => {
  let timeoutId;
  async function checkUser() {
    try {
      const resp = await apolloClient.query({
        query: getUserQuery,
        fetchPolicy: "network-only",
      });
      if (resp.data.getUser.user?.syncedAt) {
        callback({ type: "SYNC_COMPLETE", data: resp.data.getUser.user });
      } else {
        timeoutId = setTimeout(checkUser, 1000);
      }
    } catch (error) {
      callback("SYNC_ERROR");
    }
  }
  checkUser();
  return () => {
    clearTimeout(timeoutId);
  };
};

const initialContext = {
  user: null,
  username: null,
  connectedAddresses: null,
  authError: null,
  signData: {},
  accountAddress: null,
  providerName: null,
};

const machineConfig = {
  id: "authMachine",
  initial: "loading",
  description: "User authentication flow",
  predictableActionArguments: true,
  context: {
    ...initialContext,
  },
  states: {
    loading: {
      invoke: {
        src: "initAuth",
        id: "initAuth",
        onDone: [
          {
            description: "the user is null. (unauthenticated)",
            cond: "isNullUser",
            target: "loginScreen",
          },
          {
            description: "Check whether the user wallet is syncing.",
            cond: "isWalletSyncing",
            actions: "setUser",
            target: "syncing",
          },
          {
            description: "Check whether the username is set for this user.",
            cond: "doesUsernameExist",
            actions: "setUser",
            target: "goToHome",
          },
          {
            description:
              "user exists. however, the user does not have a username.",
            actions: "setUser",
            target: "updateUser",
          },
        ],
        onError: [
          {
            target: "loginScreen",
          },
        ],
      },
      description:
        "The initial loading state. We check the JWT token to decide whether the user is logged in.",
    },
    syncing: {
      invoke: {
        id: "watchSyncStatus",
        src: "watchSyncStatus",
      },
      initial: "syncingWallet",
      states: {
        syncingWallet: {
          after: {
            // after 1 minute, transition to stillSyncing
            60000: { target: "stillSyncing" },
          },
        },
        stillSyncing: {
          after: {
            // after 3 minutes, transition to needMoreTime
            180000: { target: "needMoreTime" },
          },
        },
        needMoreTime: {},
      },
      on: {
        SYNC_COMPLETE: [
          {
            cond: "doesUsernameExist",
            target: "goToHome",
            actions: "updateUserSyncStatus",
          },
          {
            description:
              "user exists. however, the user does not have a username.",
            actions: "updateUserSyncStatus",
            target: "updateUser",
          },
        ],
        SYNC_ERROR: {
          target: "syncError",
        },
      },
    },
    syncError: {
      description:
        "if we get sync error then the app is not usable. hard page refresh is required.",
      final: true,
    },
    goToHome: {
      invoke: {
        id: "watchConnectedAddresses",
        src: "watchConnectedAddresses",
      },
      on: {
        LOG_OUT: {
          target: "#authMachine.logOut",
        },
      },
    },
    logOut: {
      exit: "resetContext",
      invoke: {
        id: "logOut",
        src: "logOut",
        onDone: {
          target: "loginScreen",
          actions: "clearUser",
        },
        onError: {
          target: "loginScreen",
          actions: "clearUser",
        },
      },
    },
    loginScreen: {
      description: "show the provider login buttons. (metamask, coinbase etc)",
      on: {
        ON_CONNECT_REQUEST: {
          target: "connecting",
        },
        ON_BYPASS_CONNECT_REQUEST: {
          target: "loginWithAddress",
        },
      },
    },
    loginWithAddress: {
      invoke: {
        id: "loginWithAddress",
        src: "loginWithAddress",
        onDone: [
          {
            description: "Check whether the user wallet is syncing.",
            cond: "isWalletSyncing",
            actions: ["saveUser", "saveAccessToken"],
            target: "syncing",
          },
          {
            actions: ["saveUser", "saveAccessToken"],
            cond: "doesUsernameExist",
            target: "goToHome",
          },
          {
            description:
              "user exists. however, the user does not have a username.",
            actions: "setUser",
            target: "updateUser",
          },
        ],
        onError: {
          actions: "saveAuthError",
          target: "loginScreen",
        },
      },
    },
    connecting: {
      entry: ["resetAuthError", "saveProviderName"],
      invoke: {
        src: "connectWallet",
        id: "connectWallet",
        onDone: [
          {
            actions: "saveAddresses",
            cond: "hasMultipleAddresses",
            target: "selectAddress",
          },
          {
            actions: "saveAccountAddress",
            target: "verifying",
          },
        ],
        onError: [
          {
            actions: "saveAuthError",
            target: "loginScreen",
          },
        ],
      },
      description:
        "connect to the metaMask wallet and obtain the user address.",
    },
    verifying: {
      invoke: {
        src: "requestLogin",
        id: "requestLogin",
        onDone: [
          {
            description: "get the sign message from the backend.",
            cond: "isNewUser",
            actions: "saveSignMessage",
            target: "userSetup",
          },
          {
            actions: "saveSignMessage",
            target: "verifySignature",
          },
        ],
        onError: [
          {
            actions: "saveAuthError",
            target: "loginScreen",
          },
        ],
      },
    },
    userSetup: {
      description: "popup the username & T&C dialog.",
      on: {
        DONE: {
          description: "we now have a username",
          actions: "saveUsername",
          target: "verifySignature",
        },
      },
    },
    updateUser: {
      initial: "collectingUsername",
      states: {
        collectingUsername: {
          on: {
            DONE: {
              description: "we now have a username",
              actions: "saveUsername",
              target: "savingUsername",
            },
          },
        },
        savingUsername: {
          invoke: {
            src: "setUsername",
            id: "setUsername",
            onDone: {
              actions: "saveUser",
              target: "#authMachine.goToHome",
            },
            onError: {
              actions: "saveAuthError",
              target: "#authMachine.loginScreen",
            },
          },
        },
      },
      description: "popup the username dialog and save the user.",
    },
    verifySignature: {
      invoke: {
        src: "verifyLogin",
        id: "verifyLogin",
        onDone: [
          {
            description: "Check whether the user wallet is syncing.",
            cond: "isWalletSyncing",
            actions: ["saveUser", "saveAccessToken"],
            target: "syncing",
          },
          {
            actions: ["saveUser", "saveAccessToken"],
            target: "goToHome",
          },
        ],
        onError: {
          description:
            "if server returns an error, then show the error to user.",
          actions: "saveAuthError",
          target: "loginScreen",
        },
      },
      description:
        "sign the message with the private key and verify the wallet address.",
    },
    selectAddress: {
      description: "allow users to select one address.",
      on: {
        DONE: {
          actions: "saveAccountAddress",
          target: "verifying",
        },
      },
    },
  },
};

const authMachine = createMachine(machineConfig, {
  guards: {
    doesUsernameExist: (context, event) => {
      if ("user" in event.data) {
        return event.data.user.username;
      }
      return event.data?.username;
    },
    isWalletSyncing: (context, event) =>
      !event.data?.syncedAt && !event.data?.user?.syncedAt,
    isNullUser: (context, event) => event.data === null,
    isNewUser: (context, event) => event.data.newUser === true,
    hasMultipleAddresses: (context, event) =>
      (event.data.accounts || []).length > 1,
  },
  services: {
    initAuth,
    connectWallet: (context, event) => {
      if (event.provider === "Metamask") return connectMetamaskWallet();
      if (event.provider === "Coinbase") return connectCoinbaseWallet();
      throw new Error("Provider not recognized.");
    },
    loginWithAddress,
    requestLogin: (context) => {
      return requestLogin(context.accountAddress);
    },
    verifyLogin,
    logOut,
    watchConnectedAddresses,
    watchSyncStatus,
    setUsername,
  },
  actions: {
    saveProviderName: assign({
      providerName: (context, event) => event.provider,
    }),
    saveAddresses: assign({
      connectedAddresses: (context, event) => event.data.accounts || [],
    }),
    setUser: assign({
      user: (context, event) => event.data,
    }),
    saveUser: assign({
      user: (context, event) => event.data?.user,
    }),
    updateUserSyncStatus: assign({
      user: (context, event) => event.data,
    }),
    clearUser: assign({
      user: null,
      username: null,
    }),
    saveUsername: assign({
      username: (context, event) => {
        return event.username;
      },
    }),
    saveAccessToken: (context, event) => {
      setAccessToken(event.data?.token);
    },
    saveAccountAddress: assign({
      accountAddress: (context, event) => event.data.accounts[0],
    }),
    saveSignMessage: assign({
      signData: (context, event) => event.data,
    }),
    saveAuthError: assign({
      authError: (context, event) => {
        return event.data?.message || "UNKNOWN_ERROR";
      },
    }),
    resetAuthError: assign({
      authError: null,
    }),
    resetContext: assign(() => initialContext),
  },
});

export const AuthStateContext = createContext({});

export const AuthStateProvider = (props) => {
  const navigate = useNavigate();
  const authService = useInterpret(authMachine, {
    context: { navigate },
  });
  return (
    <AuthStateContext.Provider value={authService}>
      {props.children}
    </AuthStateContext.Provider>
  );
};
