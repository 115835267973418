import styled from 'styled-components';
import {
  color,
  space,
  typography,
  layout,
  position,
  shadow,
  flexbox,
  grid,
  border,
} from 'styled-system';

export const Box = styled.div`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${position}
  ${shadow}
  ${border}
`;

export const Flex = styled.div`
  display: flex;
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${position}
  ${shadow}
  ${flexbox}
  ${border}
`;
export const Grid = styled.div`
  display: grid;
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${position}
  ${shadow}
  ${grid}
  ${border}
`;

Grid.displayName = 'Grid';
Flex.displayName = 'Flex';
Box.displayName = 'Box';
