import { Box, Flex } from "../Box";
import React, { useCallback } from "react";
import { Shimmer } from "./Shimmer";

export const TableShimmer = () => {
  const numberOfShimmersPerColumn = 10;

  const renderShimmerComponent = useCallback(() => {
    return Array.from({ length: numberOfShimmersPerColumn }).map((_, index) => {
      return (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          key={index}
        >
          <Box py={2} px={2}>
            <Shimmer height="44px" width="48px" br="16" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="50px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="178px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="104px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="104px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="44px" width="91px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="44px" width="104px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="91px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="91px" br="8" />
          </Box>
          <Box py={2} px={2}>
            <Shimmer height="32px" width="91px" br="8" />
          </Box>
        </Flex>
      );
    });
  }, []);
  return <Flex flexDirection="column">{renderShimmerComponent()}</Flex>;
};
