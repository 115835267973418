import styled, { keyframes } from 'styled-components';

const shimmerKeyframeAnimation = keyframes`
  0% {
    background-color: #0F0D15;
  }
  50% {
    background-color: #1D1929;
  }
  100% {
    background-color: #0F0D15;
  }
`;

export const Shimmer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.theme.colors.gray2};
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: ${(props) => props.br}px;
  animation-name: ${shimmerKeyframeAnimation};
`;
