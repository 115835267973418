import Analytics from "../components/Analytics/Analytics";
import { Collectables } from "../components/Collectables/Collectables";
import KPIs from "../components/KPIs/KPIs";
import Banner from "../components/Banner/Banner";
import { Sidebar } from "../components/Sidebar";
import { Footer } from "../components/Footer";
import { AuthStateContext } from "../lib/auth.machine";
import { useSelector } from "@xstate/react";
import { useContext, useState } from "react";
import { setBannerPrefs as setBannerPrefsMutation } from "../graphql/prefs";
import { setUserPrefs as setUserPrefsMutation } from "../graphql/prefs";
import { addWalletAddress as addWalletMutation } from "../graphql/prefs";

import { apolloClient } from "../apollo";
import omit from "lodash/omit";
// import { TypeKind } from "graphql";

const stateSelector = (state) => ({
  user: state.context.user,
});

function MyCollection() {
  const authService = useContext(AuthStateContext);
  const { user } = useSelector(authService, stateSelector);

  // Handoff management of state for User from auth machine to here,
  // Make a deep copy to detach user from authmachine
  let userClone = JSON.parse(JSON.stringify(user));
  //console.log("My Collection, User: ", userClone);

  // User preferences, PFP image, and Banner images f
  const [pfpToken, setPFPToken] = useState(userClone?.pfpToken);
  const [bannerTokens, setBannerTokens] = useState(userClone?.bannerTokens);
  const allWallets = [
    userClone?.address,
    ...(userClone?.otherWallets || []).map((wallet) =>
      wallet.address.toLowerCase()
    ),
  ];

  // called from Gallery Item options menu
  function handlePFPTokenChange(token) {
    savePFPTokenToDB(token);
  }

  // s
  async function handleAddWalletSave(address) {
    console.log("handleAddWalletSave", address);
    return await saveWalletAddressToDB(address);
  }

  // add one, remove one
  function swapBannerTokens(token) {
    // shift the list of 3 tokens
    const updatedBannerTokens = [...bannerTokens];
    if (updatedBannerTokens.length === 3) updatedBannerTokens.shift();
    updatedBannerTokens.push({
      contract: token.contractAddress,
      tokenID: token.id,
    });
    return updatedBannerTokens;
  }

  // called from Gallery Item options menu
  function handleBannerTokensChange(token) {
    // swap in new token and update the db
    saveBannerTokensToDB(swapBannerTokens(token));
  }

  // save the new banner tokens to the DB
  const saveBannerTokensToDB = async (tokens) => {
    //console.log("MC, saveBannerTokensToDB", tokens);
    // strip out the typename prop before mutating
    const strippedTokens = tokens.map((token) => omit(token, ["__typename"]));

    try {
      const resp = await apolloClient.mutate({
        mutation: setBannerPrefsMutation,
        variables: {
          bannerTokens: strippedTokens,
        },
      });

      const savedBannerTokens = resp?.data?.setBannerPrefs?.user?.bannerTokens;
      if (savedBannerTokens) setBannerTokens(savedBannerTokens);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // save the new PFP token to the DB
  const savePFPTokenToDB = async (token) => {
    try {
      const resp = await apolloClient.mutate({
        mutation: setUserPrefsMutation,
        variables: {
          pfpToken: { contract: token.contractAddress, tokenID: token.id },
        },
      });
      const updatedPFPToken = resp?.data?.setUserPrefs?.user?.pfpToken;
      if (updatedPFPToken) setPFPToken(updatedPFPToken);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // save the new wallet address to the DB
  const saveWalletAddressToDB = async (address) => {
    try {
      const resp = await apolloClient.mutate({
        mutation: addWalletMutation,
        variables: {
          address: address,
        },
      });
      const updatedWalletAddresses =
        resp?.data?.addWalletAddress?.user?.otherWallets;
      console.log(
        "Server response Updated Wallet Adds: ",
        updatedWalletAddresses
      );
      if (updatedWalletAddresses) {
        // setAllWallets([
        //   user?.address,
        //   ...updatedWalletAddresses.map((wallet) =>
        //     wallet.address.toLowerCase()
        //   ),
        // ]);

        const newWallets = [
          user?.address,
          ...updatedWalletAddresses.map((wallet) =>
            wallet.address.toLowerCase()
          ),
        ];
        return {
          success: true,
          message: "Wallet address added",
          newWallets,
        };
      }
    } catch (error) {
      //throw new Error(error.message);
      console.log("Error adding wallet address: ", error.message);
      return { success: false, message: error.message };
    }
  };

  return (
    <Sidebar
      pfpToken={pfpToken}
      onAddWalletSave={handleAddWalletSave}
      userWallets={allWallets}
    >
      <Banner page="my collection" bannerTokens={bannerTokens} />
      <KPIs page="my collection" />
      <Analytics />
      <Collectables
        onPFPTokenChange={handlePFPTokenChange}
        onBannerTokensChange={handleBannerTokensChange}
      />
      <Footer />
    </Sidebar>
  );
}

export default MyCollection;
