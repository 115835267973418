import React, { useEffect, useRef, useState } from 'react';
import { select, selectAll, create } from 'd3-selection';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import { line, curveMonotoneX, area } from 'd3-shape';
import { scaleLinear, scaleTime } from 'd3-scale';
import { extent, max, min } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import {
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
} from 'd3-time';
import { format } from 'd3-format';
import { lightTheme } from '../../utils/theme';
import { Flex } from './../Box';
import { Delaunay } from 'd3-delaunay';
import styled, { css } from 'styled-components';
import { hexToRGBA } from '../../utils/hexToRGBA';
import { roundToNearest } from '../../utils/numbers';

const d3 = {
  select,
  selectAll,
  create,
  axisBottom,
  axisRight,
  format,
  scaleLinear,
  axisLeft,
  line,
  scaleTime,
  extent,
  max,
  min,
  Delaunay,
  curveMonotoneX,
  area,
  timeFormat,
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
};

const LineChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
`;

const StyledSvg = styled.svg`
  width: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  height: ${(props) => (props.chartWidth ? `${props.chartWidth}px` : '100%')};
  .dashed-line {
    stroke-dasharray: 6, 6;
  }
`;

const TooltipWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.gray3};
  color: inherit;
  font-size: inherit;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 12px;
  visibility: ${(props) => (props.isMobile ? 'visible' : 'hidden')};
  position: ${(props) => (props.isMobile ? 'initial' : 'absolute')};
  z-index: 999;
`;

const Pointer = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: ${(props) => props.color};
  display: ${(props) => (props.isMobile ? 'block' : 'none')};
`;

const PointWrapper = styled(Flex)`
  .point-title-wrapper {
    margin-bottom: 8px;
  }
`;

const TooltipTitle = styled.strong`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray6};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.sm}px;
`;

const TooltipSubTitle = styled.strong`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray6};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
`;

const TooltipText = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const Legend = styled.text`
  fill: ${(props) => props.theme.colors.gray7};
  font-family: ${(props) => props.theme.fonts.nunito};
  font-size: ${(props) => props.theme.fontSizes.xs}px;
`;

const Axis = styled.g`
  text {
    fill: ${(props) => props.theme.colors.gray7};
    font-family: ${(props) => props.theme.fonts.nunito};
    font-size: 10px;
  }
  line {
    stroke: ${(props) => props.theme.colors.gray2};
    stroke-width: 1px;
  }

  ${(props) =>
    props.isBottomAxis &&
    css`
      path {
        stroke: ${(props) => props.theme.colors.gray2};
        stroke-width: 1px;
      }
    `}
`;

const formatMillisecond = d3.timeFormat('.%L'),
  formatSecond = d3.timeFormat(':%S'),
  formatMinute = d3.timeFormat('%H:%M'),
  formatHour = d3.timeFormat('%H:%M'),
  formatDay = d3.timeFormat('%a %d'),
  formatWeek = d3.timeFormat('%b %d'),
  formatMonth = d3.timeFormat('%b'),
  formatYear = d3.timeFormat('%Y');

function multiFormat(date) {
  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

const withZeroPadding = (value) => (value < 10 ? `0${value}` : value);

const formatDate = (day, hourly) =>
  hourly
    ? `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
        day.getDate(),
      )}/${withZeroPadding(day.getFullYear())} ${withZeroPadding(
        day.getHours(),
      )}:${withZeroPadding(day.getMinutes())}`
    : `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
        day.getDate(),
      )}/${withZeroPadding(day.getFullYear())}`;

const addSuffixPrefix = (num, format) => {
  const unformattedNumber = num.toString().replace(/,/g, '');
  const equivalentPositiveNumber = num.toString().replace(/-/g, '');
  switch (format) {
    case 'Ξ':
      return parseFloat(unformattedNumber, 10) < 0
        ? `-${equivalentPositiveNumber}Ξ`
        : `${num}Ξ`;
    case '%':
      return `${num}%`;
    default:
      return num;
  }
};

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

function abbreviateNumber(number, format) {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return addSuffixPrefix(number, format);
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return addSuffixPrefix(roundToNearest(scaled, 2), format) + suffix;
}

const makeDivId = (str, isPrevious) => {
  const primaryString = str
    .replace(/[^\w\s]/gi, '')
    .split(' ')
    .join('-')
    .toLowerCase();

  return `${isPrevious ? 'previous-' : ''}${primaryString}`;
};

const createPoint = (accumulator, item) => [
  ...accumulator,
  ...item.data.map((dataItem) => ({
    id: item.id,
    divId: makeDivId(item.id, item.isPrevious),
    color: item.color,
    prefix: item.prefix,
    suffix: item.suffix,
    isPrevious: item.isPrevious,
    y: dataItem.y,
    x: new Date(dataItem.x),
    xValue: dataItem.xValue ? new Date(dataItem.xValue) : dataItem.xValue,
  })),
];

const getTooltipTitle = (point, format, hourly) => {
  switch (format) {
    case 'id':
      return point.id;
    case 'x':
    default:
      return point.isPrevious
        ? formatDate(point.xValue || point.x, hourly)
        : point.x instanceof Date
        ? formatDate(point.x, hourly)
        : point.x;
  }
};

const getTooltipText = (point, format, hourly) => {
  switch (format) {
    case 'idy':
      return {
        lebel: point.name || point.id,
        value: `${point.prefix || ''}${roundToNearest(point.y, 4)}${
          point.suffix || ''
        }`,
      };
    case 'xy':
    default:
      return {
        lebel: point.isPrevious
          ? formatDate(point.xValue, hourly)
          : point.x instanceof Date
          ? formatDate(point.x, hourly)
          : point.name || point.x,
        value: `${point.prefix || ''}${roundToNearest(point.y, 4)}${
          point.suffix || ''
        }`,
      };
  }
};

const clamp = (min, d, max) => {
  return Math.max(min, Math.min(max, d));
};

const StyledRect = styled.rect`
  height: ${(props) => props.heigth}px;
  width: ${(props) => props.width}px;
  fill: ${(props) => props.fill};
`;

const defaultGridOverlay = ({ width, height }) => {
  const requiredHeight = height / 3;
  return (
    <>
      <StyledRect
        width={width}
        height={requiredHeight}
        fill={lightTheme.colors.highlightSuccess}
        transform="translate(0, 0)"
      />
      <StyledRect
        width={width}
        height={requiredHeight}
        fill="white"
        transform={`translate(0, ${requiredHeight})`}
      />
      <StyledRect
        width={width}
        height={requiredHeight}
        fill={lightTheme.colors.highlightDanger}
        transform={`translate(0, ${2 * requiredHeight})`}
      />
    </>
  );
};

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const getMinMax = (data) => {
  const maxVal = Number(d3.max(data, (d) => d.y));
  const minVal = Number(d3.min(data, (d) => d.y));

  const diffRatio = (maxVal - minVal) / maxVal;

  const maxY = maxVal + (maxVal * diffRatio) / 10;
  const minY = minVal - (minVal * diffRatio) / 10;

  return [minY, maxY];
};

export const Linechart = ({
  chartId = 'test',
  width = 560,
  height = 320,
  maxHeight = 320,
  maxHeightMobile = 120,
  data = [],
  leftLegend,
  bottomLegend,
  canHighlightLine = false,
  lineStrokeWidth = 2,
  highlightedLineStrokeWidth = 3,
  showSliceTooltip = false,
  tooltipTitleFormat = 'x',
  tooltipTextFormat = 'idy',
  hasGridOverlay = false,
  gridOverlay = defaultGridOverlay,
  hideBottomAxis = false,
  hasArea = false,
  hourly = false,
  onLineClick = () => {},
}) => {
  const isMobile = useMobileScreen();
  const chartMaxHeight = isMobile ? maxHeightMobile : maxHeight;
  const axisArea = {
    top: 8,
    right: 8,
    bottom: 24,
    left: 48,
  };
  const chartAreaWidth = width - axisArea.left - axisArea.right;
  const chartAreaHeight = height - axisArea.top - axisArea.bottom;
  const svgNode = useRef(null);
  const svgNodeWrapper = useRef(null);

  useEffect(() => {
    renderChart();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const primarySimplifiedSeries = data
    .filter((item) => !item.isSecondaryMetric)
    .reduce(createPoint, []);

  const secondarySimplifiedSeries = data
    .filter((item) => item.isSecondaryMetric)
    .reduce(createPoint, []);

  const hasSomeActivePoints = data.some((item) => !item.isHidden);

  const calculateTooltipDiamension = (event) => {
    const tooltipHoverMargin = 16;
    const { offsetX: x, offsetY: y } = event;
    const { width: tooltipWidth, height: tooltipHeight } = document
      .getElementById(`${chartId}-line-tooltip`)
      .getBoundingClientRect();

    const left = clamp(
      tooltipHoverMargin,
      x - tooltipWidth / 2 + tooltipHoverMargin,
      chartAreaWidth - tooltipWidth - tooltipHoverMargin + axisArea.left,
    );

    const top =
      chartAreaHeight > y + tooltipHoverMargin + tooltipHeight
        ? y + tooltipHoverMargin
        : y - tooltipHeight - tooltipHoverMargin;

    return { left, top };
  };

  function handleMouseOverVoronoiWrapper(event) {
    const point = event.target.__data__;
    const { left, top } = calculateTooltipDiamension(event);
    const allIds = [
      ...primarySimplifiedSeries,
      ...secondarySimplifiedSeries,
    ].filter((item) => item.x.getTime() === point.x.getTime());

    d3.select(`#${chartId}-line-tooltip`)
      .style('visibility', 'visible')
      .style('top', `${top}px`)
      .style('left', `${left}px`);

    d3.select(`#${chartId}-point-wrapper .tooltip-title`).text(
      getTooltipTitle(point, tooltipTitleFormat, hourly),
    );

    if (showSliceTooltip) {
      allIds.forEach((item) => {
        d3.select(`#point-ls-${item.divId}`).style('display', 'flex');
        d3.select(`#point-ls-${item.divId} .pointer`)
          .style('background-color', item.color)
          .style('display', 'block');
        d3.select(`#point-ls-${item.divId} span.point-label`)
          .text(`${getTooltipText(item, tooltipTextFormat, hourly).lebel}:`)
          .style('display', 'block');
        d3.select(`#point-ls-${item.divId} strong.point-label`)
          .text(getTooltipText(item, tooltipTextFormat, hourly).value)
          .style('display', 'block');
      });
    } else {
      d3.select(`#point-ls-${point.divId}`).style('display', 'flex');
      d3.select(`#point-ls-${point.divId} span.point-label`)
        .text(`${getTooltipText(point, tooltipTextFormat, hourly).lebel}:`)
        .style('display', 'block');
      d3.select(`#point-ls-${point.divId} .pointer`)
        .style('background-color', point.color)
        .style('display', 'block');
      d3.select(`#point-ls-${point.divId} strong.point-label`)
        .text(getTooltipText(point, tooltipTextFormat, hourly).value)
        .style('display', 'block');
    }

    if (canHighlightLine) {
      d3.selectAll(`.${chartId}-line`)
        .attr('stroke', lightTheme.colors.gray9)
        .attr('stroke-width', lineStrokeWidth)
        .style('opacity', (d) => (d.isHidden ? '0' : '1'));

      d3.select(`#line-${point.divId}`)
        .attr('stroke', (d) => d.color)
        .attr('stroke-width', highlightedLineStrokeWidth);
    }
  }

  function handleMouseMove(event) {
    const point = event.target.__data__;
    const circleNode = d3
      .select(
        `#circle-${makeDivId(point.divId)}-${makeDivId(
          formatDate(point.x, hourly),
        )}`,
      )
      .node();

    const circleCx = circleNode.getAttribute('cx');
    const { left, top } = calculateTooltipDiamension(event);
    d3.select(
      `#circle-${makeDivId(point.divId)}-${makeDivId(
        formatDate(point.x, hourly),
      )}`,
    ).style('opacity', '1');
    d3.select(`#${chartId}-line-tooltip`)
      .style('top', `${top}px`)
      .style('left', `${left}px`);

    d3.select(`#${chartId}-crosshair`)
      .attr('transform', `translate(${circleCx}, 0)`)
      .attr('opacity', 1);
  }

  const handleMouseOut = (event) => {
    const point = event.target.__data__;
    if (!showSliceTooltip) {
      d3.selectAll('.point-ls').style('display', 'none');
    }
    d3.select(
      `#circle-${makeDivId(point.divId)}-${makeDivId(
        formatDate(point.x, hourly),
      )}`,
    ).style('opacity', '0');
    d3.select(`#${chartId}-line-tooltip`).style('visibility', 'hidden');
    d3.selectAll(`.${chartId}-line`)
      .attr('stroke', (d) => d.color)
      .attr('stroke-width', lineStrokeWidth)
      .style('opacity', (d) => (d.isHidden ? '0' : '1'));

    d3.select(`#${chartId}-crosshair`).attr('opacity', 0);

    d3.select(`#${chartId}-crosshair-point`).attr('opacity', 0);
  };

  const renderChart = () => {
    const svgObj = d3
      .select(svgNode.current)
      .select(`#${chartId}-chart-wrapper`);
    const x = d3
      .scaleTime()
      .domain(d3.extent(primarySimplifiedSeries, (d) => d.x))
      .range([0, chartAreaWidth]);

    const primaryAxisData =
      primarySimplifiedSeries && primarySimplifiedSeries.length
        ? primarySimplifiedSeries
        : [];

    const y = d3
      .scaleLinear()
      .domain(getMinMax(primaryAxisData))
      .range([chartAreaHeight, 0]);

    if (!hideBottomAxis) {
      svgObj
        .select('#bottom-axis')
        .attr('stroke-width', 0)
        .attr('transform', `translate(0, ${chartAreaHeight})`)
        .call(
          d3
            .axisBottom(x)
            .tickPadding(12)
            .ticks(8)
            .tickSize(0)
            .tickFormat((d) => multiFormat(d)),
        );
    }

    svgObj
      .select('#left-axis')
      .attr('stroke-width', 0)
      .call(
        d3
          .axisLeft(y)
          .tickSize(-chartAreaWidth)
          .tickPadding(8)
          .tickFormat((d) => {
            const [samplePoint] = primarySimplifiedSeries;
            return abbreviateNumber(
              d,
              samplePoint.prefix || samplePoint.suffix,
            );
          })
          .ticks(8),
      );

    svgObj
      .selectAll(`.${chartId}-line`)
      .data(data)
      .attr('d', (d) =>
        d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((i) => x(new Date(i.x)))
          .y((i) => y(+i.y))(d.data),
      );

    svgObj
      .selectAll(`.${chartId}-circle-point`)
      .data(primarySimplifiedSeries)
      .attr('cx', (d) => x(new Date(d.x)))
      .attr('cy', (d) => y(d.y));

    const voronoi = d3.Delaunay.from(
      primarySimplifiedSeries,
      (d) => x(d.x),
      (d) => y(d.y),
    ).voronoi([0, 0, chartAreaWidth, chartAreaHeight]);

    svgObj
      .select(`#${chartId}-voronoi-wrapper`)
      .selectAll('path')
      .data(primarySimplifiedSeries)
      .attr('opacity', 0.5)
      // .attr('stroke', 'pink')
      .attr('fill', 'none')
      .style('pointer-events', 'all')
      .attr('d', (d, i) => voronoi.renderCell(i))
      .on('mouseover', handleMouseOverVoronoiWrapper)
      .on('mousemove', handleMouseMove)
      .on('mouseout', handleMouseOut)
      .on('click', function (event) {
        const point = event.target.__data__;
        onLineClick(point);
      });
  };

  // console.log(data);

  return (
    <LineChartWrapper ref={svgNodeWrapper} maxHeight={chartMaxHeight}>
      <StyledSvg ref={svgNode} viewBox={`0 0 ${width} ${height}`}>
        <g
          id={`${chartId}-chart-wrapper`}
          transform={`translate(${axisArea.left},${axisArea.top})`}
          className="chart-wrapper"
        >
          {hasGridOverlay && (
            <g
              width={chartAreaWidth}
              height={chartAreaHeight}
              id={`${chartId}-overlay-wrapper`}
            >
              {gridOverlay({
                width: chartAreaWidth,
                height: chartAreaHeight,
              })}
            </g>
          )}
          {!isMobile && (
            <>
              <Axis id="bottom-axis" isBottomAxis />
              <Axis id="left-axis" />
              <Axis id="right-axis" />
            </>
          )}
          {hasSomeActivePoints && (
            <line
              id={`${chartId}-crosshair`}
              className="crosshair"
              opacity="0"
              stroke={isMobile ? '#3b4754' : '#B8C2CC'}
              strokeWidth={isMobile ? '2' : '1'}
              y1="0"
              y2={chartAreaHeight}
            />
          )}
          {data.map((item) => (
            <path
              key={`line-${makeDivId(item.id, item.isPrevious)}`}
              id={`line-${makeDivId(item.id, item.isPrevious)}`}
              className={`${chartId}-line ${
                item.isPrevious ? 'dashed-line' : ''
              }`}
              fill={hasArea ? hexToRGBA(item.color, 0.4) : 'none'}
              stroke={item.color}
              strokeWidth={item.strokeWidth || lineStrokeWidth}
              style={{ opacity: item.isHidden ? 0 : 1 }}
            />
          ))}
          {primarySimplifiedSeries.map((item) => (
            <circle
              key={`${makeDivId(item.divId)}-${item.x.getTime()}}`}
              id={`circle-${makeDivId(item.divId)}-${makeDivId(
                formatDate(item.x, hourly),
              )}`}
              r="4"
              fill="#0F0D15"
              strokeWidth={2}
              stroke={item.color}
              className={`${chartId}-circle-point`}
              opacity="0"
            />
          ))}
          {bottomLegend && !isMobile && (
            <Legend
              textAnchor="middle"
              fontSize="14"
              x={chartAreaWidth / 2}
              y={chartAreaHeight + 52}
            >
              {bottomLegend}
            </Legend>
          )}
          {leftLegend && !isMobile && (
            <Legend
              textAnchor="end"
              fontSize="14"
              x={-chartAreaHeight / 2 + 52}
              y={-axisArea.left + 16}
              transform="rotate(-90)"
            >
              {leftLegend}
            </Legend>
          )}
          {hasSomeActivePoints && isMobile && (
            <circle
              r="12"
              fill="#3b4754"
              stroke="#ecf0f3"
              strokeWidth="12"
              opacity="0"
              id={`${chartId}-crosshair-point`}
            />
          )}
          <g id={`${chartId}-voronoi-wrapper`}>
            {primarySimplifiedSeries.map((item) => (
              <path key={`${makeDivId(item.divId)}-${item.x.getTime()}}`} />
            ))}
          </g>
        </g>
      </StyledSvg>
      {hasSomeActivePoints && (
        <TooltipWrapper
          isMobile={isMobile}
          id={`${chartId}-line-tooltip`}
          flexDirection="column"
        >
          <PointWrapper
            id={`${chartId}-point-wrapper`}
            flexDirection="column"
            alignItems="center"
          >
            <TooltipTitle
              className="point-title-wrapper"
              id={`${chartId}-point-title-wrapper`}
            >
              <TooltipSubTitle className="tooltip-title">--</TooltipSubTitle>
            </TooltipTitle>
            {data.map((item) => (
              <Flex
                key={`tooltip-item-${makeDivId(item.id, item.isPrevious)}`}
                id={`point-ls-${makeDivId(item.id, item.isPrevious)}`}
                className="point-ls"
                mb="1"
              >
                <Flex marginRight="2" flexDirection="row" alignItems="center">
                  <Pointer
                    className="pointer"
                    isMobile={isMobile}
                    color={item.color}
                  />
                  <TooltipText className="point-label">{item.id}</TooltipText>
                </Flex>
                <TooltipTitle className="point-label" color="white">
                  --
                </TooltipTitle>
              </Flex>
            ))}
          </PointWrapper>
        </TooltipWrapper>
      )}
    </LineChartWrapper>
  );
};
