import React, { useState, useLayoutEffect, Fragment } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { Flex } from "../Box";
import { ChevronLgLeft, ChevronLgRight } from "../Icons";
// import { AuthStateContext } from "../../lib/auth.machine";
// import { useSelector } from "@xstate/react";
import { Menu, Transition } from "@headlessui/react";

const StyledPaginateContainer = styled(Flex)`
  margin-top: 8px;
  .table-pagination {
    display: flex;
    flex-direction: row;
    padding-left: 14px;
    align-items: center;
  }
  .page {
    margin: 0 4px;
    list-style: none;
    > a {
      border-radius: 2px;
      font-size: 14px;
      font-weight: 700;
      outline: none;
      font-family: ${(props) => props.theme.fonts.nunito};
      padding: 3px 8px;
      background: initial;
      color: ${(props) => props.theme.colors.gray10};
      &:hover {
        color: white;
      }
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &.active {
      > a {
        background: ${(props) => props.theme.colors.gray1};
        color: ${(props) => props.theme.colors.blue6};
      }
    }
    &.break-page {
      > a {
        background: initial;
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const PaginationTitle = styled.strong`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  font-family: ${(props) => props.theme.fonts.nunito};
  background: initial;
  color: ${(props) => props.theme.colors.gray9};
`;

// const stateSelector = (state) => ({
//   user: state.context.user,
// });

export function ImageGallery({
  hasPagination = true,
  isCollection = false,
  canChangePageSize,
  // pageCount,
  itemsPerPage,
  onItemClick,
  data,
  onPFPTokenChange,
  onBannerTokensChange,
}) {
  // const authService = useContext(AuthStateContext);
  // const { user } = useSelector(authService, stateSelector);

  // We start with an empty list of items. /s
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  // User clicked Add To PFP, event passed to My Collection
  const handleUpdatePFPClick = (token) => {
    onPFPTokenChange(token);
  };

  // User clicked Add To Banner, event passed to My Collection
  const handleUpdateBannerClick = (token) => {
    onBannerTokensChange(token);
  };

  // useEffect(() => {
  //   console.log("Image Gallery Render");
  // });

  useLayoutEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="flex flex-col">
      {/* <h1 className="text-white text-2xl font-extrabold">Collection Items</h1> */}
      <div className="mt-5 w-full">
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 mb-5 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {currentItems &&
            currentItems.map((item) => (
              <li
                key={item?.image}
                className="relative pb-2 rounded-lg bg-gray-1100"
              >
                <div className="group block w-full aspect-w-10 aspect-h-10 rounded-t-lg bg-gray-900 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src={item?.image}
                    alt=""
                    className="object-cover pointer-events-none group-hover:scale-150 transition-transform duration-500"
                  />
                  <button
                    type="button"
                    className="absolute inset-0 focus:outline-none"
                    onClick={() => {
                      onItemClick(item);
                    }}
                  >
                    <span className="sr-only">
                      View details for{" "}
                      {isCollection ? item?.name : item?.collection?.name}
                    </span>
                  </button>
                </div>
                <div className="flex flex-row items-end px-4">
                  <div className="w-10/12">
                    <p className="mt-2 block text-sm font-bold text-white truncate pointer-events-none">
                      {isCollection ? item?.name?.[0] : item?.collection?.[1]}
                    </p>
                    <p className="block text-sm text-gray-200 truncate pointer-events-none">
                      {isCollection ? "" : "#" + item?.id}
                    </p>
                    {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                  Purchase price
                </p> */}
                    <strong className="text-gray-300 text-sm font-semibold">
                      {isCollection
                        ? "Floor: " + item?.floor
                        : item.type + " for: " + item?.purchasePrice?.[1]}
                      Ξ
                    </strong>
                  </div>
                  {!isCollection && (
                    <Menu as="div" className="relative">
                      <Menu.Button
                        className="rounded-xl bg-gray-1100 p-1 h-min hover:bg-gray-1000 transition"
                        onClick={() => {
                          //gaEventTracker("click", "Activate-Asset-Options-Menu");
                        }}
                      >
                        <span className="sr-only">Open asset menu</span>
                        <span className="sr-only">Image options</span>
                        <div className="flex items-center pointer-events-none z-10">
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 15 15"
                            stroke="#d2d1d4"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3 7.5C3 7.77614 2.77614 8 2.5 8C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7C2.77614 7 3 7.22386 3 7.5Z" />
                            <path d="M8 7.5C8 7.77614 7.77614 8 7.5 8C7.22386 8 7 7.77614 7 7.5C7 7.22386 7.22386 7 7.5 7C7.77614 7 8 7.22386 8 7.5Z" />
                            <path d="M13 7.5C13 7.77614 12.7761 8 12.5 8C12.2239 8 12 7.77614 12 7.5C12 7.22386 12.2239 7 12.5 7C12.7761 7 13 7.22386 13 7.5Z" />
                          </svg>
                        </div>
                      </Menu.Button>
                      {/* <Menu.Button
                      className="flex items-center"
                      onClick={() => {
                        //gaEventTracker("click", "Activate-Asset-Options-Menu");
                      }}
                    >
                      <span className="sr-only">Open asset menu</span>
                      <h1>Open Menu</h1>
                    </Menu.Button> */}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div className="absolute z-10 flex flex-col justify-center w-48 p-6 mt-2 origin-top-right border border-gray-700 shadow-lg right-0 -top-36 rounded-xl bg-gray-1200 focus:outline-none">
                          <button
                            type="button"
                            className="px-4 py-2 text-sm font-semibold text-center text-white transition border border-transparent rounded-xl shadow-sm bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-1000"
                            onClick={() => {
                              //authService.send("LOG_OUT");
                              // gaEventTracker(
                              //   "click",
                              //   "Asset-Options-Menu-Set-PFP"
                              // );
                              handleUpdatePFPClick(item);
                            }}
                          >
                            Set as PFP
                          </button>
                          <button
                            type="button"
                            className="px-4 py-2 mt-4 text-sm font-semibold text-center text-white transition border border-transparent rounded-xl shadow-sm bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-1000"
                            onClick={() => {
                              //authService.send("LOG_OUT");
                              // gaEventTracker(
                              //   "click",
                              //   "Asset-Options-Menu-Set-Banner"
                              // );
                              handleUpdateBannerClick(item);
                            }}
                          >
                            Add to banner
                          </button>
                        </div>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </li>
            ))}
        </ul>
        {hasPagination && (
          <StyledPaginateContainer
            flexDirection="row"
            justifyContent={canChangePageSize ? "space-between" : "flex-end"}
            alignItems="center"
            borderTop="1px solid gray"
            paddingTop="10px"
            mb={3}
          >
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <PaginationTitle>
                Showing {itemOffset + 1} to{" "}
                {Math.min(
                  itemOffset + itemsPerPage,
                  itemOffset + currentItems?.length
                ) || 0}{" "}
                of {data.length} items
              </PaginationTitle>
              <ReactPaginate
                pageClassName="page"
                nextClassName="page"
                previousClassName="page"
                previousLabel={
                  <ChevronLgLeft color="gray11" size="12" viewBx="15" />
                }
                nextLabel={
                  <ChevronLgRight color="gray11" size="12" viewBx="15" />
                }
                breakLabel="..."
                breakClassName="page break-page"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                // onPageChange={(data) => gotoPage(data.selected)}
                onPageChange={handlePageClick}
                containerClassName="table-pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </Flex>
          </StyledPaginateContainer>
        )}
      </div>
    </div>
  );
}
