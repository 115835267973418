import {useContext} from "react";
import {useSelector} from "@xstate/react";
import {AuthStateContext} from "../lib/auth.machine";
import {useLocation, Navigate} from "react-router-dom";

const stateSelector = (state) => ({
  loading: state.matches("loading"),
  loggingOut: state.matches("logOut"),
  user: state.context.user,
});

export function RequireAuth({children}) {
  const authService = useContext(AuthStateContext);
  const {loading, user, loggingOut} = useSelector(authService, stateSelector);
  let location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!user || loggingOut) {
    if (location.pathname === "/login") {
      return children;
    }
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return children;
}
