import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size[0]}px;
  height: ${(props) => props.size[1]}px;
`;

export const BarChartIcon = withTheme(({ size = [15,16], ...props }) => {
  return (
    <StyledSVG
      size={size}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 15H15M5.5 12.5V6.5M9.5 12.5V3.5M13.5 12.5V0.5M1.5 9.5V12.5'
        stroke="#A5A3A9"
      />
    </StyledSVG>
  );
});

BarChartIcon.displayName = 'BarChartIcon';
