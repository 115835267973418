import { CollectionItems } from '../components/CollectionItems/CollectionItems';
import KPIs from '../components/KPIs/KPIs';
import OverviewCharts from '../components/OverviewCharts/OverviewCharts';
import Banner from '../components/Banner/Banner';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';

function Overview() {
  return (
    <Sidebar>
      <Banner page="overview" />
      <KPIs page="overview" />
      <OverviewCharts />
      {/* <Analytics /> */}
      <CollectionItems />
      <Footer />
    </Sidebar>
  );
}

export default Overview;
