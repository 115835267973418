import { useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Flex } from "../Box";
import styled from "styled-components";
import { getCollection, getTokens } from "../../graphql/stats";
import { useParams } from "react-router-dom";
import { getUser } from "../../graphql/stats";
import { IconWrapper, OpenSea, Twitter, Globe, Discord } from "../Icons";
import { useSelector } from "@xstate/react";
import { AuthStateContext } from "../../lib/auth.machine";
import { resizeImgURL } from "../../utils/URL";
// import { useState } from 'react';
// import { LoginIcon } from '@heroicons/react/outline';

const LinkMenuWrapper = styled(Flex)`
  height: fit-content;
`;

const LinkMenu = styled(Flex)`
  background: rgba(15, 13, 21, 0.5);
  padding: 0.6rem;
  border-radius: 0.5rem;
  flex-directiom: row;
  gap: 1rem;
`;

// const ImageWrapper = styled(Flex)`
// background-position: ${(props) => props.backGroundCoords}
// background-repeat: no-repeat;
// ${(props) => props.backGround}
//   border-radius: 12px;
// `;

const GradientDiv = styled.div`
  position: absolute;
  left: 0;
  z-index: 10;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    rgba(15, 13, 21, 1) 41%,
    rgba(15, 13, 21, 0.9) 43%,
    rgba(15, 13, 21, 0.8) 45%,
    rgba(15, 13, 21, 0.7) 47%,
    rgba(15, 13, 21, 0.6) 49%,
    rgba(15, 13, 21, 0.5) 51%,
    rgba(15, 13, 21, 0.4) 53%,
    rgba(15, 13, 21, 0.3) 55%,
    rgba(15, 13, 21, 0.2) 57%,
    rgba(15, 13, 21, 0.1) 59%,
    rgba(15, 13, 21, 0) 61%
  );
  width: 1200px;
  height: 250px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 232px;
`;

const Container = styled.div`
  position: relative;
  top: -250px;
  z-index: 15;
  display: flex;
  flex-direction: row;
  height: 232px;
  width: 100%;
  padding: 16px;
  color: white;
  border-radius: 10px;
  border-radius: 10px;
  justify-content: space-between;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0;
  justify-content: space-evenly;
  height: 200px;
`;

const HeaderWrapper = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  width: 100%;
`;

const Header = styled.div`
  margin-left: 8px;
  padding-top: 0px;
`;

const StyledHead = styled.h1`
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 34px;
  line-height: 1.2;
  letter-spacing: 0.02em;
`;

const HeaderP = styled.p`
  line-height: 22px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.gray6};
`;

const Case = styled.span`
  color: ${(props) => props.theme.colors.pink5};
`;

const Grid = styled.div`
  display: flex;
  padding-top: 30px;
  margin-left: 8px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 48px;
  padding: 0px;
  background-color: ${(props) => props.theme.colors.gray2};
  border-radius: 10px;
  margin-left: 8px;
  &:first-child {
    margin-left: 0px;
  }
  .bold {
    font-weight: 700;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray10};
    line-height: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
    letter-spacing: 0.02em;
  }
  .sub {
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    color: ${(props) => props.theme.colors.gray6};
  }
`;

const withZeroPadding = (value) => (value < 10 ? `0${value}` : value);

const formatDate = (day) =>
  `${withZeroPadding(day.getMonth() + 1)}/${withZeroPadding(
    day.getDate()
  )}/${withZeroPadding(day.getFullYear())}`;

const stateSelector = (state) => ({
  user: state.context.user,
});

export default function Banner(props) {
  const authService = useContext(AuthStateContext);
  const { user } = useSelector(authService, stateSelector);
  const params = useParams();
  var currentAPI;
  let currentVars = { variables: "" };
  let openSeaURL;

  // useEffect(() => {
  //   console.log("Banner Render");
  // });

  // console.log("Banner: bannertokens at render", props.bannerTokens);

  // useEffect(() => {
  //   console.log("Banner: bannerTokens changed: ", props.bannerTokens);
  // }, [props.bannerTokens]);

  if (props.page === "my collection") {
    currentAPI = getUser;
  } else {
    currentAPI = getCollection;
    currentVars.variables = { collectionSlug: params.collection };
    openSeaURL = "https://opensea.io/collection/" + params?.collection;
  }

  const {
    loading,
    error,
    data = {},
  } = useQuery(currentAPI, {
    ...currentVars,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  }) || {};
  const bannerData = data[Object.keys(data)[0]]; //<- best way I could come up w/ to deal with dynamic (conditional) currentAPI (because of no conditional react hook rule)

  // fetch tokens from user wallet to create banner image, query skips if not My Collection
  const { data: { getTokens: getTokensData = {} } = {} } = useQuery(getTokens, {
    skip: props?.page !== "my collection",
  });
  const tokensData = getTokensData?.tokens || [];
  let bannerImageURL;
  let bannerHeader;
  let bannerBody;
  let bannerStatOne;
  let bannerStatTwo;
  let bannerStatThree;
  let bannerStatFour;
  let bannerLabelOne;
  let bannerLabelTwo;
  let bannerLabelThree;
  let bannerLabelFour;
  let collectionWebsiteURL;
  let twitterURL;
  let discordURL;

  if (props?.page === "my collection") {
    const firstJoinDate = bannerData?.user?.createdAt
      ? new Date(bannerData?.user?.createdAt)
      : 0;
    const firstBuyDate = bannerData?.firstBuy
      ? new Date(bannerData?.firstBuy)
      : 0;
    const lastBuyDate = bannerData?.lastBuy ? new Date(bannerData?.lastBuy) : 0;
    const lastSaleDate = bannerData?.lastSale
      ? new Date(bannerData?.lastSale)
      : 0;

    const dateStrings = {
      joinedNC:
        firstJoinDate.toLocaleString("default", { month: "short" }) +
        "·" +
        firstJoinDate.toLocaleString("default", { day: "2-digit" }) +
        "·" +
        firstJoinDate.toLocaleString("default", { year: "2-digit" }),
      firstBuy:
        firstBuyDate.toLocaleString("default", { month: "short" }) +
        "·" +
        firstBuyDate.toLocaleString("default", { day: "2-digit" }) +
        "·" +
        firstBuyDate.toLocaleString("default", { year: "2-digit" }),
      lastBuy:
        lastBuyDate.toLocaleString("default", { month: "short" }) +
        "·" +
        lastBuyDate.toLocaleString("default", { day: "2-digit" }) +
        "·" +
        lastBuyDate.toLocaleString("default", { year: "2-digit" }),
      lastSale:
        lastSaleDate.toLocaleString("default", { month: "short" }) +
        "·" +
        lastSaleDate.toLocaleString("default", { day: "2-digit" }) +
        "·" +
        lastSaleDate.toLocaleString("default", { year: "2-digit" }),
    };

    bannerImageURL =
      bannerData?.bannerImageUrl || "https://picsum.photos/3396/696";
    //bannerImageURL = bannerData?.bannerImageUrl
    bannerHeader = user?.username;
    bannerBody =
      "This is your home base for viewing and analyzing\nyour NFT collection in one place.";
    bannerStatOne = dateStrings?.joinedNC || "error";
    bannerStatTwo = dateStrings?.firstBuy || "error";
    bannerStatThree = dateStrings?.lastBuy || "error";
    bannerStatFour = dateStrings?.lastSale || "error";
    bannerLabelOne = "Joined App";
    bannerLabelTwo = "First Buy";
    bannerLabelThree = "Last Buy";
    bannerLabelFour = "Last Sale";
  } else {
    bannerImageURL =
      bannerData?.bannerImageUrl ||
      "https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80";
    //bannerImageURL = bannerData?.bannerImageUrl;
    console.log(bannerImageURL);
    bannerHeader = bannerData?.name;
    bannerBody =
      bannerData?.description?.slice(0, 100) || "Please try another collection";
    // bannerBody = bannerData?.description?.slice(0, 100);
    bannerStatOne = bannerData?.createdAt
      ? formatDate(new Date(bannerData?.createdAt))
      : "--";
    bannerStatTwo = bannerData?.totalTokens
      ? Number(bannerData?.totalTokens).toLocaleString()
      : "--";
    bannerStatThree = bannerData?.numOwners
      ? Number(bannerData?.numOwners).toLocaleString()
      : "--";
    bannerStatFour = bannerData?.royalty
      ? bannerData?.royalty / 100 + "%"
      : "--";
    bannerLabelOne = "Launched";
    bannerLabelTwo = "Items";
    bannerLabelThree = "NC Owners";
    bannerLabelFour = "Royalty";
    collectionWebsiteURL = bannerData?.externalUrl;
    twitterURL = "https://www.google.com/";
    discordURL = "https://www.google.com/";
  }

  function randomArrayShuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  function moveFirstItemToBack(arr) {
    let element = arr.splice(0, 1)[0];
    arr.splice(arr.length, 0, element);
  }

  // forces the width on NFT images to 250px
  // function updateImgURL(imageURL = "") {
  //   //console.log(imageURL);
  //   if (!imageURL) return "";
  //   let urlObj = new URL(imageURL);
  //   switch (urlObj.hostname) {
  //     case "res.cloudinary.com":
  //       return imageURL.replace("/upload/", "/upload/w_250/");
  //     case "i.seadn.io":
  //       return imageURL.replace("w=500", "w=250");
  //     case "nft-cdn.alchemy.com":
  //       const newURL =
  //         "https://res.cloudinary.com/alchemyapi/image/upload/thumbnail/eth-mainnet/HASH";
  //       const hashfromURL = imageURL.split("/").pop(); //todo could check length to confirm hash is present
  //       return newURL.replace("HASH", hashfromURL);

  //     default:
  //       return imageURL;
  //   }

  //   //return imageURL.replace("=s", "=w");
  // }

  let imageData = tokensData.map((item) => item?.image);

  function getBannerImages(isMyCollection) {
    if (isMyCollection) {
      // Pick three token images from wallet at random
      // to display, (repeats if wallet has < 3 as well)
      let shuffledImageURLs = [];
      let dataToShuffle = imageData;

      // We don't want to use images hosted on IPFS (or other rando hosts) if possible
      const safeImageData = imageData.filter((imageURL) => {
        if (!imageURL) return false;
        let urlObj = new URL(imageURL);
        return (
          urlObj.hostname === "res.cloudinary.com" ||
          urlObj.hostname === "lh3.googleusercontent.com" ||
          urlObj.hostname === "i.seadn.io" ||
          urlObj.hostname === "nft-cdn.alchemy.com"
        );
      });

      // todo is this needed?
      if (safeImageData.length) {
        dataToShuffle = safeImageData;
      }

      // shuffle the list of safe images
      let imageDataKeys = Object.keys(dataToShuffle);
      randomArrayShuffle(imageDataKeys);
      imageDataKeys.map((key) => shuffledImageURLs.push(dataToShuffle[key]));

      // deals w/ < 3 tokens in wallet
      let imageURLs = [shuffledImageURLs[0]];
      moveFirstItemToBack(shuffledImageURLs);
      imageURLs.push(shuffledImageURLs[0]);
      moveFirstItemToBack(shuffledImageURLs);
      imageURLs.push(shuffledImageURLs[0]);

      // replace random pics with user prefs, if present
      let i = 0;
      for (const prefToken of props.bannerTokens) {
        let ourTokenData = tokensData.find(
          (token) =>
            token?.contractAddress?.toLowerCase() ===
              prefToken?.contract?.toLowerCase() &&
            token?.tokenId === prefToken?.tokenID
        );
        if (ourTokenData) {
          //console.log("Banner: found a token in prefs: ", ourTokenData?.image);
          imageURLs[i] = ourTokenData?.image;
        }
        i++;
      }
      //console.log("Banner: imageURLs:", imageURLs);

      return imageURLs;
    } else {
      // // not My Collection
      // backGroundCoords = "0px 0px, 0px 0px;";
      // bannerBgCSS =
      //   "background: " +
      //   bgGradientCSS +
      //   'url("' +
      //   bannerImageURL +
      //   '") center center / cover;';
    }

    //return { bgImg: bannerBgCSS, bgPos: backGroundCoords };
  }

  const bannerImageURLS = useMemo(
    () => getBannerImages(props.page === "my collection"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokensData, props.bannerTokens]
  );

  if (error) {
    console.log(error);
  }

  if (!loading) {
    return (
      <Wrapper>
        {/* <ImageWrapper
          flexDirection="row"
          backGround={getBannerBgImgCSS(props.page === "my collection").bgImg}
          backGroundCoords={
            getBannerBgImgCSS(props.page === "my collection").bgPos
          }
          justifyContent="flex-start"
        > */}
        <div className="text-white flex flex-row relative">
          <GradientDiv></GradientDiv>
          <div className="relative left-[456px] flex flex-row">
            <img
              src={resizeImgURL(bannerImageURLS[0])}
              width="250"
              className=""
              alt=""
            />
            <img
              src={resizeImgURL(bannerImageURLS[1])}
              width="250"
              className=""
              alt=""
            />
            <img
              src={resizeImgURL(bannerImageURLS[2])}
              width="250"
              className=""
              alt=""
            />
          </div>
        </div>
        <Container>
          <Container2>
            <HeaderWrapper>
              <Header>
                {props.page === "my collection" ? (
                  <StyledHead>
                    <Case>{bannerHeader}'s</Case> Collection
                  </StyledHead>
                ) : (
                  <StyledHead>
                    {bannerHeader && bannerHeader !== "undefined" ? (
                      <>
                        <Case>{bannerHeader} </Case> Overview
                      </>
                    ) : (
                      <>
                        <Case>Collection</Case> not found.
                      </>
                    )}
                  </StyledHead>
                  // <StyledHead>
                  //   <Case>{bannerHeader} </Case> Overview
                  // </StyledHead>
                )}
                <HeaderP>{bannerBody}</HeaderP>
              </Header>
            </HeaderWrapper>
            <Grid>
              <Card>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span className="bold">{bannerStatOne}</span>
                  <span className="sub">{bannerLabelOne}</span>
                </Flex>
              </Card>
              <Card>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span className="bold">{bannerStatTwo}</span>
                  <span className="sub">{bannerLabelTwo}</span>
                </Flex>
              </Card>
              <Card>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span className="bold">{bannerStatThree}</span>
                  <span className="sub">{bannerLabelThree}</span>
                </Flex>
              </Card>
              <Card>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span className="bold">{bannerStatFour}</span>
                  <span className="sub">{bannerLabelFour}</span>
                </Flex>
              </Card>
            </Grid>
          </Container2>

          {props.page !== "my collection" && (
            <LinkMenuWrapper>
              <LinkMenu>
                <a href={openSeaURL} target="_blank" rel="noreferrer">
                  <OpenSea
                    color2="#77757f"
                    className="transition hover:fill-blue-900 fill-gray-100"
                  />
                </a>
                <a href={collectionWebsiteURL} target="_blank" rel="noreferrer">
                  <Globe
                    color2="#77757f"
                    className="transition hover:fill-blue-900 fill-gray-100"
                  />
                </a>
                <a href={twitterURL} target="_blank" rel="noreferrer">
                  <IconWrapper size="15" color="gray11" hoverColor="blue4">
                    <Twitter />
                  </IconWrapper>
                </a>
                <a href={discordURL} target="_blank" rel="noreferrer">
                  <IconWrapper size="15" color="gray11" hoverColor="blue4">
                    <Discord />
                  </IconWrapper>
                </a>
              </LinkMenu>
            </LinkMenuWrapper>
          )}
        </Container>
        {/* </ImageWrapper> */}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Container>
          <Header>
            <StyledHead></StyledHead>
            <HeaderP></HeaderP>
          </Header>
        </Container>
      </Wrapper>
    );
  }
}
