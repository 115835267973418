import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { BarChart, Linechart, ScatterPlot } from '../Charts';
import {
  getCollectionFloorStats,
  getCollectionListingStats,
  getCollectionUniqueOwnersStats,
  getSellWall,
  getTransactions,
} from '../../graphql/stats';
import { formatETH } from '../../utils/eth';
import ChartLoader from '../Shimmer/ChartLoader';
import { Flex } from '../Box';
import { roundToNearest } from '../../utils/numbers';
// import {
//   generateFloorPriceData,
//   generateListingPriceData,
// } from '../../utils/mockData';

//['1H', '1D', '1W', '1M', '1Y', 'ALL'];
const filterList = ['1H', '1D', '1W', '1M', '1Y'];

export const strToHour = {
  '1H': 1,
  '1D': 24,
  '1W': 168,
  '1M': 744,
  '1Y': 8760,
  ALL: 87600,
};

export const strToMinute = {
  '1H': 60,
  '1D': 1440,
  '1W': 10080,
  '1M': 43200,
  '1Y': 525600,
  ALL: 5256000,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Pointer = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  display: block;
`;

const listingChartsPoints = ['floorPrice']; //'avgPrice', 
const floorSalesVolumPoints = ['floorPrice', 'salesVolume'];

function* createChunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export default function OverviewCharts() {
  const params = useParams();
  const [selectedTime, setSelectedTime] = useState(filterList[2]);
  const [selectedFloorPricesTime, setSelectedFloorPricesTime] = useState(
    filterList[2],
  );
  const [selectedUniqueOwnersTime, setSelectedUniqueOwnersTime] = useState(
    filterList[2],
  );
  const [selectedTransactionsTime, setSelectedTransactionsTime] = useState(
    filterList[1],
  );
  const {
    data: { getSaleWall: getSellWallData = {} } = {},
    loading: sellWallLoading,
  } = useQuery(getSellWall, {
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: { getCollectionStats: getCollectionListingStatsData = {} } = {},
    loading: collectionListingLoading,
    refetch: refetchListingPrices,
  } = useQuery(getCollectionListingStats, {
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: { getCollectionStats: getCollectionFloorStatsData = {} } = {},
    loading: floorPriceLoading,
    refetch: refetchFloorPrices,
  } = useQuery(getCollectionFloorStats, {
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: { getCollectionStats: getCollectionUniqueOwnersStatsData = {} } = {},
    loading: uniqueOwnersLoading,
    refetch: refetchUniqueOwners,
  } = useQuery(getCollectionUniqueOwnersStats, {
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: { getTransactions: getTransactionsData = [] } = {},
    loading: transactionLoading,
    refetch: refetchTransactions,
  } = useQuery(getTransactions, {
    variables: { collectionSlug: params.collection },
    notifyOnNetworkStatusChange: true,
  });

  const saleWallData = getSellWallData?.groups || [];
  const listingStatsData = getCollectionListingStatsData?.stats || [];
  const floorStatsData = getCollectionFloorStatsData?.stats || [];
  const uniqueOwnersStatsData = getCollectionUniqueOwnersStatsData?.stats || [];
  const transactionData = getTransactionsData || [];

  const reduceListingStatsData = (listingStatsData = []) => {
    console.log(listingStatsData);
    const reducedListingStatsData = listingStatsData
      ?.filter((item) => listingChartsPoints.includes(item.statName))
      .reduce((acc, curr) => {
        let lineColor = '';
        let lineName = '';
        const simplifiedValues = curr.values?.map((item) => ({
          x: item.time,
          y: formatETH(item.value),
        }));

        //this is removing errors in data during development
        // todo this should be removed once data is stable
        const zerosRemovedValues = simplifiedValues.filter(
          (item) => item.y > 0,
        );
        //console.log(zerosRemovedValues);

        switch (curr.statName) {
          case 'avgPrice':
            lineColor = '#D96FF8';
            lineName = 'Average';
            break;
          case 'floorPrice':
            lineColor = '#1BD2A4';
            lineName = 'Floor';
            break;

          default:
            break;
        }

        return acc.concat({
          id: lineName,
          color: lineColor,
          data: zerosRemovedValues,
          suffix: 'Ξ',
        });
      }, []);
      
    return reducedListingStatsData;
  };

  const splitValuesArray = (valuesArray = []) => {
    if (valuesArray.length > 15) {
      const chunkLength = Math.floor(valuesArray.length / 8);
      const chunks = createChunks(valuesArray, chunkLength);
      const chunksArray = [];
      for (const chunk of chunks) {
        chunksArray.push(chunk);
      }
      return chunksArray;
    }

    return valuesArray;
  };

  // this is mis-named, it is for the Sales Volume Bar Chart, not the Floor chart
  const reduceFloorStatsData = (floorStatsData = []) => {
    //console.log(floorStatsData);
    const reducedFloorStatsData = floorStatsData
      ?.filter((item) => floorSalesVolumPoints.includes(item.statName))
      .reduce((acc, curr) => {
        let color = '';
        let name = '';
        const averageItems =
          curr.values.length > 15
            ? splitValuesArray(curr.values)?.map((item = []) => ({
                time: Math.ceil(
                  (item?.reduce((acc, ai) => acc + ai.time, 0) || 0) /
                    item.length,
                ),
                value:
                  (item.reduce((acc, ai) => acc + Number(ai.value), 0) || 0) /
                  item.length,
              }))
            : curr.values;
        const simplifiedValues =
          curr.statName === 'salesVolume'
            ? averageItems.map((item) => ({
                date: new Date(item.time),
                y1: formatETH(item.value),
                y1Color: '#4D77FF',
                y1Format: 'Ξ',
              }))
            : curr.values.map((item) => ({
                x: item.time,
                y: formatETH(item.value),
              }));

        switch (curr.statName) {
          case 'floorPrice':
            color = '#D96FF8';
            name = 'Floor';
            break;
          case 'salesVolume':
            color = '#FF6E01';
            name = 'Volume';
            break;

          default:
            break;
        }

        acc[curr.statName] =
          curr.statName === 'salesVolume'
            ? {
                keys: ['y1'],
                indexBy: 'date',
                data: simplifiedValues,
                labels: {
                  y1: 'Volume',
                },
              }
            : {
                id: name,
                color,
                data: simplifiedValues,
                suffix: 'Ξ',
              };

        return acc;
      }, {});
    return reducedFloorStatsData;
  };

  const reduceUniqueOwnersData = (uniqueOwnersData = []) => {
    const reducedUniqueOwnersData = uniqueOwnersData
      ?.filter((item) => item.statName === 'uniqueOwners')
      .reduce((acc, curr) => {
        const simplifiedValues = curr.values.map((item) => ({
          x: new Date(item.time).toISOString(),
          y: Number(item.value),
        }));

        //this is removing errors in data during development
        // todo this should be removed once data is stable
        const zerosRemovedValues = simplifiedValues.filter(
          (item) => item.y > 0,
        );

        return acc.concat({
          id: 'Owners',
          color: '#003CFF',
          data: zerosRemovedValues,
          suffix: '',
        });
      }, []);
    return reducedUniqueOwnersData;
  };

  const reduceTransactionData = (trData = []) => {
    const data = trData
      ?.map((item) => ({
        point: `#${item.token?.tokenId}`,
        x: item.saleDate,
        y: formatETH(item.salePrice || 0),
        image: item?.token?.image,
      }))
      ?.reduce(
        (acc, curr) =>
          acc.some((ai) => ai.point === curr.point) ? acc : acc.concat(curr),
        [],
      );

    return {
      id: 'Transactions',
      xId: 'Time',
      yId: 'Transactions',
      data,
      color: '#3689FC',
      xFormat: {
        prefix: '',
      },
      yFormat: {
        suffix: 'Ξ',
      },
    };
  };

  const simplifiedData = saleWallData?.map((item) => ({
    name: roundToNearest(formatETH(item.end || 0), 4),
    value: item.count,
  }));

  const buildData = (data = [], color) => {
    const result = {};
    const diff = data?.[1]?.name - data?.[0]?.name;
    result.data = data?.map((item, index) => ({
      y1: item.value,
      y1Color: color,
      value: `${item.name}Ξ - ${
        data[index + 1]?.name
          ? data[index + 1].name
          : roundToNearest(Number(item.name) + Number(diff), 4)
      }Ξ`,
    }));
    result.keys = ['y1'];
    result.indexBy = ['value'];
    return result;
  };

  const saleWallDataResult = buildData(simplifiedData, '#E18CF9');

  const handleChangeListingPrices = (timeFrame) => {
    setSelectedTime(timeFrame);
    refetchListingPrices({ durationInHours: strToHour[timeFrame] });
    // setListingData(generateListingPriceData(timeFrame));
  };

  const handleChangeFloorPrices = (timeFrame) => {
    setSelectedFloorPricesTime(timeFrame);
    refetchFloorPrices({ durationInHours: strToHour[timeFrame] });
    // setFloorPriceData(generateFloorPriceData(timeFrame));
  };

  const handleChangeTransactions = (timeFrame) => {
    setSelectedTransactionsTime(timeFrame);
    refetchTransactions({ durationInMinutes: strToMinute[timeFrame] });
  };

  const handleChangeUniqueOwners = (timeFrame) => {
    setSelectedUniqueOwnersTime(timeFrame);
    refetchUniqueOwners({ durationInHours: strToHour[timeFrame] });
  };

  // console.log(
  //   reduceFloorStatsData(floorStatsData),
  //   floorPriceData,
  //   saleWallDataResult,
  // );

  console.log(reduceListingStatsData(listingStatsData));

  return (
    <div className="flex flex-col mt-5">
      <h1 className="text-white text-2xl font-extrabold">Charts</h1>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col bg-gray-1200 p-3 relative rounded-2xl flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">
              Listing Floor
            </h2>
            <div className="flex flex-col items-end">
              <div className="flex flex-row">
                <div className="flex flex-row items-center mr-6">
                  <Pointer color="#1BD2A4" />
                  <h3 className="text-gray-200 text-sm font-semibold">Floor</h3>
                </div>
                <div className="flex flex-row items-center">
                  <Pointer color="#D96FF8" />
                  <h3 className="text-gray-200 text-sm font-semibold">
                    Average
                  </h3>
                </div>
              </div>
              <div className="flex flex-row">
                {filterList.map((item) => (
                  <button
                    key={item}
                    className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                    onClick={() => {
                      handleChangeListingPrices(item);
                    }}
                  >
                    <span
                      className={classNames(
                        'text-sm',
                        selectedTime === item
                          ? 'text-gray-300 font-black'
                          : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                      )}
                    >
                      {item}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/* <Linechart
            chartId="listing-price-chart"
            // data={reduceListingStatsData(listingStatsData)}
            data={listingData}
            canHighlightLine
            showSliceTooltip
            hourly
          /> */}
          {collectionListingLoading ? (
            <ChartLoader />
          ) : reduceListingStatsData(listingStatsData)?.length > 0 ? (
            <Linechart
              chartId="listing-price-chart"
              data={reduceListingStatsData(listingStatsData)}
              canHighlightLine
              showSliceTooltip
              hourly
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No data is available at this time.
              </h1>
            </Flex>
          )}
        </div>
        <div className="flex flex-col justify-start bg-gray-1200 p-3 ml-5 rounded-2xl relative flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">Sales Volume</h2>
            <div className="flex flex-col items-end">
              <div className="flex flex-row">
                <div className="flex flex-row items-center">
                  <Pointer color="#4D77FF" />
                  <h3 className="text-gray-200 text-sm font-semibold">
                    Volume
                  </h3>
                </div>
              </div>
              <div className="flex flex-row">
                {filterList.map((item) => (
                  <button
                    key={item}
                    className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                    onClick={() => {
                      handleChangeFloorPrices(item);
                    }}
                  >
                    <span
                      className={classNames(
                        'text-sm',
                        selectedFloorPricesTime === item
                          ? 'text-gray-300 font-black'
                          : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                      )}
                    >
                      {item}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          {/* <BarChart
            chartId="floor-price-chart"
            data={floorPriceData.salesVolume?.data || []}
            indexBy={floorPriceData.salesVolume?.indexBy}
            keys={floorPriceData.salesVolume?.keys}
            lineData={floorPriceData.floorPrice?.data}
          /> */}


          {/* This is called floorPrice but is actually the Sales Volume bar chart */}
          {floorPriceLoading ? (
            <ChartLoader />
          ) : reduceFloorStatsData(floorStatsData)?.salesVolume?.data?.length >
            0 ? (
            <BarChart
              chartId="floor-price-chart"
              data={
                reduceFloorStatsData(floorStatsData)?.salesVolume?.data || []
              }
              indexBy={
                reduceFloorStatsData(floorStatsData)?.salesVolume?.indexBy
              }
              keys={reduceFloorStatsData(floorStatsData)?.salesVolume?.keys}
              labels={reduceFloorStatsData(floorStatsData)?.salesVolume?.labels}
              lineData={reduceFloorStatsData(floorStatsData)?.floorPrice?.data}
              barWidth={34}
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No data is available at this time.
              </h1>
            </Flex>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col justify-center bg-gray-1200 p-3 rounded-2xl relative flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">Transactions</h2>
            <div className="flex flex-col items-end">
              <div className="flex flex-row">
                <div className="flex flex-row items-center mr-6">
                  <Pointer color="#4D77FF" />
                  <h3 className="text-gray-200 text-sm font-semibold">
                    Regular
                  </h3>
                </div>
                <div className="flex flex-row items-center">
                  <Pointer color="#D96FF8" />
                  <h3 className="text-gray-200 text-sm font-semibold">
                    NC members
                  </h3>
                </div>
              </div>
              <div className="flex flex-row">
                {filterList.map((item) => (
                  <button
                    key={item}
                    className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                    onClick={() => {
                      handleChangeTransactions(item);
                    }}
                  >
                    <span
                      className={classNames(
                        'text-sm',
                        selectedTransactionsTime === item
                          ? 'text-gray-300 font-black'
                          : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                      )}
                    >
                      {item}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          {transactionLoading ? (
            <ChartLoader />
          ) : reduceTransactionData(transactionData)?.data?.length > 0 ? (
            <ScatterPlot
              chartId="transaction-overview-chart"
              data={[reduceTransactionData(transactionData)]}
              height={300}
              showSliceTooltip
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No data is available at this time.
              </h1>
            </Flex>
          )}
        </div>
      </div>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col bg-gray-1200 p-3 rounded-2xl relative flex-1">
          <h2 className="text-white text-lg pt-1 font-bold">Sell Wall</h2>
          {sellWallLoading ? (
            <ChartLoader />
          ) : saleWallDataResult?.data?.length > 0 ? (
            <BarChart
              chartId="overview-sell-wall-chart"
              data={saleWallDataResult.data}
              indexBy={saleWallDataResult.indexBy}
              keys={saleWallDataResult.keys}
              barWidth={18}
              hasMultiLineAxisPoints
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No data is available at this time.
              </h1>
            </Flex>
          )}
        </div>
        <div className="flex flex-col justify-start bg-gray-1200 p-3 rounded-2xl ml-5 relative flex-1">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">Unique Owners</h2>
            <div className="flex flex-row">
              {filterList
                .filter((item) => item !== '1H')
                .map((item) => (
                  <button
                    key={item}
                    className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                    onClick={() => {
                      handleChangeUniqueOwners(item);
                    }}
                  >
                    <span
                      className={classNames(
                        'text-sm',
                        selectedUniqueOwnersTime === item
                          ? 'text-gray-300 font-black'
                          : 'text-gray-800 font-extrabold hover:text-gray-300 transition',
                      )}
                    >
                      {item}
                    </span>
                  </button>
                ))}
            </div>
          </div>
          {uniqueOwnersLoading ? (
            <ChartLoader />
          ) : reduceUniqueOwnersData(uniqueOwnersStatsData)?.[0]?.data?.length >
            0 ? (
            <Linechart
              chartId="overview-unique-owners"
              data={reduceUniqueOwnersData(uniqueOwnersStatsData)}
              canHighlightLine
              hourly
            />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No data is available at this time.
              </h1>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
}
