import { Footer } from "../components/Footer";
import { Logo } from "../components/Icons";
import screenHighLight1 from "../assets/img/pie.png";
import screenHighLight2 from "../assets/img/pnl.png";
import screen from "../assets/img/screen.png";
import landingNerd from "../assets/img/landing-nerd.png";
import {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  Fragment,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Login } from "../components/Login";

// todo rename MyDialog to something more appropriate
// todo recator this, just bring button into the dialog component and remove the ref
// see UserMenu
const MyDialog = forwardRef((props, ref) => {
  console.log("-----------MyDialog-----------");
  let [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    showDialog() {
      setIsOpen(true);
    },
  }));

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto rounded-3xl border border-gray-800">
              <Login />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
});

function Landing() {
  const dialogRef = useRef();

  return (
    <div className="flex flex-col w-full min-h-screen text-white items-center">
      <div className="flex flex-col w-full items-center bg-gradient-to-t from-black to-gray-1100">
        <MyDialog ref={dialogRef} />
        <div className="flex flex-row py-4 px-2 md:px-6 w-full items-center justify-between sticky top-0 z-10 bg-gray-1100 shadow-topbar">
          <div className="flex flex-row items-center text-gray-200 font-extrabold md:text-lg text-sm">
            <a href="https://www.nerdcollective.xyz">
              <div className="w-20 md:w-40">
                <Logo varient="long" width="100%" height="100%" />
              </div>
            </a>
            <a href="https://www.nerdcollective.xyz">
              <div className="whitespace-nowrap pl-6 md:pl-8 lg:pl-[64px] pr-6 hover:text-white transition">
                NFT Site
              </div>
            </a>
            <a href="https://opensea.io/collection/nerdcollective-genesis-pfp">
              <div className="whitespace-nowrap pr-6 hover:text-white transition">
                OpenSea
              </div>
            </a>
            <a href="https://twitter.com/NerdCollective_">
              <div className="hover:text-white transition pr-6">Twitter</div>
            </a>
            <a href="https://discord.com/invite/thenerdcollective">
              <div className="hover:text-white transition">Discord</div>
            </a>
          </div>
          <div className="hidden md:block">
            <button
              className="whitespace-nowrap inline-flex justify-center w-full px-2 ml-2 md:px-4 py-2 md:py-2 text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-pink-500 text-sm md:text-base md:font-semibold"
              onClick={() => dialogRef.current.showDialog()}
            >
              Connect wallet
            </button>
          </div>
        </div>
        <div className="text-center w-[350px] flex flex-col items-center md:w-[800px]">
          <h1 className="font-extrabold text-gray-100 lg:leading-[110%] text-4xl md:text-6xl lg:text-7xl pt-16 md:pt-20 lg:pt-24 pb-1 md:pb-4">
            Finally, a Web3 home for your NFT collection
          </h1>
          <div className="md:w-[500px] w-[350px]">
            <h2 className="text-pink-500 text-base md:text-xl lg:text-2xl font-semibold pt-2">
              Nerd out on your tokens using data that's relevant, not
              overwhelming
            </h2>
          </div>
        </div>
        <div className="flex py-10 md:py-12 lg:py-16">
          <button
            className="whitespace-nowrap inline-flex justify-center px-8 ml-2 md:px-12 py-2 md:py-2 text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-pink-500 text-sm md:text-base md:font-semibold"
            onClick={() => dialogRef.current.showDialog()}
          >
            Connect wallet
          </button>
        </div>
        <div className="pb-16 relative">
          <img
            alt="app screenshot"
            className="rounded-2xl border-2 md:border-3 w-[320px] md:w-[600px] lg:w-[700px] border-blue-900"
            src={screen}
          ></img>
          <img
            alt="app screenshot"
            className="rounded-2xl border-2 md:border-3 w-[200px] md:w-[300px] lg:w-[400px] border-pink-1000 absolute left-[-20px] top-[280px] md:left-[-50px] md:top-[450px] lg:left-[-150px] lg:top-[500px]"
            src={screenHighLight1}
          ></img>
          <img
            alt="app screenshot"
            className="rounded-2xl border-2 md:border-3 w-[200px] md:w-[350px] lg:w-[450px] border-purple-1100 absolute left-[140px] top-[80px] md:left-[300px] md:top-[150px] lg:left-[500px] lg:top-[100px]"
            src={screenHighLight2}
          ></img>
        </div>
        <div className="py-8 md:py-24 w-full flex flex-col md:flex-row text-center justify-center text-gray-100 lg:text-4xl md:text-3xl text-3xl font-extrabold bg-gradient-to-r from-gray-1100 via-blue-50 to-gray-1100">
          <div className="flex flex-col md:flex-row md:justify-evenly items-center md:w-[1300px]">
            <div className="bg-gray-1000 rounded-2xl border border-gray-400 min-h-full w-[320px] min-w-[320px] md:w-[230px] md:min-w-[230px] lg:w-[340px] lg:min-w-[340px] p-7 lg:p-10 md:mr-2 mb-10 md:mb-0">
              <h3 className="pb-3 md:pb-4">Join The Collective</h3>
              <div className="text-base font-light text-gray-200 leading-7">
                This app is part of The Nerd Collective, along with our gorgeous
                NFT collectibles and fun, inclusive community. We're creating a
                Web3 home for all nerdkind!
              </div>
            </div>
            <div className="bg-gray-1000 rounded-2xl border border-gray-400 min-h-full md:min-h[400px] w-[320px] min-w-[320px] md:w-[230px] md:min-w-[230px] lg:w-[340px] lg:min-w-[340px] p-7 lg:p-10 md:mr-2 mb-10 md:mb-0">
              <h3 className="pb-3 md:pb-4">Understand Your Collection</h3>
              <div className="text-base font-light text-gray-200 leading-7">
                See your collectibles front and center, with unique views into
                your trading history and more insight into the tokens you love.
              </div>
            </div>
            <div className="bg-gray-1000 rounded-2xl border border-gray-400 min-h-full md:min-h[400px] w-[320px] min-w-[320px] md:w-[230px] md:min-w-[230px] lg:w-[340px] lg:min-w-[340px] p-7 lg:p-10 md:mb-0">
              <h3 className="pb-3 md:pb-4">Just The Beginning</h3>
              <div className="text-base font-light text-gray-200 leading-7">
                We're in early beta with many new features coming soon.
                Together, we'll develop the product roadmap with input from our
                TNC community.
              </div>
            </div>
          </div>
        </div>
        <div className="md:py-24 py-12 w-full bg-gradient-to-t from-black to-gray-1100">
          <div className="flex flex-col items-center text-center md:w-full w-[360px] m-auto pb-6">
            <div className="big-cta md:text-5xl lg:text-7xl text-4xl font-extrabold">
              We can't do it without you.
            </div>
            <div className="text-base font-light text-gray-200 leading-7 md:w-2/3 pt-2">
              We have an ambitious vision, and are looking for people to join
              our team to help shape the future of The Nerd Collective.
            </div>
            <div className="flex py-8 w-36">
              <button
                className="inline-flex justify-center w-full px-4 py-2 text-base font-semibold text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-pink-500  sm:text-sm"
                onClick={() => {
                  window.open("https://mint.nerdcollective.xyz/");
                }}
              >
                Mint now!
              </button>
            </div>
          </div>
          <img
            alt="TNC NFT"
            className="w-[250px] lg:w-[400px] m-auto"
            src={landingNerd}
          ></img>
          <Footer reduceMargin="true" />
        </div>
      </div>
    </div>
  );
}

export default Landing;
