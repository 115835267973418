import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  &:hover {
    fill: ${(props) => props.hoverColor};
  }
  transition-property: fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
`;

export const IconWrapper = withTheme(
  ({ size = 16, color, theme, hoverColor, children, vbH = 15, vbW = 15 }) => {
  // todo hack below adds tailwind's group-hover to support places where
  // the icon has a hover state based on parent's. Seemed to play
  // nice with the standard hover code above, so leaving as-is
  return (
    <StyledSVG
      className = {'icon group-hover:fill-' + hoverColor}
      viewBox = {'0 0 ' + vbW + ' ' + vbH}
      stroke-linecap ='square'
      fill={theme.colors[color]}
      size={size}
      hoverColor = {theme.colors[hoverColor]}
    >
    {children}
    </StyledSVG>
  );
});