import { gql } from "@apollo/client";

export const requestLogin = gql`
  mutation requestLogin($address: String!) {
    requestLogin(address: $address) {
      address
      nonce
      messageToSign
      newUser
    }
  }
`;

export const loginWithAddress = gql`
  mutation loginWithAddress($address: String!) {
    loginWithAddress(address: $address) {
      user {
        id
        username
        address
        providerName
        verified
        createdAt
        syncedAt
        pfpToken {
          contract
          tokenID
        }
        bannerTokens {
          contract
          tokenID
        }
        otherWallets {
          address
        }
      }
      token
    }
  }
`;

export const verifyLogin = gql`
  mutation verifyLogin(
    $address: String!
    $signature: String!
    $userName: String
    $providerName: String!
  ) {
    verifyLogin(
      address: $address
      signature: $signature
      username: $userName
      providerName: $providerName
    ) {
      user {
        id
        username
        address
        providerName
        verified
        createdAt
        syncedAt
        pfpToken {
          contract
          tokenID
        }
        bannerTokens {
          contract
          tokenID
        }
        otherWallets {
          address
        }
      }
      token
    }
  }
`;

export const setUsername = gql`
  mutation setUsername($username: String!) {
    setUsername(username: $username) {
      user {
        id
        username
        address
        providerName
        verified
        createdAt
        syncedAt
        pfpToken {
          contract
          tokenID
        }
        bannerTokens {
          contract
          tokenID
        }
        otherWallets {
          address
        }
      }
    }
  }
`;

export const logOut = gql`
  mutation logout {
    logout
  }
`;
