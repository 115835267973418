import { WaterFallChart, PieChart } from "../Charts";
import { useState } from "react";
import { useQuery } from "@apollo/client";
// import { pieChartData } from './mockData';
import { getProfitLoss, getTokens } from "../../graphql/stats";
import { formatETH } from "../../utils/eth";
import ChartLoader from "../Shimmer/ChartLoader";
import { roundToNearest } from "../../utils/numbers";
import { Flex } from "../Box";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

let collectionCount = 0;

const filterList = ["1H", "1D", "1W", "1M", "1Y", "ALL"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const strToHour = {
  "1H": 1,
  "1D": 24,
  "1W": 168,
  "1M": 744,
  "1Y": 8760,
  ALL: undefined,
};

const calculateIncomeTax = (income) => {
  const tax = income * 0.28;

  return tax;
};

const waterFallDataModel = [
  {
    id: "gross_sales",
    name: "Gross Sales",
    value: 0,
    color: "#4D77FF",
  },
  {
    id: "cost_of_sales",
    name: "Cost of Sales",
    value: 0,
    color: "#FA0082",
  },
  {
    id: "gross_margin",
    name: "Gross Margin",
    value: 0,
    color: "#4D77FF",
    colorNeg: "#B3C5FF",
  },
  {
    id: "gas_fees",
    name: "Gas Fees",
    value: 0,
    color: "#FA0082",
  },
  {
    id: "royalty_fees",
    name: "Royalty & Fees",
    value: 0,
    color: "#FA0082",
  },
  {
    id: "income_pre_tax",
    name: "Income Pre Tax",
    value: 0,
    color: "#4D77FF",
    colorNeg: "#B3C5FF",
  },
];

const negativeValues = [
  "cost_of_sales",
  "gas_fees",
  "royalty_fees",
  "income_tax",
];

const pieChartColors = [
  "#FFE576",
  "#D96FF8",
  "#FA0082",
  "#B3C5FF",
  "#4D77FF",
  "#FF6E01",
  "#E8A9FB",
  "#F42829",
  "#1EE9B6",
];

export default function Analytics() {
  const gaEventTracker = useAnalyticsEventTracker("Analytics");

  const {
    data: { getProfitLoss: getProfitLossData = {} } = {},
    loading: pnlLoading,
    refetch,
  } = useQuery(getProfitLoss, { notifyOnNetworkStatusChange: true });
  const {
    data: { getTokens: getTokensData = {} } = {},
    loading: tokensLoading,
  } = useQuery(getTokens, { notifyOnNetworkStatusChange: true });
  const pnlData = getProfitLossData || {};
  let tokensData = getTokensData?.tokens || [];
  // some bad tokens dont have collection data
  if (tokensData) tokensData = tokensData.filter((token) => token.collection);
  const [selectedTime, setSelectedTime] = useState(
    filterList[filterList.length - 1]
  );

  const reducePnlData = (data) => {
    const gross_sales = formatETH(data?.grossSales || 0);
    const cost_of_sales = formatETH(data?.costOfSales || 0);
    const gross_margin = Math.abs(gross_sales) - Math.abs(cost_of_sales);
    const gas_fees = formatETH(data?.gasFees || 0);
    const royalty_fees =
      formatETH(data?.royalties || 0) + formatETH(data?.marketFees || 0);
    const income_pre_tax =
      Math.abs(gross_sales) -
      Math.abs(cost_of_sales) -
      Math.abs(gas_fees) -
      Math.abs(royalty_fees);
    const income_tax =
      income_pre_tax > 0 ? calculateIncomeTax(income_pre_tax) : 0;
    const net_income = income_pre_tax - income_tax;

    return {
      gross_sales,
      cost_of_sales,
      gross_margin,
      gas_fees,
      royalty_fees,
      income_pre_tax,
      income_tax,
      net_income,
    };
  };

  const pnlDataReduced = reducePnlData(pnlData);
  const waterFallData = waterFallDataModel.map((item) => ({
    ...item,
    value: `${negativeValues.includes(item.id) ? "-" : ""}${roundToNearest(
      pnlDataReduced[item.id],
      2
    )}`,
  }));

  const handleChangeSalesPnl = (timeFrame) => {
    gaEventTracker("click", "PnL-Time-" + timeFrame);
    setSelectedTime(timeFrame);
    refetch({
      durationObj: strToHour[timeFrame]
        ? { durationInHours: strToHour[timeFrame] }
        : {},
    });
  };

  const buildPieChartData = (data) => {
    const tokensCount = data.reduce((acc, item) => {
      let value = acc[item.collection?.id]?.value || 0;
      if (acc[item.collection?.id]) {
        value += 1;
      } else {
        value = 1;
      }

      //console.log(item);
      acc[item.collection?.id] = {
        value,
        type: item.collection?.name,
        slug: item.collection?.slug,
      };

      return acc;
    }, {});

    collectionCount = Object.keys(tokensCount).length;
    // console.log(collectionsCount);

    // const totalTokenCount = Object.values(tokensCount).reduce((acc, item) => {
    //   return acc + item.value;
    // }, 0);

    const tokenPair = Object.keys(tokensCount)
      .sort((a, b) => tokensCount[b].value - tokensCount[a].value)
      .reduce((acc, key, index) => {
        acc[key] = {
          value: tokensCount[key].value,
          type: tokensCount[key].type,
          slug: tokensCount[key].slug,
          color: pieChartColors[index],
        };

        return acc;
      }, {});

    const resultData = Object.keys(tokenPair).map((item) => ({
      ...tokenPair[item],
    }));
    const otherTokensTotal = Object.keys(tokenPair)
      .slice(8)
      .reduce((acc, cur) => acc + tokenPair[cur].value, 0);

    const result = {
      name: `${collectionCount}`,
      themeColor: "pink",
      values: [...resultData.slice(0, 8)],
    };

    if (otherTokensTotal > 0) {
      result.values.push({
        value: otherTokensTotal,
        type: "Others",
        color: "#1EE9B6",
      });
    }

    //console.log(result);
    return result;
  };
  return (
    <div className="flex flex-col mt-5">
      <h1 className="text-white text-2xl font-extrabold">Analytics</h1>
      <div className="flex flex-row mt-5 w-full">
        <div className="flex flex-col bg-gray-1200 p-3 relative flex-1 rounded-2xl">
          <div className="flex flex-row justify-between mb-3">
            <h2 className="text-white text-lg pt-1 font-bold">Sales P&L</h2>
            <div className="flex flex-row">
              {filterList.map((item) => (
                <button
                  key={item}
                  className="outline-none p-0.5 mr-1 last:mr-0 focus:outline-none"
                  onClick={() => {
                    handleChangeSalesPnl(item);
                  }}
                >
                  <span
                    className={classNames(
                      "text-sm",
                      selectedTime === item
                        ? "text-gray-300 font-black"
                        : "text-gray-800 font-extrabold hover:text-gray-300 transition"
                    )}
                  >
                    {item}
                  </span>
                </button>
              ))}
            </div>
          </div>
          {pnlLoading ? (
            <ChartLoader />
          ) : pnlDataReduced.gross_sales !== 0 ? (
            <WaterFallChart data={waterFallData} maxHeight={360} />
          ) : (
            <Flex
              width="100%"
              height="100%"
              minHeight="300px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <h1 className="text-sm text-center font-semibold text-gray-300 bg-gray-1100 rounded-lg p-3">
                No sales were reported during the selected timeframe. <br />
                P&L includes NFTs purchased and sold from specified wallets.
                <br />
                (To add additional wallets, click the User Menu in the top right
                corner)
              </h1>
            </Flex>
          )}
        </div>
        <div className="flex flex-col justify-center bg-gray-1200 p-3 ml-5 relative flex-1 rounded-2xl">
          <h2 className="text-white text-lg pt-1 font-bold">
            Collection Breakdown
            {/* <span className="text-gray-400 text-sm pl-1">
              {collectionCount}
            </span> */}
          </h2>
          {tokensLoading ? (
            <ChartLoader />
          ) : (
            tokensData.length > 0 && (
              <PieChart
                data={buildPieChartData(tokensData)}
                maxHeight={400}
                width={350}
                height={350}
                radius={170}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
