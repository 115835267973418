import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size[0]}px;
  height: ${(props) => props.size[1]}px;
`;

export const GridIcon = withTheme(({ size = [15,16], ...props }) => {
  return (
    <StyledSVG
      size={size}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.5 1H1.5C0.947715 1 0.5 1.44772 0.5 2V6C0.5 6.55228 0.947715 7 1.5 7H5.5C6.05228 7 6.5 6.55228 6.5 6V2C6.5 1.44772 6.05228 1 5.5 1Z'
        fill='#34303E'
      />
      <path
        d='M13.5 1H9.5C8.94772 1 8.5 1.44772 8.5 2V6C8.5 6.55228 8.94772 7 9.5 7H13.5C14.0523 7 14.5 6.55228 14.5 6V2C14.5 1.44772 14.0523 1 13.5 1Z'
        fill='#34303E'
      />
      <path
        d='M13.5 9H9.5C8.94772 9 8.5 9.44772 8.5 10V14C8.5 14.5523 8.94772 15 9.5 15H13.5C14.0523 15 14.5 14.5523 14.5 14V10C14.5 9.44772 14.0523 9 13.5 9Z'
        fill='#34303E'
      />
      <path
        d='M5.5 9H1.5C0.947715 9 0.5 9.44772 0.5 10V14C0.5 14.5523 0.947715 15 1.5 15H5.5C6.05228 15 6.5 14.5523 6.5 14V10C6.5 9.44772 6.05228 9 5.5 9Z'
        fill='#34303E'
      />
      <path
        d='M5.5 1H1.5C0.947715 1 0.5 1.44772 0.5 2V6C0.5 6.55228 0.947715 7 1.5 7H5.5C6.05228 7 6.5 6.55228 6.5 6V2C6.5 1.44772 6.05228 1 5.5 1Z'
        stroke='#A5A3A9'
      />
      <path
        d='M13.5 1H9.5C8.94772 1 8.5 1.44772 8.5 2V6C8.5 6.55228 8.94772 7 9.5 7H13.5C14.0523 7 14.5 6.55228 14.5 6V2C14.5 1.44772 14.0523 1 13.5 1Z'
        stroke='#A5A3A9'
      />
      <path
        d='M13.5 9H9.5C8.94772 9 8.5 9.44772 8.5 10V14C8.5 14.5523 8.94772 15 9.5 15H13.5C14.0523 15 14.5 14.5523 14.5 14V10C14.5 9.44772 14.0523 9 13.5 9Z'
        stroke='#A5A3A9'
      />
      <path
        d='M5.5 9H1.5C0.947715 9 0.5 9.44772 0.5 10V14C0.5 14.5523 0.947715 15 1.5 15H5.5C6.05228 15 6.5 14.5523 6.5 14V10C6.5 9.44772 6.05228 9 5.5 9Z'
        stroke='#A5A3A9'
      />
    </StyledSVG>
  );
});

GridIcon.displayName = 'GridIcon';
