import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const Etherium = withTheme(({ size = 16, ...props }) => {
  return (
    <StyledSVG
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#4D77FF'
      />
      <path
        d='M10.311 2.5V8.04373L14.9966 10.1375L10.311 2.5Z'
        fill='white'
        fillOpacity='0.602'
      />
      <path d='M10.3113 2.5L5.625 10.1375L10.3113 8.04373V2.5Z' fill='white' />
      <path
        d='M10.311 13.73V17.4969L14.9998 11.01L10.311 13.73Z'
        fill='white'
        fillOpacity='0.602'
      />
      <path
        d='M10.3113 17.4969V13.7294L5.625 11.01L10.3113 17.4969Z'
        fill='white'
      />
      <path
        d='M10.311 12.8581L14.9966 10.1375L10.311 8.04498V12.8581Z'
        fill='white'
        fillOpacity='0.2'
      />
      <path
        d='M5.625 10.1375L10.3113 12.8581V8.04498L5.625 10.1375Z'
        fill='white'
        fillOpacity='0.602'
      />
    </StyledSVG>
  );
});

Etherium.displayName = 'Etherium';
