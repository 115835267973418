import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const ChevronLgRight = withTheme(({ size = 16, viewBx = size, color, theme }) => {
  const viewBoxStr = "0 0 " + viewBx + " " + viewBx;
  return (
    <StyledSVG
      className='icon'
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewBoxStr}
      stroke-linecap="square"
      stroke={theme.colors[color]}
      strokeWidth='1.5'
    >
      <path d="M4 14L11 7.5L4 1" />
    </StyledSVG>
  );
});

ChevronLgRight.displayName = 'ChevronLgRight';


