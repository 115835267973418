export const ChevronDown2 = (props) => (
  <svg
  width={props.w || 15}
  height={props.h || 15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14 5L7.5 12L1 5" strokeLinecap="square" />
  </svg>
);

export default ChevronDown2;