import { useQuery } from '@apollo/client';
import { Flex } from '../Box';
import { Table, compareRowsNumeric } from '../Table';
// import { BarChart } from '../Charts';
// import { simpledBarChartData, simpledBarChartDataVolume } from './mockData';
import { getTraitsBreakdown, getCollectionTraits } from '../../graphql/stats';
import { useParams } from 'react-router-dom';
import { formatETH } from '../../utils/eth';
import { PercentChangeText } from '../Misc';
import { roundToNearest } from '../../utils/numbers';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

function getPercentChanged(changeTuple, isEth = true) {
  const [previous, current] = changeTuple || [];
  let percent;
  if (previous === null || current === null) {
    // console.log('Missing previous or current value');
    return null;
  } else {
    if (isEth) {
      const ethPrevious = Number(formatETH(previous || 0));
      const ethCurrent = Number(formatETH(current || 0));
      percent = ((ethCurrent - ethPrevious) / (ethPrevious || 1)) * 100;
      // console.log('!!!!!!!!!!!!!!');
      // console.log('previous: ' + ethPrevious);
      // console.log('current: ' + ethCurrent);
      // console.log('percent: ' + percent);
    } else {
      percent = ((current - previous) / (previous || 1)) * 100;
    }
    return percent;
  }
}

// function camelCase(str) {
//   // converting all characters to lowercase
//   var ans = str.toLowerCase();

//   // Returning string to camelcase
//   return ans
//     .split(' ')
//     .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
// }

function titleCase(str) {
  //console.log(str);
  const result = str
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return result;
};

export const TraitsBreakdown = ({ selectedTraits, selectedOps }) => {
  const params = useParams();

  const {
    data: { getTraitsBreakdown: traitsBreakdownData = [] } = {},
    loading: traitsBreakdownLoading,
  } = useQuery(getTraitsBreakdown, {
    variables: {
      collectionSlug: params.collection,
      durationInMinutes: 1440,
    },
    notifyOnNetworkStatusChange: true,
  });

  // for the distribution num w/ traits/total tokens
  const { data: { getCollection: collectionTraitsData = [] } = {} } = useQuery(
    getCollectionTraits,
    {
      variables: {
        collectionSlug: params.collection,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  // saving the trait data from the server in a more accessible object
  // transformedData.category.trait = N
  const totalTokens = collectionTraitsData?.totalTokens;
  let traitTypeData = collectionTraitsData?.traitTypes;
  const transformedData = {};
  traitTypeData?.forEach((ttData) => {
    if (!transformedData[ttData.name]) {
      transformedData[ttData.name] = {};
    }
    transformedData[ttData.name][ttData.value] = ttData.count;
  });


// Filter function based on selected traits in sidebar menu
  function traitExistsInBDItem(traitBDItem) {
    const isInItem = selectedTraits.some((selectedTrait) => {
      const categoryMatch = compareTraits(selectedTrait.id, traitBDItem.category)
      const nameMatch = ( selectedTrait.values.filter(value => compareTraits(value, traitBDItem.value)).length > 0 );
      return (
        categoryMatch && nameMatch
      );

    });

    return isInItem;
  };

  // const volumeDays = [...Array(7)]
  //   .map((_, i) => {
  //     const d = new Date();
  //     d.setDate(d.getDate() - i);
  //     return d;
  //   })
  //   .map((d) => d.toISOString().split('T')[0]);

  // const buildData = (data = [], color) => {
  //   const result = {};
  //   result.data = volumeDays.map((item) => {
  //     const dataItem = data.find(
  //       (d) => new Date(d.day).toISOString().split('T')[0] === item,
  //     );
  //     return {
  //       y1: formatETH(dataItem?.value || 0),
  //       y1Color: color,
  //       value: item,
  //     };
  //   });
  //   result.keys = ['y1'];
  //   result.indexBy = ['value'];
  //   return result;
  // };

  // OpenSea is not consistent with casing for trait names or values,
  // so upper, lower, and all-caps must all be tested 
  function getTraitValue (traitCategory, traitName){
    const traitCategoryLowerCase = traitCategory.toLowerCase();
    const traitCategoryTitleCase = titleCase(traitCategory);
    const traitCategoryUpperCase = traitCategory.toUpperCase();
    const traitNameLowerCase = traitName.toLowerCase();
    const traitNameTitleCase = titleCase(traitName);
    
    const traitDat = transformedData?.[traitCategoryLowerCase] || transformedData?.[traitCategoryTitleCase] || transformedData?.[traitCategoryUpperCase];
    console.log(traitDat);
    return traitDat?.[traitNameLowerCase] || traitDat?.[traitNameTitleCase] || traitDat?.[traitNameTitleCase];

  };

  // OpenSea is not consistent with casing for trait names or values,
  // so upper, lower, and all-caps must all be tested 
  function compareTraits (traitA, traitB){
    return (traitB === traitA.toLowerCase()) || (traitB === titleCase(traitA)) || (traitB === traitA.toUpperCase());
  };
  

  function makeRowData(queryDataItem) {
    //console.log(queryDataItem?.category, queryDataItem?.value);
    
    let rowData = {
      averagePrice: queryDataItem?.averagePrice,
      floorPrice: queryDataItem?.floorPrice,
      maxPrice: queryDataItem?.maxPrice,
      category: titleCase(queryDataItem?.category) || 'Not Available',
      value: queryDataItem?.value,
      sales: queryDataItem?.sales || 0,
      distribution:
        Number(
          getTraitValue(queryDataItem?.category, queryDataItem?.value)).toLocaleString() +
        ' / ' +
        Number(totalTokens).toLocaleString(),
        distPercent: Number(getTraitValue(queryDataItem?.category, queryDataItem?.value)) /
        Number(totalTokens) * 100
    };

    return rowData;
  }

  // Create the data for the table
  // filtering from SideBar happens here
  //console.log(traitsBreakdownData);
  // traitsBreakdownData.map(item => { if(item.category==="flair"){console.log(item.category + " : " + item.value)}});
  const tableData =
    selectedTraits.length > 0
      ? traitsBreakdownData
          .filter((traitBDItem) => {
            return traitExistsInBDItem(traitBDItem);
          })
          .map(makeRowData)
      : traitsBreakdownData.map(makeRowData);

  const columns = [
    {
      Header: 'Category',
      accessor: 'category',
      align: 'left',
      width: 120,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-gray-200 font-bold text-sm">{value}</strong>
        </Flex>
      ),
    },
    {
      Header: 'Trait',
      accessor: 'value',
      align: 'left',
      width: 120,
      Cell: ({ cell: { value } }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-gray-200 font-bold text-sm">{value}</strong>
        </Flex>
      ),
    },
    {
      Header: 'Distribution',
      accessor: 'distribution',
      align: 'left',
      width: 120,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Flex flexDirection="column" alignItems="baseline">
          <strong className="text-gray-200 font-bold text-sm">{cell.value}</strong>
          <span className="text-xs font-semibold text-gray-500">{roundToNearest(cell.row.original.distPercent, 1) }%</span>
        </Flex>
      ),
    },
    {
      Header: 'Sales',
      accessor: 'sales',
      align: 'left',
      width: 110,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {value[1]}
            </strong>
            <PercentChangeText changedValue={getPercentChanged(value, false)} />
          </Flex>
        );
      },
    },
    {
      Header: 'Floor Price',
      accessor: 'floorPrice',
      align: 'left',
      width: 110,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(Number(formatETH(value[1] || 0)), 2)}Ξ
            </strong>
            <PercentChangeText changedValue={getPercentChanged(value)} />
          </Flex>
        );
      },
    },
    {
      Header: 'Avg. Price',
      accessor: 'averagePrice',
      align: 'left',
      width: 110,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(Number(formatETH(value[1] || 0)), 2)}Ξ
            </strong>
            <PercentChangeText changedValue={getPercentChanged(value)} />
          </Flex>
        );
      },
    },
    {
      Header: 'Max Price',
      accessor: 'maxPrice',
      align: 'left',
      width: 100,
      sortType: compareRowsNumeric,
      Cell: ({ cell: { value } }) => {
        return (
          <Flex flexDirection="column" alignItems="baseline">
            <strong className="text-gray-200 font-bold text-sm">
              {roundToNearest(Number(formatETH(value[1] || 0)), 2)}Ξ
            </strong>
            <PercentChangeText changedValue={getPercentChanged(value)} />
          </Flex>
        );
      },
    },
    // {
    //   Header: '7 Day Floor',
    //   accessor: 'chart',
    //   align: 'left',
    //   width: 140,
    //   disableSortBy: true,
    //   Cell: ({ cell: { value = [] } }) => {
    //     const data = buildData(value, '#f5f5f5');
    //     console.log(data);
    //     return (
    //       <Flex>
    //         <BarChart
    //           data={simpledBarChartData.data}
    //           indexBy={simpledBarChartData.indexBy}
    //           keys={simpledBarChartData.keys}
    //           isMicro
    //         />
    //       </Flex>
    //     );
    //   },
    // },
    // {
    //   Header: '7 Day Volume',
    //   accessor: 'chartVolume',
    //   align: 'left',
    //   width: 140,
    //   disableSortBy: true,
    //   Cell: ({ cell: { value = [] } }) => {
    //     const data = buildData(value, '#f5f5f5');
    //     console.log(data);

    //     return (
    //       <Flex>
    //         <BarChart
    //           data={simpledBarChartDataVolume.data}
    //           indexBy={simpledBarChartDataVolume.indexBy}
    //           keys={simpledBarChartDataVolume.keys}
    //           isMicro
    //         />
    //       </Flex>
    //     );
    //   },
    // },
  ];

  const handleRowClick = (row) => {
    console.log(row);
  };

  return (
    <div className="flex flex-col rounded-xl p-4 overflow-hidden mt-5">
      <h1 className="text-white text-2xl font-extrabold mb-5">
        Traits Breakdown
      </h1>
      <Table
        columns={columns}
        data={tableData}
        onRowClick={handleRowClick}
        loading={traitsBreakdownLoading}
        hasPagination
        canSort
        initialState={{
          pageIndex: 0,
          sortBy: [{ id: 'category', desc: true }],
        }}
        disableSortRemove="true"
      />
    </div>
  );
};
