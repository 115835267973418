import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const Collection = withTheme(({ size = 16, color, theme, ...props }) => {
  return (
    <StyledSVG
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0.5 4L7.5 1L14.5 4L7.5 7L0.5 4Z' fill='#34303E' />
      <path
        d='M7.5 1L7.69696 0.540427C7.57119 0.486524 7.42881 0.486524 7.30304 0.540427L7.5 1ZM0.5 4L0.30304 3.54043C0.119198 3.61922 0 3.79999 0 4C0 4.20001 0.119198 4.38078 0.30304 4.45957L0.5 4ZM7.5 7L7.30304 7.45957C7.42881 7.51348 7.57119 7.51348 7.69696 7.45957L7.5 7ZM14.5 4L14.697 4.45957C14.8808 4.38078 15 4.20001 15 4C15 3.79999 14.8808 3.61922 14.697 3.54043L14.5 4ZM7.5 10L7.30304 10.4596L7.5 10.544L7.69696 10.4596L7.5 10ZM7.5 13L7.30304 13.4596L7.5 13.544L7.69696 13.4596L7.5 13ZM7.30304 0.540427L0.30304 3.54043L0.69696 4.45957L7.69696 1.45957L7.30304 0.540427ZM0.30304 4.45957L7.30304 7.45957L7.69696 6.54043L0.69696 3.54043L0.30304 4.45957ZM7.69696 7.45957L14.697 4.45957L14.303 3.54043L7.30304 6.54043L7.69696 7.45957ZM14.697 3.54043L7.69696 0.540427L7.30304 1.45957L14.303 4.45957L14.697 3.54043ZM0.30304 7.45957L7.30304 10.4596L7.69696 9.54043L0.69696 6.54043L0.30304 7.45957ZM7.69696 10.4596L14.697 7.45957L14.303 6.54043L7.30304 9.54043L7.69696 10.4596ZM0.30304 10.4596L7.30304 13.4596L7.69696 12.5404L0.69696 9.54043L0.30304 10.4596ZM7.69696 13.4596L14.697 10.4596L14.303 9.54043L7.30304 12.5404L7.69696 13.4596Z'
        fill='#A5A3A9'
      />
    </StyledSVG>
  );
});

Collection.displayName = 'Collection';
