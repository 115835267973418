export function resizeImgURL(imageURL, width = 250) {
  let urlObj;
  try {
    urlObj = new URL(imageURL);
  } catch {
    urlObj = undefined;
  }
  if (!urlObj) return undefined;

  switch (urlObj.hostname) {
    case "res.cloudinary.com":
      return imageURL.replace("/upload/", "/upload/w_" + width + "/");
    case "i.seadn.io":
      return imageURL.replace("w=500", "w=" + width);
    case "nft-cdn.alchemy.com":
      if (width <= 250) {
        const newURL =
          "https://res.cloudinary.com/alchemyapi/image/upload/thumbnail/eth-mainnet/HASH";
        const hashfromURL = imageURL.split("/").pop();
        return newURL.replace("HASH", hashfromURL);
      } else {
        return imageURL;
      }

    default:
      return imageURL;
  }
}
