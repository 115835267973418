import { MagGlass } from "../Icons";

export default function Search({ defaultText, changeCallBack }) {
  return (
    <div className="w-96 flex md:ml-0">
      <div className="relative w-full text-white focus-within:text-white">
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
          <MagGlass aria-hidden="true" />
        </div>
        <input
          type="text"
          onChange={(e) => {
            changeCallBack(e.target.value);
          }}
          value={defaultText || ""}
          className="transition block w-full h-full pl-10 pr-3 py-2 rounded-xl sm:text-sm border-gray-900 text-white bg-gray-1200 placeholder-gray-900 focus:placeholder-gray-700 focus:ring-0 hover:placeholder-gray-700 hover:border-gray-700 focus:outline-offset-0 focus:outline-1 focus:outline-blue-1200 focus:border-blue-700 focus:border"
          placeholder="Filter items"
          autoComplete="off"
        />
      </div>
    </div>
  );
}
