import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSVG = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const UpFilled = withTheme(({ ...props }) => {
  return (
    <StyledSVG
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.77009 5.89655L4.56299 10.1037L12.9772 10.1037L8.77009 5.89655Z'
      />
    </StyledSVG>
  );
});

UpFilled.displayName = 'UpFilled';
