import React, { useContext } from "react";

import { Flex } from "../Box";
import { AuthStateContext } from "../../lib/auth.machine";

// ConnectButtons component
// this component renders the connect buttons for the user to connect their wallet
// it will render a loading spinner if the loading prop is true
// it will render an error message if the error prop is not null
// it will render the connect buttons if the loading prop is false and the error prop is null
// the connect buttons will call the ON_CONNECT_REQUEST event on the auth machine
// and pass the provider as a payload
export const ConnectButtons = (props) => {
  const authService = useContext(AuthStateContext);
  return (
    <div className="w-[42rem] p-12 mx-auto bg-gray-1100 rounded-3xl flex flex-col items-center">
      <h2 className="text-lg font-medium leading-6 text-center text-white">
        Connect your wallet...
      </h2>
      <Flex mt={6} flexDirection="column" alignItems="center" width="60%">
        <div className="flex w-full mb-6">
          <button
            className="inline-flex justify-center w-full px-4 py-2 text-base font-semibold text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-pink-500 sm:text-sm"
            onClick={() =>
              authService.send("ON_CONNECT_REQUEST", { provider: "Metamask" })
            }
            disabled={props.loading}
          >
            Connect to Metamask
          </button>
        </div>
        <div className="flex w-full mb-6">
          <button
            className="inline-flex justify-center w-full px-4 py-2 text-base font-semibold text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-pink-500 sm:text-sm"
            onClick={() =>
              authService.send("ON_CONNECT_REQUEST", { provider: "Coinbase" })
            }
            disabled={props.loading}
          >
            Connect to Coinbase
          </button>
        </div>
        {/* <div className="flex w-full mb-6">
          <button
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white transition border border-transparent shadow-sm rounded-xl bg-pink-1000 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink sm:text-sm"
            onClick={() =>
              authService.send("ON_BYPASS_CONNECT_REQUEST", {
                address: "0xC765d1e0971e2c921682f842A941eB56802d30e1",
              })
            }
            disabled={props.loading}
          >
            Bypass Wallet Connect...
          </button>
        </div> */}
        {props.error && (
          <div className="flex flex-col w-full p-4 rounded-2xl bg-gray-1000">
            <div className="pb-1 text-sm font-bold text-red-700">
              Something went wrong!
            </div>
            <div className="text-sm text-gray-200">{props.error || " "}</div>
          </div>
        )}
      </Flex>
    </div>
  );
};
// my MM wallet 0x4b55D6c7c29eF333Ca93aaC467e3331294B75C5d
//my 2nd MM cbwallet: 0x016a53ccf9af0FDD632D2639E110F36B3Fb7de8d
// my CB wallet: 0x6464C44c576890935cc387B2c17F2d0A54AdE347
// current test wallet 0xE46A5DfD891bC7A83115F8Ed5C1dEe7bD86459d8
// saul1: 0x41Eb782bAEAa5fdf4B36E16809A23a895167Da40
// RDs: 0x84264D74Fc3725E10ac36122c69A404F9De3F914
// whale 0x28fE8ef2Def1f5E6Fca4cF2e41936Ef6CB77A007

//BB collection addy 0xb5c747561a185a146f83cfff25bdfd2455b31ff4
//ETHAlien: 0x56b391339615fd0e88e0d370f451fa91478bb20f

//0x7D0051Dd1b619fB0Ddf77d61Bd7131C5Be5CDA08 // guy with bug
// 0xFb203c2E544d1D2D889900883b4bD538Bc914357 // winx
// 0x75FA6FA37569706Da14233618d3d5BE2ece6438f // justin lots of bugs
