export function roundToNearest(num, decimals) {
  decimals = decimals || 0;
  var p = Math.pow(10, decimals);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

// note: there is a bug in this code that mishandles 3-decimal place numbers
// there is a fix in the version of this function found in teh KPI component
// But I want to wait until that has been tested before promoting to this shared function
// todo j
export function abbreviateNumber(number = 0, places = 2) {
  const SI_SYMBOL = ["", "k", "M", "Bn", "T", "P", "E"];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) {
    //Hundreds or Less
    //return roundToNearest(number, 1);
    return Number(roundToMaxChars(number, 4));
  } else {
    // Thousands or larger
    const suffix = SI_SYMBOL[tier]; // e.g. "1.5k"
    const scale = Math.pow(10, tier * 3);
    //const scaled = roundToNearest( (number / scale) , places);
    const scaled = Number(roundToMaxChars(number / scale, 4));
    return scaled + suffix;
  }
}

//todo use this on other places, reconicel the two abbreviateNumber uses
export function roundToMaxChars(val, maxChars) {
  val = Number(val);
  if (!val || Number.isInteger(val)) return val;
  //console.log(val);
  const [leftStr, rightStr = ""] = roundToNearest(Number(val), maxChars)
    .toString()
    .split(".");
  //console.log("rightStr", rightStr);
  const numDecPlaces =
    leftStr === "0" ? maxChars : Math.max(maxChars - leftStr.length, 0);
  return (
    leftStr +
    (numDecPlaces === 0 ? "" : ".") +
    rightStr.substring(0, numDecPlaces)
  );
}
