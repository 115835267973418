import { IconWrapper, OpenSea, Twitter, Globe, Discord } from "../Icons";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

export const Footer = ({ reduceMargin = false }) => {
  const gaEventTracker = useAnalyticsEventTracker("Footer");
  const iconSize = 19;
  const iconColor = "blue4";
  const hoverColor = "white";
  const openSeaURL = "https://opensea.io/collection/nerdcollective-genesis-pfp";
  const collectionWebsiteURL = "https://nerdcollective.xyz";
  const twitterURL = "https://twitter.com/nerdcollective_";
  const discordURL = "https://discord.com/invite/thenerdcollective";
  // const termsURL = "https://www.nerdcollective.xyz/terms-conditions";
  const privacyURL = "https://www.nerdcollective.xyz/terms-conditions";
  const featureURL = "https://discord.com/invite/thenerdcollective";

  return (
    <div
      className={`flex flex-col justify-end ${!reduceMargin && "mt-6"} pb-6`}
    >
      <div className="flex flex-col items-center gap-y-3">
        <div className={`w-full dottedBorder ${!reduceMargin && "mt-6"}`} />
        <div className="flex flex-row gap-x-6 text-sm font-bold text-gray-300">
          {/* <a href={termsURL} target="_blank" rel="noreferrer">
            <div
              className="hover:text-white transition"
              onClick={() => gaEventTracker("click", "Terms")}
            >
              Terms of Use
            </div>
          </a> */}
          <a href={privacyURL} target="_blank" rel="noreferrer">
            <div
              className="hover:text-white transition"
              onClick={() => gaEventTracker("click", "Privacy")}
            >
              Privacy Policy & Terms of Use
            </div>
          </a>
          <a href={featureURL} target="_blank" rel="noreferrer">
            <div
              className="hover:text-white transition"
              onClick={() => gaEventTracker("click", "Feature")}
            >
              Feature Requests
            </div>
          </a>
        </div>
        <div className="flex flex-row text-white gap-x-3">
          <a
            href={openSeaURL}
            target="_blank"
            rel="noreferrer"
            onClick={() => gaEventTracker("click", "OpenSea")}
          >
            <OpenSea
              color2="black"
              height={iconSize}
              width={iconSize}
              className="hover:fill-white fill-blue-900 transition"
            />
          </a>
          <a
            href={collectionWebsiteURL}
            target="_blank"
            rel="noreferrer"
            onClick={() => gaEventTracker("click", "Website")}
          >
            <Globe
              color2="black"
              height={iconSize}
              width={iconSize}
              className="hover:fill-white fill-blue-900 transition"
            />
          </a>
          <a
            href={twitterURL}
            target="_blank"
            rel="noreferrer"
            onClick={() => gaEventTracker("click", "Twitter")}
          >
            <IconWrapper
              size={iconSize}
              color={iconColor}
              hoverColor={hoverColor}
            >
              <Twitter />
            </IconWrapper>
          </a>
          <a
            href={discordURL}
            target="_blank"
            rel="noreferrer"
            onClick={() => gaEventTracker("click", "Discord")}
          >
            <IconWrapper
              size={iconSize}
              color={iconColor}
              hoverColor={hoverColor}
            >
              <Discord />
            </IconWrapper>
          </a>
        </div>
        <div className="text-xs text-gray-600 font-semibold">
          Copyright © 2023 The Nerd Collective
        </div>
        <div className="text-xs text-gray-600 font-semibold text-center">
          This is app is currently in a "Beta" phase, which means it is still in
          development. Data may not be accurate or up-to-date. <br />
          This app does not represent financial advice. Please seek professional
          help for tax-related questions.
        </div>
      </div>
    </div>
  );
};
