export const Discord = () => {
  return (
    <>
      <path d="M4.41964 7.72744C4.41964 7.19302 4.80248 6.83771 5.19376 6.83771C5.59261 6.83771 5.97658 7.19958 5.96803 7.71862L5.96796 7.72744C5.96796 8.26195 5.58512 8.61726 5.19376 8.61726C4.81207 8.61726 4.41964 8.25842 4.41964 7.72744Z" />
      <path d="M9.10702 7.72744C9.10702 7.44474 9.19309 7.24777 9.30139 7.12801C9.40522 7.01318 9.5609 6.93317 9.78585 6.93317C10.1771 6.93317 10.56 7.28848 10.56 7.82291C10.56 8.35744 10.1771 8.71273 9.78585 8.71273C9.62655 8.71273 9.46986 8.63518 9.336 8.45837C9.19844 8.27667 9.10702 8.01225 9.10702 7.72744Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7993 12.1418C4.07687 12.2501 4.38312 12.3589 4.71778 12.4592C4.12821 13.0714 3.21429 13.9286 3.21429 13.9286C0.37603 13.8387 0 11.5142 0 11.5142C0 7.37898 1.8493 2.41985 1.8493 2.41985C3.6986 1.03286 5.458 1.0714 5.458 1.0714L5.62093 2.16701C4.76875 2.3937 3.89524 2.76842 2.95781 3.27967L3.47081 4.2203C5.01696 3.37708 6.27612 2.98365 7.50254 2.98304C8.72864 2.98243 9.98592 3.37443 11.5283 4.21977L12.0432 3.2802C11.0601 2.74139 10.1471 2.35474 9.25482 2.13267L9.54191 1.07148C9.54191 1.07148 11.3013 1.03286 13.1506 2.41994C13.1506 2.41994 15 7.37898 15 11.5142C15 11.5142 14.6239 13.8387 11.7857 13.9286C11.7857 13.9286 10.9323 13.0869 10.3682 12.4803C10.6643 12.3894 10.9637 12.2809 11.2673 12.1537C11.752 11.9729 12.2854 11.7116 12.8417 11.3452L12.2524 10.4504C11.7598 10.7748 11.2953 11.0005 10.8838 11.1532L10.877 11.1557L10.8717 11.1578L10.8627 11.1615C10.2557 11.4166 9.67857 11.5841 9.11812 11.6796L9.10935 11.6812C7.95422 11.8977 6.89265 11.8385 5.98175 11.6686C5.2897 11.5348 4.69286 11.3403 4.18929 11.1438C3.68543 10.9466 3.25432 10.7381 2.83716 10.4461L2.22277 11.3239C2.73274 11.6808 3.24743 11.9258 3.79879 12.1416L3.7993 12.1418ZM5.19376 5.76628C4.12105 5.76628 3.34821 6.69516 3.34821 7.72744C3.34821 8.76333 4.13709 9.68869 5.19376 9.68869C6.26503 9.68869 7.03719 8.76244 7.03938 7.73183C7.05433 6.68599 6.25739 5.76628 5.19376 5.76628ZM9.78585 5.86174C9.27871 5.86174 8.82712 6.05505 8.5067 6.40938C8.19075 6.75877 8.03559 7.22678 8.03559 7.72744C8.03559 8.22606 8.19185 8.72215 8.48177 9.10509C8.77538 9.49292 9.22597 9.78416 9.78585 9.78416C10.8586 9.78416 11.6314 8.85525 11.6314 7.82291C11.6314 6.79062 10.8586 5.86174 9.78585 5.86174Z"
      />
    </>
  );
};
