import React, { useState, useContext } from "react";
import { Checkbox } from "../Checkbox";
import { AuthStateContext } from "../../lib/auth.machine";

export const SetupUser = (props) => {
  const authService = useContext(AuthStateContext);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [checked, setChecked] = useState(false);
  // 4-20 characters, letters, numbers, periods, underscores, can't start with a period
  const userNameValidationPattern = /^\w[\w.]{2,18}\w$/;

  const handleSubmitUserName = () => {
    if (!username) {
      setError("A username is required");
      return;
    }
    if (!username.match(userNameValidationPattern)) {
      setError(
        "Username must be between 4-20 characters, and can only contain letters, numbers, periods and underscores."
      );
      return;
    }
    if (!checked) {
      setError("You must agree to the Terms of Use and Privacy Policy");
      return;
    }
    setError("");
    authService.send("DONE", { username });
  };

  return (
    <div className="w-full p-12 mx-auto bg-gray-1100 rounded-3xl">
      <h2 className="text-lg font-semibold leading-6 text-center text-gray-100">
        Welcome to The Nerd Collective.
      </h2>
      <div className="w-full pt-6">
        <div className="relative mb-5 floating-input">
          <input
            type="text"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            value={username}
            className="w-full h-16 p-3 text-gray-100 border border-gray-900 bg-gray-1200 focus:outline-none rounded-xl focus:border-pink-1000 focus:shadow-sm focus:ring-0"
            placeholder="Username"
            autoComplete="off"
          />
          {/* <label
            htmlFor="username"
            className="absolute top-0 left-0 h-full px-3 py-5 text-gray-500 transition-all duration-100 ease-in-out origin-left transform pointer-events-none"
          >
            User name
          </label> */}
        </div>
        <div className="relative mb-5 floating-input">
          <Checkbox
            label="I agree to the app's Terms of Use and Privacy Policy"
            color="pink5"
            onChange={() => {
              setChecked(!checked);
            }}
            checked={checked}
            id="terms"
            labelColor="gray11"
          />
        </div>
        <button
          onClick={handleSubmitUserName}
          disabled={!checked || props.loading}
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-gray-100 transition border border-transparent shadow-sm rounded-xl disabled:opacity-60 bg-pink-1000 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-1000 sm:text-sm"
        >
          Submit
        </button>
        <div className="pt-6 text-xs text-gray-400 font-semibold w-[500px]">
          This is app is currently in a "Beta" phase, which means it is still in
          development. Data may not be accurate or up-to-date. This app does not
          represent financial advice. Please seek professional help for
          tax-related questions. See{" "}
          <a
            href="https://www.nerdcollective.xyz/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            <span className=" text-gray-200 font-bold underline">
              Terms of Use
            </span>{" "}
            for more info.
          </a>
        </div>
        {/* <div className="flex items-center text-sm justify-center p-4 text-red-900">
          {error || " "}
        </div> */}
        {error && (
          <div className="flex flex-col max-w-sm p-4 rounded-2xl bg-gray-1000 mt-8">
            <div className="pb-1 text-sm font-bold text-red-700">
              Something went wrong!
            </div>
            <div className="text-sm text-gray-200">{error || " "}</div>
          </div>
        )}
      </div>
    </div>
  );
};
