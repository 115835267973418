import { gql } from "@apollo/client";

// todo change name to setPFPToken
export const setUserPrefs = gql`
  mutation setUserPrefs($pfpToken: PrefTokenInput!) {
    setUserPrefs(pfpToken: $pfpToken) {
      user {
        id
        username
        address
        pfpToken {
          contract
          tokenID
        }
      }
    }
  }
`;

export const setBannerPrefs = gql`
  mutation setBannerPrefs($bannerTokens: [PrefTokenInput!]!) {
    setBannerPrefs(bannerTokens: $bannerTokens) {
      user {
        id
        username
        address
        bannerTokens {
          contract
          tokenID
        }
      }
    }
  }
`;

export const addWalletAddress = gql`
  mutation addWalletAddress($address: String!) {
    addWalletAddress(address: $address) {
      user {
        id
        username
        address
        otherWallets {
          address
        }
      }
    }
  }
`;
